import { BaseUrl, Endpoint } from "@constants/handleRequest";
import axios from "axios";

export function loginService(email: string, password: string) {
  const headers = { "Content-Type": "application/json" };
  const apiUrl = BaseUrl.dev + Endpoint.signin;
  const body = { email, password };

  return new Promise<any>((resolve, rejects) => {
    axios
      .post(apiUrl, body, { headers })
      .then(async (res: any) => {
        resolve(res?.data);
      })
      .catch((e) => {
        rejects(e?.response?.data);
      });
  });
}
