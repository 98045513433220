import { FC, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, lighten, alpha, Theme } from "@mui/system";

import { TextStyles } from "@themes/textStyles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import EuroIcon from "@mui/icons-material/Euro";
import { CartIcon } from "@assets/SvgComponents/Cart";

import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
} from "recharts";

import phoneHumanImage from "@assets/Images/phone-human.png";
import { Colors } from "@themes/colors";

import { dataBarChart } from "./mockChartData";
import _ from "lodash";
import { Grid } from "@mui/material";
import { PaperShadowCustom } from "@components/PaperCustom";
import ButtonCustom from "@components/ButtonCustom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatistic } from "@stores/statistic/statistic.creator";
import { IAppState } from "@stores/state";
import formatMoney from "@utils/formatMoney";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  "&:hover": {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  position: "absolute",
  right: 4,
  bottom: 0,
  display: "block",
  height: "80%",
}));

const ContractChartWrapper = styled("div")({
  transform: "translateX(-32px)",
  marginTop: 16,
});

export interface IDashboardProps {}

export const Dashboard: FC<IDashboardProps> = () => {
  const textStyles = TextStyles();
  const boxContainsBarchartRef = useRef<any>(null);
  const [barChartWidth, setBarChartWidth] = useState<number>(600);
  const isUnderLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isUnderXl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );
  const dispatch = useDispatch();

  const { listStatistic } = useSelector((state: IAppState) => state.statistic);

  useEffect(() => {
    dispatch(getStatistic());

    const updateBarchartWidth = () => {
      if (!_.isEmpty(boxContainsBarchartRef.current)) {
        const style = window.getComputedStyle(
          boxContainsBarchartRef.current,
          null
        );
        setBarChartWidth(
          parseInt(style.getPropertyValue("width")) -
            parseInt(style.getPropertyValue("padding")) -
            20
        );
      }
    };
    updateBarchartWidth();
    window.addEventListener("resize", updateBarchartWidth);

    return () => {
      window.removeEventListener("resize", updateBarchartWidth);
    };
  }, []);

  const renderWelcome = () => {
    return (
      <Box
        key="bonjour"
        sx={{
          position: "relative",
          flexBasis: "50%",
          background:
            "linear-gradient(85.39deg, #FCD859 24.53%, rgba(255, 242, 128, 0.66) 98.99%);",
          p: 2,
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography
          color="white"
          className={textStyles.h1}
          sx={{ fontSize: "35px !important" }}
        >
          Bonjour Nicolas
        </Typography>
        <Typography color="white" className={textStyles.h5}>
          Un devis à créer ? C’est parti !
        </Typography>
        <Link to="/search-company" style={{ textDecoration: "none" }}>
          <StyledButton
            startIcon={
              <AddIcon
                sx={{
                  border: (theme) => `2px solid ${theme.palette.primary.main}`,
                  borderRadius: 1,
                }}
                fontSize="small"
              />
            }
            variant="contained"
          >
            Créer un nouveau devis
          </StyledButton>
        </Link>
        <StyledImage src={phoneHumanImage} alt="phone human" />
      </Box>
    );
  };

  const renderBarChart = () => {
    return (
      <Box
        ref={boxContainsBarchartRef}
        sx={{
          p: 2,
          flexBasis: "50%",
          borderRadius: 2,
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          backgroundColor: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={textStyles.h4}>
            Nombre de contrats validés
          </Typography>
          <Typography className={textStyles.paragraph_small_semi_bold}>
            06/01/2021 - 06/21/2022
          </Typography>
        </Stack>
        <Typography className={textStyles.paragraph_small_semi_bold}>
          Total:&nbsp;
          <Typography
            className={textStyles.paragraph_small_semi_bold}
            sx={{
              color: "#FFCC4D",
              display: "inline",
              fontSize: "13px !important",
            }}
          >
            {listStatistic?.reduce(
              (acc, current) => acc + current.totalContracts,
              0
            )}
          </Typography>
        </Typography>
        <ContractChartWrapper>
          <BarChart height={300} width={barChartWidth} data={dataBarChart}>
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#EBE9F1" />
            <Bar
              dataKey="value"
              fill="#FFCC4D"
              barSize={12}
              radius={[100, 100, 0, 0]}
            />
          </BarChart>
        </ContractChartWrapper>
      </Box>
    );
  };

  const renderMoney = () => {
    return (
      <Stack direction="column" spacing={4} sx={{ flexBasis: "50%" }}>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            backgroundColor: (theme) => theme.palette.common.white,
            boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography className={textStyles.h2}>
                {formatMoney(
                  (
                    listStatistic?.reduce(
                      (acc, current) => acc + current.avgAmount,
                      0
                    ) / listStatistic.length
                  ).toFixed(0)
                )}{" "}
                €
              </Typography>
              <Typography className={textStyles.paragraph}>
                Panier moyen
              </Typography>
            </div>
            <Avatar sx={{ bgcolor: alpha(Colors.purple, 0.2) }}>
              <CartIcon />
            </Avatar>
          </Stack>
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            backgroundColor: (theme) => theme.palette.common.white,
            boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography className={textStyles.h2}>
                {formatMoney(
                  (
                    listStatistic?.reduce(
                      (acc, current) => acc + current.totalAmount,
                      0
                    ) / listStatistic.length
                  ).toFixed(0)
                )}{" "}
                €
              </Typography>
              <Typography className={textStyles.paragraph}>
                CA depuis le début de l’année
              </Typography>
            </div>
            <Avatar sx={{ bgcolor: alpha(Colors.success, 0.2) }}>
              <EuroIcon sx={{ color: Colors.success }} />
            </Avatar>
          </Stack>
        </Box>
      </Stack>
    );
  };

  const renderPieChart = () => {
    return (
      <Box
        sx={{
          p: 2,
          flexBasis: "50%",
          borderRadius: 2,
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          backgroundColor: "white",
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography className={textStyles.h4}>
            Nombre de devis créé
          </Typography>
          <Typography align="right" className={textStyles.paragraph}>
            Sur 30 jours glissants
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mt: 4 }}>
          <PieChart width={200} height={200}>
            <Pie
              outerRadius={100}
              cx="50%"
              cy="50%"
              data={[
                {
                  name: "Professions",
                  value: 50,
                },
                {
                  name: "Artisans",
                  value: 14,
                },
              ]}
              dataKey={"value"}
            >
              <Cell fill={Colors.info} />
              <Cell fill={Colors.warning} />
            </Pie>
          </PieChart>
        </Stack>
        <Stack sx={{ mt: 4 }} direction="row" alignItems="center">
          <svg width={24} height={24} viewBox="0 0 100 100">
            <circle cx="50%" cy="50%" r={24} fill={Colors.info} />
          </svg>
          <Typography className={textStyles.h6}>
            Professions intellectuelles du bâtiment
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <svg width={24} height={24} viewBox="0 0 100 100">
            <circle cx="50%" cy="50%" r={24} fill={Colors.warning} />
          </svg>
          <Typography className={textStyles.h6}>
            Professions intellectuelles artisants
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperShadowCustom sx={{ py: 1.5, px: 4 }}>
          <Box component="div" width={181} sx={{ mr: 0, ml: "auto" }}>
            <Link to="/search-company" style={{ textDecoration: "none" }}>
              <ButtonCustom
                title="Nouveau devis"
                startIcon={
                  <AddCircleOutlineIcon
                    style={{ marginRight: 5, fontSize: 14 }}
                  />
                }
              />
            </Link>
          </Box>
        </PaperShadowCustom>
      </Grid>

      <Box mt={4} px={4}>
        <Stack direction="column" spacing={4}>
          <Stack direction={isUnderXl ? "column" : "row"} spacing={4}>
            {renderWelcome()}
            {renderMoney()}
          </Stack>
          <Stack direction={isUnderLg ? "column" : "row"} spacing={4}>
            {renderBarChart()}
            {renderPieChart()}
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};
