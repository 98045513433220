import NextIcon from "@assets/SvgComponents/NextIcon";
import ButtonCustom from "@components/ButtonCustom";
import DatePickerCustom from "@components/DatePickerCustom";
import InputCustom from "@components/InputCustom";
import { PaperNoneShadowCustom } from "@components/PaperCustom";
import RadioCustom from "@components/RadioCustom";
import SelectCustom from "@components/SelectCustom";
import SliderCustom from "@components/SliderCustom";
import Spacing from "@components/Spacing";
import SwitchCustom from "@components/SwitchCustom";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { clearDetailCompany } from "@stores/searchCompany/searchCompany.creator";
import CircularProgress from "@mui/material/CircularProgress";
import {
  saveDraftContract,
  saveInfoContractIntoRedux,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import {
  clearResponseCompany,
  createNewCompany,
  updateCompany,
} from "@stores/createNewCompany/createNewCompany.creator";
import {
  Certification,
  CompanyLegalStatusType,
  Diploma,
  ICreateCompanyDto,
  LeaderExperience,
} from "@stores/createNewCompany/createNewCompany.dto";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { getDetailCompanySucceeded } from "@stores/searchCompany/searchCompany.creator";
import { convertFormatPhone, phoneFormated } from "@utils/convertFormatPhone";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import { removeSpacing } from "@utils/removeSpacing";
import BoxSlide from "./BoxSlide";
import { IdProfessionType } from "@stores/activitiesOfContract/activitiesOfContract.dto";
interface TypeData {
  value: any;
  label: string;
}
interface TypeDataLegalStatus {
  value: any;
  label: string;
  value_mongo: CompanyLegalStatusType;
}

const dataDiplomaPIB: TypeData[] = [
  { value: Diploma.Bac, label: "Bac" },
  { value: Diploma.Bac23, label: "Bac +2/3" },
  { value: Diploma.Bac45, label: "Bac +4/5" },
  { value: Diploma.Ingenieur, label: "Ingénieur" },
  { value: Diploma.Architecte, label: "Architecte" },
  { value: Diploma.Aucun, label: "Aucun" },
];

const dataCertificationPIB: TypeData[] = [
  { value: Certification.Aucune, label: "Aucune" },
  { value: Certification.Opqibi, label: "Opqibi" },
  { value: Certification.Opqtecc, label: "Opqtecc" },
  { value: Certification.Chai, label: "Chai" },
  { value: Certification.Autre, label: "Autre" },
];

const dataDiplomaArtisans: TypeData[] = [
  ...dataDiplomaPIB,
  { value: Diploma.CAP, label: "Cap" },
  { value: Diploma.BEP, label: "Bep" },
];

const dataCertificationArtisans: TypeData[] = [
  ...dataCertificationPIB,
  { value: Certification.Qualibat, label: "Qualibat" },
  { value: Certification.Qualifelec, label: "Qualifelec" },
  { value: Certification.QualitEnR, label: "Qualit'EnR" },
  { value: Certification.Ecoartisan, label: "Ecoartisan" },
];

export const dataLegalStatus: TypeDataLegalStatus[] = [
  {
    value: "AutoEntrepreneur",
    label: "Auto entrepreneur",
    value_mongo: "AutoEntrepreneur",
  },
  {
    value: "EI",
    label: "EI - Entreprise individuelle",
    value_mongo: "EI",
  },
  {
    value: "EIRL",
    label: "EIRL - Entreprise individuelle responsabilité limité",
    value_mongo: "EIRL",
  },
  {
    value: "EURL",
    label: "EURL - Entreprise unipersonnelle responsabilité limité",
    value_mongo: "EURL",
  },
  {
    value: "SA",
    label: "SA - Société anonyme",
    value_mongo: "SA",
  },
  {
    value: "SARL",
    label: "SARL - Société à responsabilité limité",
    value_mongo: "SARL",
  },
  {
    value: "SAS",
    label: "SAS - Société anonyme simplifiée",
    value_mongo: "SAS",
  },
  {
    value: "SASU",
    label: "SASU - Société anonyme simplifiée unipersonnelle",
    value_mongo: "SASU",
  },
  {
    value: "SCI",
    label: "SCI - Société civile immobilière",
    value_mongo: "SCI",
  },
  {
    value: "Autre",
    label: "Autre",
    value_mongo: "Autre",
  },
];

const InformationCompany = () => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [valueRadio, setValueRadio] = useState<any>();
  const [isReceivership, setIsReceivership] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);

  const { loading_get_detail, detail_company } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );

  const { loading, response, selected_type, error } = useSelector(
    (state: IAppState) => state.responseCreateCompany
  );

  const responseDraftContract = useSelector(
    (state: IAppState) => state.dataContract.response
  );

  const loadingContract = useSelector(
    (state: IAppState) => state.dataContract.loading
  );

  const inforContract = useSelector(
    (state: IAppState) => state.dataContract.info_contract
  );

  const idContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.idContractUpdate
  );

  const data_contract_update = useSelector(
    (state: IAppState) => state.dataContract.data_contract_update
  );

  const errorGetCompany = useSelector(
    (state: IAppState) => state.dataSearchCompany?.error
  );

  // console.log(
  //   "info",
  //   detail_company?.type,
  //   data_contract_update?.type,
  //   selected_type
  // );

  const currentProductType =
    (detail_company?.type ?? data_contract_update?.type ?? selected_type) ===
    ContractType.Artisans_BTP
      ? ContractType.Artisans_BTP
      : ContractType.PIB;

  const dataDiploma =
    currentProductType === ContractType.Artisans_BTP
      ? dataDiplomaArtisans
      : dataDiplomaPIB;

  const dataCertification =
    currentProductType === ContractType.Artisans_BTP
      ? dataCertificationArtisans
      : dataCertificationPIB;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (value: any) => {
    const legalStatusSelected = dataLegalStatus.find(
      (item) => item.value === value?.legal_status
    );
    const informationCompany: ICreateCompanyDto = {
      name: value?.name_company,
      type: currentProductType ?? ContractType.PIB,
      siren: removeSpacing(value?.siren),
      codeNAF: value?.code_naf,
      legalStatus: legalStatusSelected?.value_mongo,
      headquarterAddress: value?.address_company,
      city: value?.city,
      codePostal: removeSpacing(value?.code_postal),
      capitalSocial:
        value?.capital_social &&
        parseFloat(removeSpacing(value?.capital_social)),
      birthday: moment(value?.date_creation).format("YYYY/MM/DD"),
      professionIds: [IdProfessionType.PIB, IdProfessionType.Artisans_BTP],
      legalRepresentative: {
        name: value?.name_leader,
        birthday: moment(value?.birthday).format("YYYY/MM/DD"),
        email: value?.email,
        phoneNumber: phoneFormated(removeSpacing(value?.phone)),
      },
      companyDetails: {
        revenue: parseInt(removeSpacing(value?.revenue)),
        percentMadeBySubContractors: value?.percentMadeBySubContractors
          ? value.percentMadeBySubContractors
          : 0,
        percentEntrustToSubContractors: value?.percentEntrustToSubContractors
          ? value.percentEntrustToSubContractors
          : 0,
        // isInReceivership: isReceivership,
        workforce: value?.workforce,
        percentIndividuals: value?.percentIndividuals
          ? value.percentIndividuals
          : 0,
        percentEnterprises: value?.percentIndividuals
          ? 100 - parseInt(value?.percentIndividuals)
          : 100,
        percentRenovation: value?.percentRenovation
          ? value.percentRenovation
          : 0,
        percentNew: value?.percentRenovation
          ? 100 - parseInt(value.percentRenovation)
          : 100,
        diploma: value?.diploma,
        leaderExperience: valueRadio,
        certification: value?.certification,
      },
    };
    if (!detail_company?._id) {
      dispatch(createNewCompany(informationCompany));
    } else {
      dispatch(updateCompany(informationCompany, detail_company._id));
    }
  };

  useEffect(() => {
    if (detail_company) {
      setValue("name_company", detail_company?.name);
      setValue("siren", detail_company?.siren);
      setValue("code_naf", detail_company?.codeNAF);
      if (detail_company?.legalStatus) {
        dataLegalStatus.forEach((item) => {
          if (detail_company?.legalStatus === item.value) {
            setValue("legal_status", detail_company.legalStatus);
          }
        });
      }
      setValue("address_company", detail_company?.headquarterAddress);
      setValue("city", detail_company?.city);
      setValue("code_postal", detail_company?.codePostal);
      setValue("capital_social", detail_company?.capitalSocial);
      detail_company?.birthday &&
        setValue(
          "date_creation",
          moment(detail_company?.birthday, "YYYY-MM-DD").toDate()
        );
      setValue("name_leader", detail_company?.legalRepresentative?.name);
      detail_company?.legalRepresentative?.birthday &&
        setValue(
          "birthday",
          moment(
            detail_company?.legalRepresentative.birthday,
            "YYYY-MM-DD"
          ).toDate()
        );
      setValue("birthday", detail_company?.legalRepresentative?.birthday);
      setValue("email", detail_company?.legalRepresentative?.email);
      detail_company?.legalRepresentative?.phoneNumber &&
        setValue(
          "phone",
          convertFormatPhone(detail_company?.legalRepresentative?.phoneNumber)
        );
      setValue("revenue", detail_company?.companyDetails?.revenue);
      setValue(
        "percentMadeBySubContractors",
        detail_company?.companyDetails?.percentMadeBySubContractors
      );
      setValue(
        "percentEntrustToSubContractors",
        detail_company?.companyDetails?.percentEntrustToSubContractors
      );
      setValue("workforce", detail_company?.companyDetails?.workforce);
      setValue(
        "percentIndividuals",
        detail_company?.companyDetails?.percentIndividuals
      );
      setValue(
        "percentRenovation",
        detail_company?.companyDetails?.percentRenovation
      );
      setValue("diploma", detail_company?.companyDetails?.diploma);
      setValueRadio(detail_company?.companyDetails?.leaderExperience);
      setValue("certification", detail_company?.companyDetails?.certification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail_company]);

  useEffect(() => {
    return () => {
      dispatch(clearResponseCompany());
      setIsCreated(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setIsCreated(true);
      dispatch(getDetailCompanySucceeded(response));
      const newDraftContract = {
        ...inforContract,
        companyId: response?._id,
        isInReceivership: isReceivership || false,
      };
      dispatch(saveInfoContractIntoRedux(newDraftContract));
    }
  }, [response, dispatch, inforContract, isReceivership]);

  useEffect(() => {
    isCreated && !isSaveDraft && navigate("/contract/activities");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated]);

  useEffect(() => {
    if (response && isSaveDraft) {
      const newDraftContract = {
        ...inforContract,
        companyId: response?._id,
        isInReceivership: isReceivership || false,
      };
      if (idContractUpdate) {
        dispatch(updateDraftContract(newDraftContract, idContractUpdate));
      } else {
        dispatch(saveDraftContract(newDraftContract));
      }
    }
  }, [
    response,
    isSaveDraft,
    dispatch,
    isReceivership,
    inforContract,
    idContractUpdate,
  ]);

  useEffect(() => {
    if (responseDraftContract) {
      !idContractUpdate && notistack.success("Création de brouillon réussie.");
      idContractUpdate && notistack.success("Projet de mise à jour réussi.");
      navigate("/search-company");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDraftContract, idContractUpdate]);

  useEffect(() => {
    if (idContractUpdate) {
      setIsReceivership(data_contract_update?.isInReceivership);
    }
  }, [idContractUpdate, data_contract_update]);

  // Notify errors
  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  useEffect(() => {
    errorGetCompany && notistack.error(errorGetCompany);
  }, [errorGetCompany]);

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaperNoneShadowCustom
          sx={{
            pt: 5,
            px: 7,
            pb: 10,
            position: "relative",
          }}
        >
          {loading_get_detail && (
            <Box position="absolute" width="100%" sx={{ top: 0, left: 0 }}>
              <LinearProgress color="success" />
            </Box>
          )}
          <Typography className={textStyles.h3}>
            Informations de la société
          </Typography>
          <Spacing height={35} />
          <Box component="div">
            <Controller
              name="name_company"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="Nom de la société"
                  label="Nom de la société*"
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value}
                  error={errors?.name_company && errors.name_company?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
            <Spacing height={18} />
            <Box component="div" display="flex">
              <Controller
                name="siren"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputCustom
                    placeholder="123 456 789"
                    label="SIREN (9 chiffres)"
                    sizeLabel="sm"
                    onChange={(e) => {
                      onChange(formatCustomInput(e.target.value, "money"));
                    }}
                    value={value ? formatCustomInput(value, "money") : value}
                    error={errors?.siren && errors?.siren?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^([0-9]{3})[ ]?([0-9]{3})[ ]?([0-9]{3})$/,
                    message: "SIREN doit comporter 9 chiffres.",
                  },
                }}
              />
              <Spacing width={20} />
              <Controller
                name="code_naf"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputCustom
                    placeholder="1235B"
                    label="Code NAF"
                    sizeLabel="sm"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
            <Spacing height={18} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              Statut juridique*
            </Typography>
            <Controller
              name="legal_status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectCustom
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Selectionner"
                  data={dataLegalStatus}
                  error={errors?.legal_status && errors.legal_status?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
            <Spacing height={18} />
            <Controller
              name="address_company"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="Adresse du siège"
                  label="Adresse du siège*"
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value}
                  error={
                    errors?.address_company && errors?.address_company?.message
                  }
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
            <Spacing height={18} />
            <Box component="div" display="flex">
              <Controller
                name="code_postal"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputCustom
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        onChange(formatCustomInput(e.target.value, "money"));
                      }
                    }}
                    value={value ? formatCustomInput(value) : value}
                    placeholder="92 250"
                    label="Code postal*"
                    sizeLabel="sm"
                    error={errors?.code_postal && errors.code_postal?.message}
                  />
                )}
                rules={{
                  required: "Ce champ est requis.",
                  pattern: {
                    value: /^([0-9]{2})[ ]?([0-9]{3})$/,
                    message: "Ce champ est requis.",
                  },
                }}
              />
              <Spacing width={20} />
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputCustom
                    onChange={onChange}
                    value={value}
                    placeholder="Ville"
                    label="Ville*"
                    sizeLabel="sm"
                    error={errors?.city && errors.city?.message}
                  />
                )}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
            </Box>
            <Spacing height={18} />
            <Controller
              name="capital_social"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  onChange={onChange}
                  value={value ? formatCustomInput(value) : value}
                  placeholder="Capital social"
                  label="Capital social"
                  endGroupIcon={<EuroSymbolIcon style={{ fontSize: 14 }} />}
                  sizeLabel="sm"
                  error={
                    errors?.capital_social && errors?.capital_social?.message
                  }
                />
              )}
            />
            <Spacing height={18} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              Date de création*
            </Typography>
            <Controller
              name="date_creation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerCustom
                  onChange={onChange}
                  value={value}
                  error={errors?.date_creation && errors.date_creation?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
          </Box>
        </PaperNoneShadowCustom>
        <Spacing height={30} />
        <PaperNoneShadowCustom
          sx={{
            pt: 5,
            px: 7,
            pb: 10,
            position: "relative",
          }}
        >
          {loading_get_detail && (
            <Box position="absolute" width="100%" sx={{ top: 0, left: 0 }}>
              <LinearProgress color="success" />
            </Box>
          )}
          <Typography className={textStyles.h3}>
            Informations du représentant légal
          </Typography>
          <Spacing height={35} />
          <Box component="div">
            <Controller
              name="name_leader"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="NOM prénom"
                  label="NOM et prénom (si société juste nom)*"
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value}
                  error={errors?.name_leader && errors.name_leader?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
            <Spacing height={18} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              Date de naissance (si société date de création)
            </Typography>
            <Controller
              name="birthday"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerCustom
                  error={errors?.birthday && errors.birthday?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Spacing height={18} />
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="Adresse email"
                  label="Email*"
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value}
                  error={errors?.email && errors.email?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
                pattern: {
                  value:
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                  message: "Invalide.",
                },
              }}
            />
            <Spacing height={18} />
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="06 12 34 56 78"
                  label="Téléphone*"
                  sizeLabel="sm"
                  onChange={(e) => {
                    onChange(formatCustomInput(e.target.value, "phone"));
                  }}
                  value={value ? formatCustomInput(value, "phone") : value}
                  error={errors?.phone && errors.phone?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
                pattern: {
                  value:
                    /^\+?([0-9]{2})\)?[ ]?([0-9]{2})[ ]?([0-9]{2})[ ]?([0-9]{2})[ ]?([0-9]{2})$/,
                  message: "Invalide.",
                },
              }}
            />
            <Spacing height={30} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              Diplôme
            </Typography>
            <Controller
              name="diploma"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectCustom
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Selectionner"
                  data={dataDiploma}
                />
              )}
            />
            <Spacing height={30} />
            <Box component="div">
              <Typography
                className={textStyles.paragraph_small_500}
                sx={{ mb: 0.5 }}
              >
                Nombre d’années d’expériences du dirigeant
              </Typography>
              <FormControl style={{ width: "100%", padding: "0 10px" }}>
                <RadioGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={LeaderExperience.LESS_THAN_ONE_YEAR}
                    control={<RadioCustom />}
                    label="Moins de 1 an"
                    className={classes.controlLabel}
                    checked={valueRadio === LeaderExperience.LESS_THAN_ONE_YEAR}
                    onClick={() =>
                      setValueRadio(LeaderExperience.LESS_THAN_ONE_YEAR)
                    }
                  />
                  <FormControlLabel
                    value={LeaderExperience.FROM_1_TO_3_YEARS}
                    control={<RadioCustom />}
                    label="1 à 3 ans"
                    className={classes.controlLabel}
                    checked={valueRadio === LeaderExperience.FROM_1_TO_3_YEARS}
                    onClick={() =>
                      setValueRadio(LeaderExperience.FROM_1_TO_3_YEARS)
                    }
                  />
                  <FormControlLabel
                    value={LeaderExperience.FROM_3_TO_5_YEARS}
                    control={<RadioCustom />}
                    label="3 à 5 ans"
                    className={classes.controlLabel}
                    checked={valueRadio === LeaderExperience.FROM_3_TO_5_YEARS}
                    onClick={() =>
                      setValueRadio(LeaderExperience.FROM_3_TO_5_YEARS)
                    }
                  />
                  <FormControlLabel
                    value={LeaderExperience.FROM_5_TO_10_YEARS}
                    control={<RadioCustom />}
                    label="5 à 10 ans"
                    className={classes.controlLabel}
                    checked={valueRadio === LeaderExperience.FROM_5_TO_10_YEARS}
                    onClick={() =>
                      setValueRadio(LeaderExperience.FROM_5_TO_10_YEARS)
                    }
                  />
                  <FormControlLabel
                    value={LeaderExperience.MORE_THAN_10_YEARS}
                    control={<RadioCustom />}
                    label="> 10 ans"
                    className={classes.controlLabel}
                    checked={valueRadio === LeaderExperience.MORE_THAN_10_YEARS}
                    onClick={() =>
                      setValueRadio(LeaderExperience.MORE_THAN_10_YEARS)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </PaperNoneShadowCustom>
        <Spacing height={30} />
        <PaperNoneShadowCustom
          sx={{
            pt: 5,
            px: 7,
            pb: 10,
            position: "relative",
          }}
        >
          {loading_get_detail && (
            <Box position="absolute" width="100%" sx={{ top: 0, left: 0 }}>
              <LinearProgress color="success" />
            </Box>
          )}
          <Typography className={textStyles.h3}>
            Détails de l’entreprise
          </Typography>
          <Spacing height={35} />
          <Box component="div">
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              L’entreprise est-t-elle en redressement judiciaire ?*
            </Typography>
            <Box component="div" display="flex" alignItems="center">
              <Typography
                className={textStyles.paragraph_small_500}
                sx={{ mr: 1.2 }}
              >
                Non
              </Typography>
              <SwitchCustom
                checked={isReceivership}
                onChange={(e) => setIsReceivership(e.target.checked)}
              />
              <Typography
                className={textStyles.paragraph_small_500}
                sx={{ ml: 1.2 }}
              >
                Oui
              </Typography>
            </Box>
            <Spacing height={18} />
            <Controller
              name="revenue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="Chiffre d’affaire"
                  label="Chiffre d’affaire en cours ou prévisionnel pour création*"
                  endGroupIcon={<EuroSymbolIcon style={{ fontSize: 14 }} />}
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value ? formatCustomInput(value) : value}
                  error={errors?.revenue && errors.revenue?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
                pattern: {
                  value: /^[1-9]/,
                  message: "Invalide.",
                },
              }}
            />
            <Spacing height={18} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              % du CA effectué en qualité de sous-traitant
            </Typography>
            <Spacing height={20} />
            <Controller
              name="percentMadeBySubContractors"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <SliderCustom
                    onChange={onChange}
                    type="number"
                    defaultValue={value}
                  />
                </>
              )}
            />
            <Spacing height={18} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              % du CA que vous confiez à des sous-traitants
            </Typography>
            <Spacing height={20} />
            <Controller
              name="percentEntrustToSubContractors"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <SliderCustom
                    onChange={onChange}
                    type="number"
                    defaultValue={value}
                  />
                </>
              )}
            />
            <Spacing height={30} />
            <Controller
              name="workforce"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  placeholder="Effectif"
                  label="Effectif de l’entreprise*"
                  sizeLabel="sm"
                  onChange={onChange}
                  value={value}
                  error={errors?.workforce && errors.workforce?.message}
                />
              )}
              rules={{
                required: "Ce champ est requis.",
              }}
            />
            <Spacing height={30} />
            <Box component="div">
              <Typography className={textStyles.paragraph_small_bold}>
                Typologie de clients
              </Typography>
              <Spacing height={12} />
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  className={textStyles.paragraph_small_bold}
                  style={{ color: Colors.primary }}
                >
                  Particuliers
                </Typography>
                <Typography
                  className={textStyles.paragraph_small_bold}
                  style={{ color: "#00CFE8" }}
                >
                  Entreprises
                </Typography>
              </Box>
              <Spacing height={5} />
              <Controller
                name="percentIndividuals"
                control={control}
                defaultValue={50}
                render={({ field: { onChange, value } }) => (
                  <BoxSlide onChange={onChange} defaultValue={value} />
                )}
              />
            </Box>
            <Spacing height={30} />
            <Box component="div">
              <Typography className={textStyles.paragraph_small_bold}>
                Type de travaux
              </Typography>
              <Spacing height={12} />
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  className={textStyles.paragraph_small_bold}
                  style={{ color: Colors.primary }}
                >
                  Rénovation
                </Typography>
                <Typography
                  className={textStyles.paragraph_small_bold}
                  style={{ color: "#00CFE8" }}
                >
                  Neuf
                </Typography>
              </Box>
              <Spacing height={5} />
              <Controller
                name="percentRenovation"
                control={control}
                defaultValue={50}
                render={({ field: { onChange, value } }) => (
                  <BoxSlide onChange={onChange} defaultValue={value} />
                )}
              />
            </Box>
            <Spacing height={30} />
            <Typography
              className={textStyles.paragraph_small_500}
              sx={{ mb: 0.5 }}
            >
              Certification
            </Typography>
            <Controller
              name="certification"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectCustom
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Selectionner"
                  data={dataCertification}
                />
              )}
            />
          </Box>
        </PaperNoneShadowCustom>
        <Spacing height={40} />
        <Box
          component="div"
          style={{ width: 350 }}
          sx={{ mr: "auto", ml: "auto" }}
        >
          <Box display="flex">
            <ButtonCustom
              title="Précédent"
              color={Colors.primary}
              startIcon={
                <span
                  style={{
                    margin: 0,
                    display: "flex",
                    transform: "rotateY(180deg)",
                    marginRight: 6,
                  }}
                >
                  <NextIcon color={Colors.primary} />
                </span>
              }
              backgroundColor="#B9B9C3"
              variant="outlined"
              onClick={() => navigate("/search-company")}
            />
            <Spacing width={55} />
            <ButtonCustom
              type="submit"
              title="Suivant"
              endIcon={
                loading && !isSaveDraft ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : (
                  <NextIcon color="#fff" />
                )
              }
            />
          </Box>
          <Spacing height={35} />
          <Box width={225} sx={{ mr: "auto", ml: "auto" }}>
            <ButtonCustom
              type="submit"
              backgroundColor="#B9B9C3"
              variant="outlined"
              onClick={() => setIsSaveDraft(true)}
              title="Enregistrer en brouillon"
              color={Colors.primary}
              endIcon={
                loadingContract && (
                  <CircularProgress
                    size={18}
                    style={{ color: Colors.primary }}
                  />
                )
              }
            />
          </Box>
        </Box>
      </form>
      <Spacing height={100} />
    </Container>
  );
};

const useStyles = makeStyles({
  controlLabel: {
    "& .MuiTypography-root": {
      fontSize: 12,
      fontWeight: 500,
      color: "#6E6B7B",
      marginLeft: "0.5em",
    },
  },
});

export default InformationCompany;
