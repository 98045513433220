import NextIcon from "@assets/SvgComponents/NextIcon";
import ButtonCustom from "@components/ButtonCustom";
import DatePicker from "@components/DatePickerCustom";
import Input from "@components/InputCustom";
import NumberInputCustom from "@components/NumberInputCustom";
import {
  PaperNoneShadowCustom,
  PaperShadowCustom,
} from "@components/PaperCustom";
import RadioCustom from "@components/RadioCustom";
import SelectCustom from "@components/SelectCustom";
import Spacing from "@components/Spacing";
import SwitchWithLabel from "@components/SwitchWithLabel";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { CircularProgress, FormControlLabel, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { pdf } from "@react-pdf/renderer";
import { getActivitiesContract } from "@stores/activitiesOfContract/activitiesOfContract.creator";
import {
  IActivityOfContract,
  IdProfessionType,
} from "@stores/activitiesOfContract/activitiesOfContract.dto";
import {
  saveDraftContract,
  saveInfoContractIntoRedux,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import {
  IClaimDetail,
  ICreateHistoryInsuranceDto,
} from "@stores/contract/contract.dto";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import isNumberic from "@utils/checkNumberic";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import { removeSpacing } from "@utils/removeSpacing";
import validContractStartDate from "@utils/validContractStartDate";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GeneratingQuestionPDF from "../GeneratingQuestionPDF";

export interface IInsuranceHistoryProps {}

interface IDataSelect {
  value: number;
  label: string;
}

export interface IActivitiesSelected extends IActivityOfContract {
  percent?: number;
}

export const dataReason: Array<IDataSelect> = [
  { value: 0, label: "Echéance principale" },
  { value: 1, label: "Augmentation de prime" },
  { value: 2, label: "Non paiement de prime" },
  { value: 3, label: "Modification du risque" },
  { value: 4, label: "Sinistre" },
  { value: 5, label: "Fausse déclaration" },
];

export const InsuranceHistory: FC<IInsuranceHistoryProps> = () => {
  const textStyles = TextStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();

  const [isRCD, setIsRCD] = useState<boolean>(false);
  const [isUnexpired, setIsUnexpired] = useState<boolean>(false);
  const [is5Years, setIs5Years] = useState<boolean>(false);
  const [isRecovery, setIsRecovery] = useState<boolean>(false);
  const [reason, setReason] = useState<IDataSelect>({
    value: 0,
    label: "Echéance principale",
  });
  const [totalClaims, setTotalClaims] = useState<number>(0);
  const [errorTotalClaims, setErrorTotalClaims] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [errorTotalAmount, setErrorTotalAmount] = useState<boolean>(false);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);
  const [dataActivities, setDataActivities] = useState<IActivitiesSelected[]>(
    []
  );
  const [dataYear, setDataYear] = useState<IDataSelect[]>([]);

  const { info_contract, loading, idContractUpdate, error } = useSelector(
    (state: IAppState) => state.dataContract
  );

  const responseDraftContract = useSelector(
    (state: IAppState) => state.dataContract.response
  );

  const { activities } = useSelector(
    (state: IAppState) => state.dataActivities
  );

  const { detail_company } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );

  const dataContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.data_contract_update
  );

  // console.log("insu", detail_company?.type, dataContractUpdate?.type);

  const currentProductType =
    (detail_company?.type ?? dataContractUpdate?.type) ===
    ContractType.Artisans_BTP
      ? ContractType.Artisans_BTP
      : ContractType.PIB;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  // Handlers
  const handleDownloadPDF_1 = async (info_contract: any, reason?: string) => {
    const asPDF = pdf();
    asPDF.updateContainer(
      <GeneratingQuestionPDF
        activities={dataActivities}
        company={detail_company}
        contract={info_contract}
        reason={reason}
      />
    );
    const blob = await asPDF.toBlob();
    saveAs(blob, "QUESTIONNAIRE D’ETUDE.pdf");
    navigate("/contract/calculate-result");
  };

  const onSubmit = (value: any) => {
    if (totalClaims <= 0 && totalAmount <= 0 && is5Years) {
      setErrorTotalAmount(true);
      setErrorTotalClaims(true);
      return;
    }
    if (totalAmount <= 0 && is5Years) {
      setErrorTotalAmount(true);
      return;
    }
    if (totalClaims <= 0 && is5Years) {
      setErrorTotalClaims(true);
      return;
    }
    const dataClaimDetails: Array<IClaimDetail> = [];
    // handle get claims and amount
    for (let i = 0; i < 6; i++) {
      if (getValues(`amount_${i}`) && getValues(`request_${i}`)) {
        if (
          isNumberic(removeSpacing(getValues(`amount_${i}`))) &&
          isNumberic(removeSpacing(getValues(`request_${i}`)))
        ) {
          dataClaimDetails.push({
            relativeBackwardYear: i,
            numberOfClaims: parseInt(removeSpacing(getValues(`request_${i}`))),
            amount: parseFloat(removeSpacing(getValues(`amount_${i}`))),
          });
        }
      }
    }
    const dataHistoryInsurance: ICreateHistoryInsuranceDto = {};
    dataHistoryInsurance.hasBeenRCD = isRCD;
    if (isRCD && value?.rcd && detail_company?.birthday) {
      const currentDate = new Date();
      const dateWithoutInteruption = currentDate.setFullYear(
        moment(detail_company?.birthday).toDate().getFullYear() +
          parseInt(value?.rcd)
      );
      dataHistoryInsurance.dateWithoutInteruption = moment(
        dateWithoutInteruption
      ).format("YYYY/MM/DD");
    }
    if (isRCD) {
      dataHistoryInsurance.lastInsurer = value?.last_company;
    }
    dataHistoryInsurance.isInProgress = isRCD ? isUnexpired : false;
    if (isUnexpired && value?.progressDate && isRCD) {
      dataHistoryInsurance.progressDate = moment(value?.progressDate).format(
        "YYYY/MM/DD"
      );
    }
    if (!isUnexpired && value?.cancellation_date && isRCD) {
      dataHistoryInsurance.progressCancellationDate = moment(
        value?.cancellation_date
      ).format("YYYY/MM/DD");
    }
    if (!isUnexpired && isRCD) {
      dataHistoryInsurance.progressTerminationReason = reason?.value;
    }
    dataHistoryInsurance.isClaimed = isRCD ? is5Years : false;
    if (value?.date_expected) {
      const { date_expected } = value;

      const dateExpected = validContractStartDate(date_expected);

      dataHistoryInsurance.contractStartDate =
        moment(dateExpected).format("YYYY/MM/DD");
    }
    dataHistoryInsurance.isInsuredThePast = isRecovery;
    if (isRecovery && value?.resumptionDatePast) {
      dataHistoryInsurance.resumptionDatePast = moment(
        value?.resumptionDatePast
      ).format("YYYY/MM/DD");
    }
    if (is5Years && isRCD) {
      dataHistoryInsurance.claimDetails = [...dataClaimDetails];
    } else {
      dataHistoryInsurance.claimDetails = [];
    }

    const newDraftContract = {
      ...info_contract,
      insuranceHistory: { ...dataHistoryInsurance },
    };

    if (idContractUpdate) {
      dispatch(updateDraftContract(newDraftContract, idContractUpdate));
    } else {
      dispatch(saveDraftContract(newDraftContract));
    }
    dispatch(saveInfoContractIntoRedux(newDraftContract));
    if (!isSaveDraft) {
      handleDownloadPDF_1(newDraftContract, reason?.label);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeNumberOfClaims = useCallback(
    debounce(() => {
      let claims: number = 0;
      for (let i = 0; i < 5; i++) {
        if (isNumberic(getValues(`request_${i}`))) {
          claims += parseInt(getValues(`request_${i}`));
        }
      }
      setTotalClaims(claims);
    }, 500),
    []
  );

  const handleChangeNumberOfClaims = () => {
    debounceChangeNumberOfClaims();
    errorTotalClaims && setErrorTotalClaims(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeAmount = useCallback(
    debounce(() => {
      let amount: number = 0;
      for (let i = 0; i < 5; i++) {
        if (getValues(`amount_${i}`)) {
          if (isNumberic(removeSpacing(getValues(`amount_${i}`)))) {
            amount += parseFloat(removeSpacing(getValues(`amount_${i}`)));
          }
        }
      }
      setTotalAmount(amount);
    }, 500),
    []
  );

  const handleChangeAmount = () => {
    debounceChangeAmount();
    errorTotalAmount && setErrorTotalAmount(false);
  };

  // Side effects
  useEffect(() => {
    if (detail_company?.birthday) {
      let yearCreation = new Date(detail_company.birthday).getFullYear();
      let currentYear = new Date().getFullYear();
      let arrayYear: IDataSelect[] = [];
      for (let i = 0; i <= currentYear - yearCreation; i++) {
        arrayYear.push({ value: i, label: i.toString() });
      }
      setDataYear(arrayYear);
    }
  }, [detail_company]);

  useEffect(() => {
    if (activities.data && info_contract?.activities) {
      let newActivities: Array<IActivitiesSelected> = [];
      for (let i = 0; i < activities.data?.length; i++) {
        for (let j = 0; j < info_contract.activities.length; j++) {
          if (activities.data[i]._id === info_contract.activities[j].id) {
            newActivities.push({
              ...activities.data[i],
              percent: info_contract.activities[j].percent,
            });
          }
        }
      }
      setDataActivities(newActivities);
    }
  }, [activities, info_contract]);

  // Notify and navigate
  useEffect(() => {
    if (responseDraftContract) {
      !idContractUpdate && notistack.success("Création de brouillon réussie.");
      idContractUpdate && notistack.success("Projet de mise à jour réussi.");
      isSaveDraft && navigate("/search-company");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDraftContract, isSaveDraft, idContractUpdate]);

  // Set values
  useEffect(() => {
    if (idContractUpdate || info_contract?.insuranceHistory) {
      const dataHistoryInsurance = info_contract?.insuranceHistory;
      if (dataHistoryInsurance?.hasBeenRCD !== undefined) {
        setIsRCD(dataHistoryInsurance.hasBeenRCD);
      } else {
        setIsRCD(false);
      }

      if (dataHistoryInsurance?.isClaimed !== undefined) {
        setIs5Years(dataHistoryInsurance.isClaimed);
      } else {
        setIs5Years(false);
      }

      if (dataHistoryInsurance?.isInProgress !== undefined) {
        setIsUnexpired(dataHistoryInsurance.isInProgress);
      } else {
        setIsUnexpired(false);
      }

      if (dataHistoryInsurance?.isInsuredThePast !== undefined) {
        setIsRecovery(dataHistoryInsurance.isInsuredThePast);
      } else {
        setIsRecovery(false);
      }

      if (
        dataHistoryInsurance?.dateWithoutInteruption &&
        detail_company?.birthday
      ) {
        const yearWithoutInteruption = moment(
          dataHistoryInsurance?.dateWithoutInteruption
        )
          .toDate()
          .getFullYear();
        const yearCreationCompany = new Date(
          detail_company?.birthday
        ).getFullYear();
        setValue("rcd", yearWithoutInteruption - yearCreationCompany);
      }

      if (dataHistoryInsurance?.lastInsurer) {
        setValue("last_company", dataHistoryInsurance?.lastInsurer);
      }

      if (dataHistoryInsurance?.progressDate) {
        setValue(
          "progressDate",
          moment(dataHistoryInsurance?.progressDate).toDate()
        );
      }

      if (dataHistoryInsurance?.progressCancellationDate) {
        setValue(
          "cancellation_date",
          moment(dataHistoryInsurance?.progressCancellationDate).toDate()
        );
      }

      if (dataHistoryInsurance?.progressTerminationReason) {
        dataReason.forEach((item: IDataSelect) => {
          if (item.value === dataHistoryInsurance?.progressTerminationReason) {
            setReason(item);
          }
        });
      }

      if (dataHistoryInsurance?.claimDetails) {
        let totalClaims: number = 0;
        let totalAmount: number = 0;
        for (let i = 0; i < dataHistoryInsurance?.claimDetails?.length; i++) {
          const claims = dataHistoryInsurance?.claimDetails?.[i];
          if (claims.numberOfClaims) {
            totalClaims += claims.numberOfClaims;
          }
          if (claims.amount) {
            totalAmount += claims.amount;
          }
          setValue(
            `request_${claims.relativeBackwardYear}`,
            claims.numberOfClaims
          );
          setValue(`amount_${claims.relativeBackwardYear}`, claims.amount);
        }
        setTotalClaims(totalClaims);
        setTotalAmount(totalAmount);
      }

      if (dataHistoryInsurance?.contractStartDate) {
        const { contractStartDate } = dataHistoryInsurance;

        const dateExpected = validContractStartDate(contractStartDate);

        setValue("date_expected", moment(dateExpected).toDate());
      }

      if (dataHistoryInsurance?.resumptionDatePast) {
        setValue(
          "resumptionDatePast",
          moment(dataHistoryInsurance?.resumptionDatePast).toDate()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info_contract, idContractUpdate, setValue]);

  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  // Get activities by contract type
  useEffect(() => {
    const idProfession =
      (currentProductType === ContractType.Artisans_BTP
        ? detail_company?.professionIds?.[1]
        : detail_company?.professionIds?.[0]) ?? IdProfessionType.PIB;

    dispatch(getActivitiesContract({ idProfession }));
  }, [currentProductType, detail_company?.professionIds, dispatch]);

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaperNoneShadowCustom sx={{ pt: 5, px: 7, pb: 4 }}>
          <Typography sx={{ mb: 4 }} className={textStyles.h3}>
            Antécédents d’assurance
          </Typography>
          <Stack spacing={3} alignItems="flex-start">
            <Box>
              <Typography
                gutterBottom
                className={textStyles.paragraph_small_500}
              >
                L’entreprise a-t-elle déjà été assurée en RC et RCD pour les
                activités à garantir ?*
              </Typography>
              <SwitchWithLabel
                checked={isRCD}
                onChange={(e) => setIsRCD(e.target.checked)}
              />
            </Box>
            {isRCD && (
              <>
                <Box minWidth="50%">
                  <Typography
                    className={textStyles.paragraph_small_500}
                    sx={{ mb: 0.5 }}
                  >
                    Nombre d'année d'assurance ?*
                  </Typography>
                  <Controller
                    name="rcd"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectCustom
                        onChange={onChange}
                        defaultValue={value}
                        placeholder="Selectionner"
                        data={dataYear}
                        error={errors?.rcd && errors?.rcd?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                    }}
                  />
                </Box>
                <Box alignSelf="stretch">
                  <Typography
                    gutterBottom
                    className={textStyles.paragraph_small_500}
                  >
                    Nom du dernier assureur*
                  </Typography>
                  <Controller
                    name="last_company"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        onChange={onChange}
                        value={value}
                        error={
                          errors?.last_company && errors?.last_company?.message
                        }
                        placeholder="Nom du dernier assureur"
                      />
                    )}
                    rules={{
                      required: {
                        value: isRCD,
                        message: "Ce champ est requis.",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    className={textStyles.paragraph_small_500}
                  >
                    Le contrat est-il en cours ?*
                  </Typography>
                  <SwitchWithLabel
                    checked={isUnexpired}
                    onChange={(e) => setIsUnexpired(e.target.checked)}
                  />
                </Box>
                {isUnexpired && (
                  <Box minWidth={"50%"}>
                    <Typography
                      gutterBottom
                      className={textStyles.paragraph_small_500}
                    >
                      Depuis quelle date ?*
                    </Typography>
                    <Controller
                      control={control}
                      name="progressDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          onChange={onChange}
                          error={
                            errors?.progressDate &&
                            errors?.progressDate?.message
                          }
                          value={value}
                          minDate={
                            detail_company?.birthday
                              ? new Date(detail_company?.birthday)
                              : new Date()
                          }
                          maxDate={new Date()}
                        />
                      )}
                      rules={{
                        required: {
                          value: isUnexpired && isRCD,
                          message: "Ce champ est requis.",
                        },
                      }}
                    />
                  </Box>
                )}
                {!isUnexpired && (
                  <>
                    <Box minWidth={"50%"}>
                      <Typography
                        gutterBottom
                        className={textStyles.paragraph_small_500}
                      >
                        Date de résiliation*
                      </Typography>
                      <Controller
                        control={control}
                        name="cancellation_date"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            onChange={onChange}
                            error={
                              errors?.cancellation_date &&
                              errors?.cancellation_date?.message
                            }
                            value={value}
                            maxDate={new Date()}
                          />
                        )}
                        rules={{
                          required: {
                            value: !isUnexpired && isRCD,
                            message: "Ce champ est requis.",
                          },
                        }}
                      />
                    </Box>
                    <Box width="100%">
                      <Typography
                        gutterBottom
                        className={textStyles.paragraph_small_500}
                      >
                        Motif de résiliation*
                      </Typography>
                      <RadioGroup
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          width: "65%",
                          padding: "0 8px",
                        }}
                      >
                        {dataReason.map((item: IDataSelect, index: number) => (
                          <FormControlLabel
                            key={index}
                            value={item.value}
                            onClick={() => setReason(item)}
                            control={
                              <RadioCustom
                                checked={item.value === reason.value}
                              />
                            }
                            sx={{ py: 1 }}
                            label={
                              <Typography
                                sx={{ ml: 0.5 }}
                                className={textStyles.paragraph_small_500}
                              >
                                {item.label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </>
                )}
                <Box>
                  <Typography className={textStyles.paragraph_small_500}>
                    L’entreprise a-t-elle fait l’objet d’un ou plusieurs
                    sinistres au cours des
                  </Typography>
                  <Typography className={textStyles.paragraph_small_500}>
                    5 dernières années ?
                  </Typography>
                  <SwitchWithLabel
                    checked={is5Years}
                    onChange={(e) => setIs5Years(e.target.checked)}
                  />
                </Box>
                {is5Years && (
                  <Box alignSelf="stretch">
                    <PaperShadowCustom sx={{ pb: 2, pt: 0.5 }}>
                      <Typography
                        className={textStyles.paragraph_small}
                        sx={{ px: "1.5em", py: 1 }}
                      >
                        Renseignez dans les cases le nombre et montant des
                        sinistres
                      </Typography>
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          backgroundColor: "#F3F2F7",
                          padding: "0.7em 1.5em",
                        }}
                      >
                        <Box component="div" style={{ width: "25%" }}></Box>
                        <Box component="div" style={{ width: "37.5%" }}>
                          <Typography
                            className={textStyles.paragraph_small_semi_bold}
                          >
                            NOMBRE DE SINISTRE
                          </Typography>
                        </Box>
                        <Box component="div" style={{ width: "37.5%" }}>
                          <Typography
                            className={textStyles.paragraph_small_semi_bold}
                          >
                            MONTANT DES SINISTRES
                          </Typography>
                        </Box>
                      </Box>
                      {[1, 2, 3, 4, 5].map((_, index: number) => (
                        <Box
                          key={index}
                          component="div"
                          display="flex"
                          justifyContent="space-between"
                          sx={{
                            padding: "0.7em 0",
                            borderBottom: "1px solid #E9ECEF",
                            margin: "0 1.5em",
                          }}
                        >
                          <Box
                            component="div"
                            style={{ width: "25%" }}
                            display="flex"
                            alignItems="center"
                            sx={{ pl: 3 }}
                          >
                            <Typography className={textStyles.h6}>
                              {index > 0 ? `ANNEE N-${index}` : "ANNEE N"}
                            </Typography>
                          </Box>
                          <Box component="div" style={{ width: "37.5%" }}>
                            <Box
                              component="div"
                              sx={{ width: "156px" }}
                              display="flex"
                              alignItems="flex-start"
                            >
                              <Controller
                                name={`request_${index}`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <NumberInputCustom
                                    background="#fff"
                                    border="1px solid #B9B9C3"
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChangeNumberOfClaims();
                                    }}
                                    value={value || ""}
                                    error={
                                      errors?.[`request_${index}`] &&
                                      errors?.[`request_${index}`]?.message
                                    }
                                  />
                                )}
                                rules={{
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "Mauvais format",
                                  },
                                  min: {
                                    value: 1,
                                    message: "doit être supérieur ou égal à 1.",
                                  },
                                  required: {
                                    value: getValues(`amount_${index}`),
                                    message: "Ce champ est requis.",
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            style={{ width: "37.5%" }}
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              component="div"
                              sx={{ width: "156px" }}
                              display="flex"
                            >
                              <Controller
                                name={`amount_${index}`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <NumberInputCustom
                                    background="#fff"
                                    border="1px solid #B9B9C3"
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChangeAmount();
                                    }}
                                    value={
                                      value ? formatCustomInput(value) : ""
                                    }
                                    error={
                                      errors?.[`amount_${index}`] &&
                                      errors?.[`amount_${index}`]?.message
                                    }
                                  />
                                )}
                                rules={{
                                  pattern: {
                                    value: /^[1-9]/,
                                    message: "Doit être supérieur à 0",
                                  },
                                  required: {
                                    value: getValues(`request_${index}`),
                                    message: "Ce champ est requis.",
                                  },
                                }}
                              />
                            </Box>
                            <Spacing width={8} />
                            <EuroSymbolIcon
                              style={{ fontSize: 14, color: "#6E6B7B" }}
                            />
                          </Box>
                        </Box>
                      ))}
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          padding: "0.7em 0",
                          margin: "0 1.5em",
                        }}
                      >
                        <Box
                          component="div"
                          style={{ width: "25%" }}
                          display="flex"
                          alignItems="center"
                          sx={{ pl: 3 }}
                        >
                          <Typography className={textStyles.paragraph_bold}>
                            Total
                          </Typography>
                        </Box>
                        <Box component="div" style={{ width: "37.5%" }}>
                          <Box component="p" sx={{ width: "156px" }}>
                            <NumberInputCustom
                              value={formatCustomInput(totalClaims).toString()}
                              disabled
                              background="#FFF5DB"
                              error={
                                errorTotalClaims
                                  ? "Le total doit être supérieur à 0"
                                  : undefined
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          component="div"
                          style={{ width: "37.5%" }}
                          display="flex"
                          alignItems="center"
                        >
                          <Box component="p" sx={{ width: "156px" }}>
                            <NumberInputCustom
                              value={formatCustomInput(totalAmount).toString()}
                              disabled
                              background="#FFF5DB"
                              error={
                                errorTotalAmount
                                  ? "Le total doit être supérieur à 0"
                                  : undefined
                              }
                            />
                          </Box>
                          <Spacing width={8} />
                          <EuroSymbolIcon
                            style={{ fontSize: 14, color: "#6E6B7B" }}
                          />
                        </Box>
                      </Box>
                    </PaperShadowCustom>
                  </Box>
                )}
              </>
            )}
            <Box minWidth="50%">
              <Typography
                gutterBottom
                className={textStyles.paragraph_small_500}
              >
                Date souhaitée du contrat*
              </Typography>
              <Controller
                name="date_expected"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    minDate={
                      new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        1
                      )
                    }
                    value={value}
                    onChange={onChange}
                    error={
                      errors?.date_expected && errors?.date_expected?.message
                    }
                  />
                )}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
            </Box>
            <Box>
              <Typography className={textStyles.paragraph_small_500}>
                Souhaitez-vous la reprise du passé ?*
              </Typography>
              <SwitchWithLabel
                checked={isRecovery}
                onChange={(e) => setIsRecovery(e.target.checked)}
              />
            </Box>
            {isRecovery && (
              <Box minWidth={"50%"}>
                <Typography
                  gutterBottom
                  className={textStyles.paragraph_small_500}
                >
                  Date de reprise du passé*
                </Typography>
                <Controller
                  control={control}
                  name="resumptionDatePast"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      onChange={onChange}
                      error={
                        errors?.resumptionDatePast &&
                        errors?.resumptionDatePast?.message
                      }
                      value={value}
                      minDate={
                        detail_company?.birthday
                          ? new Date(detail_company.birthday)
                          : new Date(0)
                      }
                      maxDate={new Date()}
                    />
                  )}
                  rules={{
                    required: {
                      value: isRecovery,
                      message: "Ce champ est requis.",
                    },
                  }}
                />
              </Box>
            )}
          </Stack>
        </PaperNoneShadowCustom>
        <Box
          component="div"
          style={{ width: 520 }}
          sx={{ mr: "auto", ml: "auto", mt: 6 }}
        >
          <Box display="flex">
            <Box width={275}>
              <ButtonCustom
                title="Précédent"
                color={Colors.primary}
                startIcon={
                  <span
                    style={{
                      margin: 0,
                      display: "flex",
                      transform: "rotateY(180deg)",
                      marginRight: 6,
                    }}
                  >
                    <NextIcon color={Colors.primary} />
                  </span>
                }
                backgroundColor="#B9B9C3"
                variant="outlined"
                onClick={() => navigate("/contract/activities")}
              />
            </Box>
            <Spacing width={55} />
            <ButtonCustom
              type="submit"
              title="Télécharger et lancer la simulation"
              endIcon={
                loading && !isSaveDraft ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : (
                  <NextIcon color="#fff" />
                )
              }
              onClick={() => setIsSaveDraft(false)}
            />
          </Box>
          <Spacing height={25} />
          <Box width={321} sx={{ mr: "auto", ml: "auto" }}>
            <ButtonCustom
              type="submit"
              backgroundColor="#B9B9C3"
              variant="outlined"
              title="Enregistrer le brouillon"
              color={Colors.primary}
              onClick={() => setIsSaveDraft(true)}
              endIcon={
                loading &&
                isSaveDraft && (
                  <CircularProgress
                    size={18}
                    style={{ color: Colors.primary }}
                  />
                )
              }
            />
          </Box>
        </Box>
        <Spacing height={100} />
      </form>
    </Container>
  );
};
