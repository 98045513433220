import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { IActivityOfContract } from "@stores/activitiesOfContract/activitiesOfContract.dto";
import { Colors } from "@themes/colors";
import parse from "html-react-parser";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage12: FC<{
  dataActivities?: IActivityOfContract[];
  isPIB?: boolean;
}> = ({ dataActivities = [], isPIB = true }) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          ANNEXE
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Détail des activités {isPIB ? "souscrites" : null}
        </Text>
      </View>

      <View>
        {dataActivities?.length > 0 &&
          dataActivities.map((item, index: number) => (
            <View key={index} style={{ marginTop: 15 }}>
              <Text style={styles.mainTitle}>
                {index + 1}. {item.name} :
              </Text>
              <Text style={generatingResultPDFStyles.mainContent}>
                {item?.definition && parse(item.definition)}.
              </Text>
              <Text style={generatingResultPDFStyles.mainContent}>
                {item?.exclusion && parse(item.exclusion)}.
              </Text>
              <View style={styles.divider} />
            </View>
          ))}
        {/* <View style={{ marginTop: 15 }}>
        <Text style={styles.mainTitle}>
          2. Architecte d’intérieur sans intervention sur la structure :
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Mission complète ou partielle de maîtrise d’œuvre comportant la
          conception (établissement de tous documents, pièces écrites et plans)
          et/ou le suivi de l’exécution (direction, surveillance, contrôle
          général des travaux et réception des travaux) pour l’aménagement
          intérieur, l'agencement des ouvrages et la mise en scène des objets
          participant de la qualité, de la cohérence et du plaisir d'habiter un
          espace.
        </Text>
        <View style={{ height: 15 }} />
        <Text style={generatingResultPDFStyles.mainContent}>
          Elle peut comporter une activité accessoire de négoce de meubles et
          d’objets décoratifs.
        </Text>
        <View style={{ height: 15 }} />
        <Text style={generatingResultPDFStyles.mainContent}>Est comprise la mission de :</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Assistance à Maitrise d’Ouvrage dans la spécialité.
        </Text>
        <View style={styles.divider} />
      </View>
      <View style={{ marginTop: 15 }}>
        <Text style={styles.mainTitle}>
          3. Assistant maître d’oeuvre / OPC TCE :
        </Text>
        <Text
          style={{
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: 9,
            color: "#5E5873",
            lineHeight: 1.8,
          }}
        >
          PHASE D’ETUDE :
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>DIA : Diagnostic ESQ : Esquisse</Text>
        <Text style={generatingResultPDFStyles.mainContent}>APS : Avant-Projet</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Sommaire APD : Avant-Projet Définitif EXE : Etudes d’Exécution PRO :
          Etudes de Projet
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          ACT : Assistance aux Contrats de Travaux
        </Text>
        <View style={{ height: 15 }} />
        <Text
          style={{
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: 9,
            color: "#5E5873",
            lineHeight: 1.8,
          }}
        >
          PHASE DE TRAVAUX :
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>VISA</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          OPC : Ordonnancement, Pilotage, Coordination DET : Direction de
          l’Exécution des Travaux
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          AOR : Assistance aux Opérations de Réception
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          L’ordonnancement, le pilotage et la coordination (OPC) ont pour objet,
          tout au long du déroulement d’un chantier de bâtiment et travaux
          publics (BTP), d’organiser et d’harmoniser dans le temps et dans
          l’espace les tâches élémentaires d’études et de travaux et les actions
          des différents intervenants.
        </Text>
      </View> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: "underline",
    color: Colors.primary,
    marginBottom: 8,
  },
  divider: {
    width: 30,
    height: 2,
    borderRadius: 2,
    backgroundColor: "#FFCC4D",
    marginTop: 5,
  },
});

export default ContentPage12;
