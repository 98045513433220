import NextIcon from "@assets/SvgComponents/NextIcon";
import PaperIcon from "@assets/SvgComponents/PaperIcon";
import ButtonCustom from "@components/ButtonCustom";
import InputCustom from "@components/InputCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import SelectCustom from "@components/SelectCustom";
import Spacing from "@components/Spacing";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { getActivitiesContract } from "@stores/activitiesOfContract/activitiesOfContract.creator";
import { IActivityOfContract } from "@stores/activitiesOfContract/activitiesOfContract.dto";
import {
  calculateResult,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import {
  IActivitiesDto,
  ICalculateAmountDto,
  IContractOptions,
  IInfoContractPDF,
  IPricingByFranchise,
} from "@stores/contract/contract.dto";
import { getFranchise } from "@stores/franchises/franchises.creator";
import {
  ContractStatus,
  ContractType,
} from "@stores/listQuotes/listQuotes.dto";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import { removeSpacing } from "@utils/removeSpacing";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import moment from "moment";
import "moment/locale/fr";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GeneratingResultPDF from "../GeneratingResultPDF";
import { dataLegalStatus } from "../InformationCompany";
import { dataReason } from "../InsuranceHistory";
import BoxResult from "./BoxResult";

export interface IActivitiesSelected extends IActivityOfContract {
  percent?: number;
}

const CalculateResult = () => {
  const textStyles = TextStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttonSubmit = useRef<HTMLButtonElement>(null);

  const [dataActivities, setDataActivities] = useState<IActivitiesSelected[]>(
    []
  );
  const [franchise, setFranchise] = useState<any>();
  const [franchiseSelected, setFranchiseSelected] =
    useState<IPricingByFranchise>();

  const {
    info_contract,
    message,
    error,
    idContractUpdate,
    loading,
    resultCalculate,
    data_contract_update,
    contract_id,
  } = useSelector((state: IAppState) => state.dataContract);

  const { activities } = useSelector(
    (state: IAppState) => state.dataActivities
  );

  const { detail_company } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const dataSelectIsReceivership = useMemo(
    () => [
      { value: "Annuel", label: "Annuel" },
      { value: "Triemestriel", label: "Trimestriel" },
      { value: "Semestriel", label: "Semestriel" },
      { value: "Mensuel", label: "Mensuel" },
    ],
    []
  );
  const dataSelect = useMemo(
    () => [
      { value: "Annuel", label: "Annuel" },
      { value: "Triemestriel", label: "Trimestriel", disabled: true },
      { value: "Semestriel", label: "Semestriel", disabled: true },
      { value: "Mensuel", label: "Mensuel", disabled: true },
    ],
    []
  );

  // console.log("resu", detail_company?.type, data_contract_update?.type);

  const currentProductType =
    (detail_company?.type ?? data_contract_update?.type) ===
    ContractType.Artisans_BTP
      ? ContractType.Artisans_BTP
      : ContractType.PIB;

  // const isPIB = currentProductType === ContractType.PIB;
  const isPIB =
    !data_contract_update?.company?.name?.includes?.("test artisans");

  const handleDownloadPDF_2 = async (infoInsurance: IInfoContractPDF) => {
    const asPDF = pdf();

    const companyName = detail_company?.name?.replaceAll(" ", "_");
    const currentDate = moment(new Date()).format("DD-MM-YYYY");

    asPDF.updateContainer(
      <GeneratingResultPDF infoInsurance={infoInsurance} isPIB={isPIB} />
    );
    const blob = await asPDF.toBlob();
    saveAs(
      blob,
      `${companyName}_${currentDate}_${
        contract_id ? contract_id : data_contract_update?.contract_id
      }.pdf`
    );
    dispatch(
      updateDraftContract(
        { status: ContractStatus.IN_PROGRESS },
        idContractUpdate ? idContractUpdate : data_contract_update?._id
      )
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSubmit = useCallback(
    debounce(() => buttonSubmit.current?.click(), 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCalculate = useCallback(
    debounce(
      (data: ICalculateAmountDto) => dispatch(calculateResult(data)),
      500
    ),
    []
  );

  const onSubmit = (value: any) => {
    setFranchise(undefined);
    const activitiesDto: IActivitiesDto[] = [];
    if (dataActivities) {
      dataActivities.forEach((activity: IActivitiesSelected) => {
        activitiesDto.push({
          activity: {
            id: activity._id,
            param: activity.param,
          },
          percent: activity.percent,
        });
      });
    }

    const dataCalculate: ICalculateAmountDto = {
      companyId: info_contract?.companyId || "",
      type: currentProductType ?? ContractType.PIB,
      isInReceivership: info_contract?.isInReceivership || false,
      activities: activitiesDto || [],
      insuranceHistory: {
        hasBeenRCD: info_contract?.insuranceHistory?.hasBeenRCD,
        isInProgress: info_contract?.insuranceHistory?.isInProgress,
        contractStartDate: moment(
          info_contract?.insuranceHistory?.contractStartDate
        ).format("YYYY/MM/DD"),
        //
        resumptionDatePast: moment(
          info_contract?.insuranceHistory?.resumptionDatePast
        ).format("YYYY/MM/DD"),
        //
        progressTerminationReason:
          info_contract?.insuranceHistory?.progressTerminationReason,
        claimDetails: info_contract?.insuranceHistory?.claimDetails,
        progressCancellationDate:
          info_contract?.insuranceHistory?.progressCancellationDate,
        isInsuredThePast: info_contract?.insuranceHistory?.isInsuredThePast,
      },
      contractOption: {
        period: value?.priod,
        subscriptionFee: parseInt(removeSpacing(value?.fee)),
        commission: parseInt(value?.commissions),
      },
    };
    dispatch(calculateResult(dataCalculate));
  };

  useEffect(() => {
    const idProfession =
      (currentProductType === ContractType.Artisans_BTP
        ? detail_company?.professionIds?.[1]
        : detail_company?.professionIds?.[0]) ?? ContractType.PIB;

    dispatch(
      getActivitiesContract({
        idProfession,
      })
    );
    moment.locale("fr");
    dispatch(getFranchise(idProfession));
  }, [currentProductType, detail_company?.professionIds, dispatch]);

  useEffect(() => {
    message && notistack.success("Le contrat a été créé.");
    error && notistack.error(error);
  }, [message, error]);

  useEffect(() => {
    if (activities.data && info_contract?.activities) {
      let newActivities: Array<IActivitiesSelected> = [];
      for (let i = 0; i < activities.data?.length; i++) {
        for (let j = 0; j < info_contract.activities.length; j++) {
          if (activities.data[i]._id === info_contract.activities[j].id) {
            newActivities.push({
              ...activities.data[i],
              percent: info_contract.activities[j].percent,
            });
          }
        }
      }
      setDataActivities(newActivities);
    }
  }, [activities, info_contract]);

  // Set values
  useEffect(() => {
    if (idContractUpdate) {
      setValue(
        "fee",
        info_contract?.contractOption?.subscriptionFee
          ? info_contract?.contractOption?.subscriptionFee
          : 200
      );
      setValue(
        "commissions",
        info_contract?.contractOption?.commission
          ? info_contract?.contractOption?.commission
          : 12
      );
      if (!info_contract?.isInReceivership) {
        if (info_contract?.insuranceHistory?.progressTerminationReason === 2) {
          setValue("priod", "Annuel");
        } else {
          if (info_contract?.contractOption?.period) {
            setValue("priod", info_contract?.contractOption?.period);
          } else {
            setValue("priod", "Mensuel");
          }
        }
      } else {
        setValue("priod", "Annuel");
      }
    } else {
      setValue("fee", parseInt(removeSpacing(getValues("fee"))));
      setValue("commissions", parseInt(getValues("commissions")));
      setValue("priod", getValues("priod"));
    }
    if (dataActivities?.length > 0) {
      const activitiesDto: IActivitiesDto[] = [];
      if (dataActivities) {
        dataActivities.forEach((activity: IActivitiesSelected) => {
          activitiesDto.push({
            activity: {
              id: activity._id,
              param: activity.param,
            },
            percent: activity.percent,
          });
        });
      }
      const dataCalculate: ICalculateAmountDto = {
        companyId: info_contract?.companyId || "",
        type: ContractType.PIB,
        isInReceivership: info_contract?.isInReceivership || false,
        activities: activitiesDto || [],
        insuranceHistory: {
          hasBeenRCD: info_contract?.insuranceHistory?.hasBeenRCD,
          isInProgress: info_contract?.insuranceHistory?.isInProgress,
          contractStartDate: moment(
            info_contract?.insuranceHistory?.contractStartDate
          ).format("YYYY/MM/DD"),
          //
          resumptionDatePast: moment(
            info_contract?.insuranceHistory?.resumptionDatePast
          ).format("YYYY/MM/DD"),
          //
          progressTerminationReason:
            info_contract?.insuranceHistory?.progressTerminationReason,
          claimDetails: info_contract?.insuranceHistory?.claimDetails,
          progressCancellationDate:
            info_contract?.insuranceHistory?.progressCancellationDate,
          isInsuredThePast: info_contract?.insuranceHistory?.isInsuredThePast,
        },
        contractOption: {
          period: getValues("priod"),
          subscriptionFee: parseInt(removeSpacing(getValues("fee"))),
          commission: parseInt(getValues("commissions")),
        },
      };
      debounceCalculate(dataCalculate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataActivities, info_contract, detail_company]);

  const triggerValidation = () => {
    debounceSubmit();
  };

  const handleGeneratePDF = async () => {
    if (franchise) {
      const contractOption: IContractOptions = {
        period: getValues("priod"),
        subscriptionFee: parseInt(removeSpacing(getValues("fee"))),
        commission: parseInt(getValues("commissions")),
        franchiseId: franchise?.id,
      };
      const newDraftContract = {
        ...info_contract,
        contractOption,
        amount: franchise?.amount,
        pricingByFranchise: {
          totalMAJO_MINO: franchiseSelected?.totalMAJO_MINO,
          primeNetHT: franchiseSelected?.primeNetHT,
          primeCode: franchiseSelected?.primeCODE,
          PJ_GROUPAMA: franchiseSelected?.PJ_GROUPAMA,
          JURY_LAW: franchiseSelected?.JURY_LAW,
          fractionement: franchiseSelected?.fractionement,
          NETCompanyAvecCom: franchiseSelected?.NETCompanyAvecCom,
        },
      };

      let taux_de_revision_du_contrat: number = 0;
      if (
        franchiseSelected?.NETCompanyAvecCom &&
        detail_company?.companyDetails?.revenue
      ) {
        taux_de_revision_du_contrat =
          franchiseSelected.NETCompanyAvecCom /
          detail_company.companyDetails.revenue;
      }

      const legalStatus = dataLegalStatus.find(
        (item) => item.value === detail_company?.legalStatus
      );
      const reason = dataReason.find(
        (item) =>
          item.value ===
          info_contract?.insuranceHistory?.progressTerminationReason
      );

      const dataInsurance: IInfoContractPDF = {
        contract_id: contract_id
          ? contract_id
          : data_contract_update?.contract_id,
        NETCompanyAvecCom: parseFloat(
          franchiseSelected?.NETCompanyAvecCom?.toFixed(2) || "0"
        ),
        fractionnement: getValues("priod"),
        frais_de_souscription: parseFloat(removeSpacing(getValues("fee"))),
        franchise_du_contrat: franchise?.money?.toFixed(0) || 0,
        taux_de_revision_du_contrat: parseFloat(
          (taux_de_revision_du_contrat * 100).toFixed(2)
        ),
        assureur: detail_company?.name,
        assureur_DPRSA_PJ: "Groupama protection juridique",
        courtier: "Madecennale",
        informations_du_souscripteur_1: {
          souscripteur: detail_company?.name,
          forme_juridique: legalStatus?.label,
          phone: detail_company?.legalRepresentative?.phoneNumber,
          email: detail_company?.legalRepresentative?.email,
          dimmatriculation: detail_company?.siren,
          date_creation: moment(detail_company?.birthday).format("DD/MM/YYYY"),
          effectif: detail_company?.companyDetails?.workforce,
          chiffre_HT: detail_company?.companyDetails?.revenue?.toString(),
          dirigeant: detail_company?.legalRepresentative?.name,
          address: detail_company?.headquarterAddress,
          siren: detail_company?.siren,
          city: detail_company?.city,
          codePostal: detail_company?.codePostal,
        },
        activities: dataActivities,
        informations_du_souscripteur_2: {
          hasRCD: info_contract?.insuranceHistory?.hasBeenRCD ? "Oui" : "Non",
          contrat_en_cours: info_contract?.insuranceHistory?.isInProgress
            ? "Oui"
            : "Non",
          date_de_resiliation:
            !info_contract?.insuranceHistory?.isInProgress &&
            info_contract?.insuranceHistory?.progressCancellationDate
              ? moment(
                  info_contract?.insuranceHistory?.progressCancellationDate
                ).format("DD/MM/YYYY")
              : "",
          assure_lors_des_24_derniers_mois:
            info_contract?.insuranceHistory?.claimDetails?.reduce(
              (acc, current) => acc + current.numberOfClaims,
              0
            ),
          pour_un_montant_de:
            info_contract?.insuranceHistory?.claimDetails?.reduce(
              (acc, current) => acc + current.amount,
              0
            ),
          esilie_non_paiement:
            reason?.label === "Non paiement de prime" ? "Oui" : "Non",
          resilie_pour_fausse_declaration:
            reason?.label === "Fausse déclaration" ? "Oui" : "Non",
          de_sous_traitance:
            detail_company?.companyDetails?.percentEntrustToSubContractors &&
            detail_company?.companyDetails?.percentEntrustToSubContractors > 30
              ? "Oui"
              : "Non",
          en_redressement_judiciaire: info_contract?.isInReceivership
            ? "Oui"
            : "Non",
        },
        page6: {
          amount_franchise_2: franchiseSelected?.NETCompanyAvecCom,
          amount_franchise_3:
            resultCalculate?.pricingByFranchises?.[2]?.NETCompanyAvecCom,
        },
        pricingByPeriod: franchiseSelected?.pricingByPeriod,
        cotisations: {
          JURY_LAW: franchiseSelected?.JURY_LAW || 0,
          PJ_GROUPAMA: franchiseSelected?.PJ_GROUPAMA || 0,
          fractionement: franchiseSelected?.fractionement,
          fraisInclus5LPP: franchiseSelected?.fraisInclus5LPP || 0,
          primeCODE: franchiseSelected?.primeCODE || 0,
          primeNetHT: franchiseSelected?.primeNetHT,
          totalMAJO_MINO: franchiseSelected?.totalMAJO_MINO,
        },
        contractStartDate: info_contract?.insuranceHistory?.contractStartDate
          ? info_contract.insuranceHistory.contractStartDate
          : "",
        //
        resumptionDatePast: data_contract_update?.insuranceHistory
          ?.resumptionDatePast
          ? data_contract_update.insuranceHistory.resumptionDatePast
          : "",
        //
        dataActivities,
        date_decheance: info_contract?.insuranceHistory?.contractStartDate
          ? moment(info_contract?.insuranceHistory?.contractStartDate)
              .format("LL")
              .slice(0, -5)
          : "",
        //
        workforce: data_contract_update?.company?.companyDetails?.workforce,
        //
      };
      await handleDownloadPDF_2(dataInsurance);
      if (idContractUpdate) {
        dispatch(updateDraftContract(newDraftContract, idContractUpdate));
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <form>
          <PaperShadowCustom sx={{ px: 6.5, py: 4, pb: 8 }}>
            <Box component="div">
              <Typography className={textStyles.h3}>
                Options & tarifications
              </Typography>
              <Spacing height={30} />
              <div>
                <Box component="div">
                  <Typography
                    className={textStyles.paragraph_small_500}
                    sx={{ mb: 0.5 }}
                  >
                    Fractionnement*
                  </Typography>
                  <Controller
                    control={control}
                    name="priod"
                    defaultValue={
                      !info_contract?.isInReceivership ? "Mensuel" : "Annuel"
                    }
                    render={({ field: { onChange, value } }) => (
                      <SelectCustom
                        data={
                          info_contract?.isInReceivership ||
                          info_contract?.insuranceHistory
                            ?.progressTerminationReason === 2
                            ? dataSelect
                            : dataSelectIsReceivership
                        }
                        placeholder="Sélectionnez une option"
                        onChange={(e) => {
                          onChange(e);
                          triggerValidation();
                        }}
                        defaultValue={value}
                        error={errors?.priod && errors?.priod?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                    }}
                  />
                </Box>

                <Spacing height={20} />
                <Box component="div">
                  <Controller
                    control={control}
                    name="fee"
                    defaultValue={200}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="Montant des frais"
                        endGroupIcon={
                          <EuroSymbolIcon style={{ fontSize: 14 }} />
                        }
                        label="Frais de souscription*"
                        value={value ? formatCustomInput(value) : value}
                        onChange={(e) => {
                          onChange(e);
                          triggerValidation();
                        }}
                        error={errors?.fee && errors?.fee?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                      pattern: {
                        value: /^[0-9]/,
                        message: "N'autoriser que les chiffres.",
                      },
                    }}
                  />
                </Box>
                <Spacing height={20} />
                <Box component="div">
                  <Controller
                    control={control}
                    name="commissions"
                    defaultValue={12}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="Commission"
                        endGroupIcon={
                          <Typography
                            className={textStyles.paragraph_small_bold}
                          >
                            %
                          </Typography>
                        }
                        label="Commission (8 à 25%)*"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          triggerValidation();
                        }}
                        error={
                          errors?.commissions && errors?.commissions?.message
                        }
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                      pattern: {
                        value: /^\d+$/,
                        message: "N'autoriser que les chiffres.",
                      },
                      min: {
                        value: 8,
                        message:
                          "La valeur doit être supérieure ou égale à 8 et inférieure ou égale à 25.",
                      },
                      max: {
                        value: 25,
                        message:
                          "La valeur doit être supérieure ou égale à 8 et inférieure ou égale à 25.",
                      },
                    }}
                  />
                </Box>
              </div>
            </Box>
          </PaperShadowCustom>
          <Spacing height={28} />
          <Box
            component="div"
            sx={{
              mr: "auto",
              ml: "auto",
              width: { sm: 377, md: 377, lg: "100%", xl: "100%" },
            }}
          >
            <Box display="flex">
              <Box sx={{ mr: 3, width: "40%" }}>
                <ButtonCustom
                  title="Précédent"
                  color={Colors.primary}
                  startIcon={
                    <span
                      style={{
                        margin: 0,
                        display: "flex",
                        transform: "rotateY(180deg)",
                        marginRight: 6,
                      }}
                    >
                      <NextIcon color={Colors.primary} />
                    </span>
                  }
                  backgroundColor="#B9B9C3"
                  variant="outlined"
                  onClick={() => navigate("/contract/insurance-history")}
                />
              </Box>
              <Box sx={{ width: "60%" }}>
                <ButtonCustom
                  type="button"
                  title="Générer le devis"
                  endIcon={
                    loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={14} />
                    ) : (
                      <PaperIcon color="#fff" />
                    )
                  }
                  onClick={() => handleGeneratePDF()}
                  backgroundColor={franchise ? Colors.primary : "#B9B9C3"}
                />
              </Box>
            </Box>
            <Spacing height={25} />
            <button
              style={{ display: "none" }}
              type="button"
              ref={buttonSubmit}
              onClick={handleSubmit(onSubmit)}
            />
            <Box width={225} sx={{ mr: "auto", ml: "auto" }}>
              <ButtonCustom
                type="button"
                backgroundColor="#B9B9C3"
                variant="outlined"
                title="Voir la liste des devis"
                color={Colors.primary}
                endIcon={<NextIcon color={Colors.primary} />}
                onClick={() => navigate("/liste-des-devis")}
              />
            </Box>
          </Box>
          <Spacing height={100} />
        </form>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <BoxResult
          onFranchiseSelected={setFranchiseSelected}
          onSelected={setFranchise}
          packages={franchiseSelected?.packages}
        />
      </Grid>
    </Grid>
  );
};

export default CalculateResult;
