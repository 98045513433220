import React, { FC, useEffect, useState } from "react";
import BackgroundLogin from "@assets/SvgComponents/BackgroundLogin";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextStyles } from "themes/textStyles";
import Spacing from "@components/Spacing";
import InputCustom from "@components/InputCustom";
import CheckboxCustom from "@components/CheckboxCustom";
import ButtonCustom from "@components/ButtonCustom";
import { Controller, useForm } from "react-hook-form";
import ForgotPass from "./ForgotPass";
import Logo from "@assets/Images/logo_dashboard.jpg";
import { useDispatch, useSelector } from "react-redux";
import { loginRequested } from "@stores/login/login.creator";
import { IAppState } from "@stores/state";
import { notistack } from "@utils/notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

export interface ILoginPageProps {}

export const LoginPage: FC<ILoginPageProps> = () => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotPass, setForgotPass] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm();

  const { error, loading, data } = useSelector(
    (state: IAppState) => state.responseLogin
  );

  const onSubmit = (value: any) => {
    dispatch(loginRequested(value.email, value.password));
  };

  const onForgotPass = () => {
    setForgotPass(true);
  };

  useEffect(() => {
    clearErrors();
  }, [forgotPass, clearErrors]);

  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  useEffect(() => {
    if (data?.token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderLoginUI = () => (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        style={{ background: "#F8F8F8" }}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>
          <BackgroundLogin />
        </p>
        <p className={classes.logo}>
          <img width="100%" height="100%" src={Logo} alt="" />
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        sx={{ pl: 10, pr: 10 }}
      >
        <Typography className={textStyles.h2}>
          Bienvenue sur Madecennale 👋🏻
        </Typography>
        <Typography className={textStyles.paragraph}>
          Connectez-vous à votre compte.
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputCustom
                onChange={onChange}
                value={value}
                label="Email"
                placeholder="johndoe@gmail.com"
                error={errors?.email && errors?.email?.message}
                autoComplete="username"
                sizeLabel="sm"
              />
            )}
            rules={{
              required: "L'e-mail est requis.",
              pattern: {
                value:
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "Email invalide.",
              },
            }}
          />
          <Spacing height={16} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography className={textStyles.paragraph_small} sx={{ mb: 0.5 }}>
              Mot de passe
            </Typography>
            <Typography
              color="primary"
              className={classes.titleForgot}
              sx={{ mb: 0.5 }}
              onClick={onForgotPass}
            >
              Mot de passe oublié ?
            </Typography>
          </div>
          <Controller
            name="password"
            render={({ field: { onChange, value } }) => (
              <InputCustom
                type="password"
                error={errors?.password && errors?.password?.message}
                value={value}
                onChange={onChange}
                autoComplete="current-password"
                sizeLabel="sm"
              />
            )}
            control={control}
            rules={{
              required: "Mot de passe requis.",
            }}
          />
          <Spacing height={15} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckboxCustom />
            <Spacing width={5} />
            <Typography className={textStyles.paragraph} sx={{ mt: 0.3 }}>
              Se souvenir de moi
            </Typography>
          </div>
          <Spacing height={16} />
          <Box component="div">
            <ButtonCustom
              disabled={loading}
              endIcon={
                loading && (
                  <CircularProgress
                    style={{ color: "#fff", width: "16px", height: "16px" }}
                  />
                )
              }
              type="submit"
              title="Se connecter"
            />
          </Box>
        </form>
      </Grid>
    </Grid>
  );

  return (
    <>
      {forgotPass ? (
        <ForgotPass onBack={() => setForgotPass(false)} />
      ) : (
        renderLoginUI()
      )}
    </>
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  logo: {
    position: "absolute",
    zIndex: 9,
    left: 50,
    top: 30,
    margin: 0,
    width: "170px",
  },
  form: {
    width: "100%",
    marginTop: "1.5rem",
  },
  titleForgot: {
    fontWeight: "500 !important",
    fontSize: "0.75rem !important",
    cursor: "pointer",
  },
});
