import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  clearMessageErrorLogin,
  loginFailed,
  loginSucceeded,
} from "./login.creator";
import { ILogin } from "./login.dto";
import { loginService } from "./login.service";
import { LoginRequestedAction, LoginType } from "./login.type";

function* login({ email, password }: LoginRequestedAction) {
  try {
    const dataResponse: AxiosResponse<ILogin> = yield call(
      loginService,
      email,
      password
    );
    if(dataResponse?.data) {
      localStorage.setItem('AccessToken', dataResponse.data?.token)
    }
    yield put(loginSucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(loginFailed(err?.message));
    yield put(clearMessageErrorLogin());
  }
}

function* watchLoginRequest() {
  yield takeEvery(LoginType.LOGIN_REQUESTED, login);
}

function* loginSaga() {
  yield all([fork(watchLoginRequest)]);
}

export default loginSaga;
