import { IResponseCreateNewCompany } from "./createNewCompany.dto";
import {
  CreateCompanyActions,
  CreateNewCompanyTypes,
} from "./createNewCompany.type";

const initialResponseCreateCompany: IResponseCreateNewCompany = {
  selected_type: undefined,
  response: undefined,
  loading: false,
};

export function createNewCompanyReducer(
  state = initialResponseCreateCompany,
  action: CreateCompanyActions
): IResponseCreateNewCompany {
  switch (action.type) {
    case CreateNewCompanyTypes.CREATE_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case CreateNewCompanyTypes.CREATE_COMPANY_SUCCEEDED: {
      return {
        ...state,
        response: action?.response,
        loading: false,
      };
    }

    case CreateNewCompanyTypes.CREATE_COMPANY_FAILED: {
      return {
        ...state,
        error: action?.error,
        loading: false,
      };
    }

    case CreateNewCompanyTypes.SAVE_SELECTED_TYPE: {
      return {
        ...state,
        selected_type: action?.selected_type,
      };
    }

    case CreateNewCompanyTypes.CLEAR_RESPONSE: {
      return {
        ...state,
        selected_type: undefined,
        response: undefined,
        loading: false,
        error: undefined,
      };
    }

    case CreateNewCompanyTypes.UPDATE_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case CreateNewCompanyTypes.UPDATE_COMPANY_SUCCEEDED: {
      return {
        ...state,
        response: action?.response,
        loading: false,
      };
    }

    case CreateNewCompanyTypes.UPDATE_COMPANY_FAILED: {
      return {
        ...state,
        error: action?.error,
        loading: false,
      };
    }

    default:
      return state;
  }
}
