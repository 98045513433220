import { Colors } from "@themes/colors";

type DataBarChartElement = {
  day: string;
  value: number;
};

export const dataBarChart: Array<DataBarChartElement> = [
  {
    day: "01/21",
    value: 0,
  },
  {
    day: "02/21",
    value: 20,
  },
  {
    day: "03/21",
    value: 5,
  },
  {
    day: "04/21",
    value: 12,
  },
  {
    day: "05/21",
    value: 13,
  },
  {
    day: "06/21",
    value: 9,
  },
  {
    day: "07/21",
    value: 6,
  },
  {
    day: "08/21",
    value: 3,
  },
  {
    day: "09/21",
    value: 13,
  },
  {
    day: "10/21",
    value: 15,
  },
  {
    day: "11/21",
    value: 10,
  },
  {
    day: "12/21",
    value: 8,
  },
];

type DataPieChartElement = {
  name: string;
  value: number;
  color: string;
};

export const dataPieChart: Array<DataPieChartElement> = [
  {
    name: "Typologie 1",
    value: 72,
    color: Colors.primary,
  },
  {
    name: "Typologie 2",
    value: 28,
    color: Colors.error,
  },
  {
    name: "Typologie 3",
    value: 14,
    color: Colors.info,
  },
  {
    name: "Typologie 4",
    value: 36,
    color: Colors.purple,
  },
];
