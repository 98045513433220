import React, { FC } from "react";
import { Box, Divider, Slider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "@themes/colors";

type TypeSlider = "number" | "none-number";

interface SliderCustomProps {
  type?: TypeSlider;
  defaultValue?: number;
  onChange?: (e: Event, number: number | number[]) => void;
}

const SliderCustom: FC<SliderCustomProps> = ({
  type = "number",
  defaultValue,
  onChange = () => {},
}) => {
  const valueLabelFormat = (value: number) => `${value}%`;

  const isEven = (value: number) => value % 2 === 0;

  const renderSliderHasNumberBottom = () => (
    <Box component="div" display="flex" flexDirection="column">
      <Box
        component="div"
        style={{
          height: "fit-content",
          display: "flex",
          padding: "0 10px 0 3px",
        }}
      >
        <SliderNumberLabelStyled
          valueLabelDisplay="on"
          aria-label="pretto slider"
          value={defaultValue || 0}
          valueLabelFormat={valueLabelFormat}
          onChange={onChange}
          step={5}
        />
      </Box>
      <Box component="div" display="flex" justifyContent="space-between">
        {[
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
          90, 95, 100,
        ].map((item, index) => (
          <Box
            component="p"
            sx={{ m: 0 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            key={index}
          >
            <Divider
              style={{
                borderColor: "#E1E5EA",
                height: 6,
                width: "fit-content",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              key={item}
              orientation="vertical"
              flexItem
            />
            {isEven(item) && (
              <Typography
                style={{ color: "#B9B9C3", fontSize: 10, fontWeight: 400 }}
              >
                {item}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderSliderNotNumberBottom = () => (
    <Box component="div" display="flex" flexDirection="column">
      <SliderNotNumberLabelStyled
        valueLabelDisplay="off"
        aria-label="pretto slider"
        value={defaultValue}
        valueLabelFormat={valueLabelFormat}
        onChange={onChange}
        step={5}
      />
      <Box component="div" display="flex" justifyContent="space-between">
        {[
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
          90, 95, 100,
        ].map((item, index) => (
          <Box
            component="p"
            sx={{ m: 0 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            key={index}
          >
            <Divider
              style={{
                borderColor: "#E1E5EA",
                height: 4,
                width: "fit-content",
                marginRight: "auto",
                marginLeft: "auto",
                borderWidth: 1,
              }}
              key={item}
              orientation="vertical"
              flexItem
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      {type === "number"
        ? renderSliderHasNumberBottom()
        : renderSliderNotNumberBottom()}
    </>
  );
};

const SliderNumberLabelStyled = styled(Slider)({
  color: Colors.primary,
  height: 6,
  borderRadius: 3,
  "& .MuiSlider-rail": {
    backgroundColor: "#EEEDFD",
    opacity: 1,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    width: 50,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    color: "#6E6B7B",
    borderRadius: "5px",
    "&::before": {
      display: "none",
    },
    fontSize: 12,
    fontWeight: 600,
  },
});

const SliderNotNumberLabelStyled = styled(Slider)({
  color: Colors.primary,
  height: 6,
  borderRadius: 3,
  padding: "4px 0",
  "& .MuiSlider-rail": {
    backgroundColor: "#86E5F0",
    opacity: 1,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    width: 50,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    color: "#6E6B7B",
    borderRadius: "5px",
    "&::before": {
      display: "none",
    },
    fontSize: 12,
    fontWeight: 600,
  },
});

export default SliderCustom;
