export enum ContractStatus {
  ALL = "Tous",
  DRAFT = "Brouillon",
  IN_PROGRESS = "En cours",
  VALIDATE = "Validé",
  INVALIDATE = "Invalidé",
  OBSOLETE = "Obsolète",
}

export type ContractStatusTypes =
  | "Brouillon"
  | "En cours"
  | "Validé"
  | "Invalidé"
  | "Obsolète";

/**
 * @from `Nicolas`: There is only one type of contract: "PIB"
 * in a second phase there will be a second type: "Artisans BTP".
 */
export enum ContractType {
  ALL = "Tous",
  PIB = "PIB",
  Artisans_BTP = "Entreprise du batiment",
}

export type ContractTypeTypes = "PIB";

export interface ICompanyQuote {
  _id?: string;
  name?: string;
}

export interface IQuote {
  _id: string;
  company?: ICompanyQuote;
  type?: ContractType;
  status?: ContractStatusTypes;
  createdAt?: string;
  amount?: number;
  contract_id?: string;
  isGuaranteedToPass?: boolean;
}

export interface IResponseQuote {
  data: Array<IQuote>;
  last_page?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export interface IFilterContractDto {
  sortBy?: object;
  page?: number;
  limit?: number;
  companyName?: string;
  type?: string;
  status?: string;
}

export interface IResponseListQuotes {
  listQuotes: IResponseQuote;
  loading?: boolean;
  error?: any;
  message?: string;
  loading_get?: boolean;
}
