import { IStatisticDto } from "./statistic.dto";
import {
  IGetStatisticFailedAction,
  IGetStatisticRequestedAction,
  IGetStatisticSucceededAction,
  StatisticTypes,
} from "./statistic.type";

export function getStatistic(): IGetStatisticRequestedAction {
  return {
    type: StatisticTypes.GET_STATISTIC_REQUESTED,
  };
}

export function getStatisticSucceeded(
  listStatistic: Array<IStatisticDto>
): IGetStatisticSucceededAction {
  return {
    type: StatisticTypes.GET_STATISTIC_SUCCEEDED,
    listStatistic,
  };
}

export function getStatisticFailed(error?: any): IGetStatisticFailedAction {
  return {
    type: StatisticTypes.GET_STATISTIC_FAILED,
    error,
  };
}
