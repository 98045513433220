import { ILogin } from "./login.dto";

export enum LoginType {
  LOGIN_REQUESTED = "login/REQUESTED",
  LOGIN_SUCCEEDED = "login/SUCCEEDED",
  LOGIN_FAILED = "login/FAILED",
  CLEAR_MESSAGE_ERROR = "login/CLEAR_MESSAGE_ERROR",
  CLEAR_DATA_LOGIN = "login/CLEAR_DATA_LOGIN",
}

export interface LoginRequestedAction {
  type: typeof LoginType.LOGIN_REQUESTED;
  email: string;
  password: string;
}

export interface LoginSucceededAction {
  type: typeof LoginType.LOGIN_SUCCEEDED;
  data: ILogin;
}

export interface LoginFailedAction {
  type: typeof LoginType.LOGIN_FAILED;
  error?: string;
}

export interface ClearMessageErrorLogin {
  type: typeof LoginType.CLEAR_MESSAGE_ERROR;
}

export interface ClearDataLogin {
  type: typeof LoginType.CLEAR_DATA_LOGIN;
}

export type LoginActions =
  | LoginRequestedAction
  | LoginSucceededAction
  | LoginFailedAction
  | ClearMessageErrorLogin
  | ClearDataLogin;
