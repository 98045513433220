import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getStatisticFailed, getStatisticSucceeded } from "./statistic.creator";
import { IStatisticDto } from "./statistic.dto";
import { getStatisticService } from "./statistic.service";
import { StatisticTypes } from "./statistic.type";

function* getStatistic() {
  try {
    const dataResponse: AxiosResponse<IStatisticDto[]> = yield call(
      getStatisticService
    );
    yield put(getStatisticSucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(getStatisticFailed(err?.message));
  }
}

function* watchGetStatistic() {
  yield takeLatest(StatisticTypes.GET_STATISTIC_REQUESTED, getStatistic);
}

function* statisticSaga() {
  yield all([fork(watchGetStatistic)]);
}

export default statisticSaga;
