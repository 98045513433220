import React, { FC, useMemo, useCallback, useState } from "react";
import Spacing from "@components/Spacing";
import { Box, Typography } from "@mui/material";
import SelectCustom from "@components/SelectCustom";
import InputCustom from "@components/InputCustom";
import { TextStyles } from "@themes/textStyles";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { IAppState } from "@stores/state";
import SearchIcon from "@assets/SvgComponents/SearchIcon";

interface IFormQueryProps {
  handleChangeRowsPerPage: (count: number) => void;
  handleSearchInput: (value: string) => void;
}

const FormQuery: FC<IFormQueryProps> = ({
  handleChangeRowsPerPage = () => {},
  handleSearchInput = () => {},
}) => {
  const textStyles = TextStyles();
  const { control } = useForm();
  const { loading } = useSelector((state: IAppState) => state.dataActivities);
  const [valueInput, setValueInput] = useState<string>("");

  const dataPage = useMemo(
    () => [
      { value: 5, label: "05" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue: string) => handleSearchInput(nextValue), 500),
    []
  );

  const handlingSearch = (value: string) => {
    debounceSearch(value);
    setValueInput(value);
  };

  return (
    <Box
      component="div"
      display="flex"
      sx={{ mx: 3, pt: 2.5, pb: 2 }}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Box component="div" display="flex" alignItems="center">
        <Typography className={textStyles.paragraph}>Afficher</Typography>
        <Spacing width={5} />
        <SelectCustom
          onChange={(value: number) => {
            if (typeof value === "number") {
              handleChangeRowsPerPage(value);
            }
          }}
          data={dataPage}
          defaultValue={5}
          border={false}
        />
      </Box>
      <Spacing width={30} />
      <Box style={{ width: "50%" }}>
        <Controller
          name="new-password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputCustom
              onChange={(e) => {
                onChange(e);
                handlingSearch(e.target.value);
              }}
              value={value}
              placeholder="Rechercher par référence ou activité"
              endAdornmentIcon={
                loading && valueInput ? (
                  <Box
                    component="p"
                    style={{
                      margin: 0,
                      display: "flex",
                      transform: "scale(0.5)",
                    }}
                  >
                    <CircularProgress style={{ color: "#5E5873" }} />
                  </Box>
                ) : (
                  <SearchIcon color="#BABFC7" />
                )
              }
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default FormQuery;
