import LogoOptim from "@assets/Images/optim.png";
import NumberInputCustom from "@components/NumberInputCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { clearResultCalculate } from "@stores/contract/contract.creator";
import {
  IContractPackages,
  IPricingByFranchise,
} from "@stores/contract/contract.dto";
import { IAppState } from "@stores/state";
import { TextStyles } from "@themes/textStyles";
import { formatCustomInput } from "@utils/formatCustomInput";
import formatMoney from "@utils/formatMoney";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IFranchise {
  id: string;
  level?: number;
  money: number;
}
interface IBoxResultProps {
  packages?: IContractPackages;
  onSelected?: (value: any) => void;
  onFranchiseSelected?: (franchise: IPricingByFranchise) => void;
}

const BoxResult: FC<IBoxResultProps> = ({
  packages,
  onSelected = () => {},
  onFranchiseSelected = () => {},
}) => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const dispatch = useDispatch();

  const [franchise1, setFranchise1] = useState<IFranchise>();
  const [franchise2, setFranchise2] = useState<IFranchise>();
  const [franchise3, setFranchise3] = useState<IFranchise>();

  const [selected, setSelected] = useState<IFranchise | undefined>();

  const [amount1, setAmount1] = useState<number | undefined>(undefined);
  const [amount2, setAmount2] = useState<number | undefined>(undefined);
  const [amount3, setAmount3] = useState<number | undefined>(undefined);

  const [comments, setComments] = useState<string | null>(null);

  const { loadingCalculate, resultCalculate } = useSelector(
    (state: IAppState) => state.dataContract
  );
  const commentsRedux = useSelector(
    (state: IAppState) => state.dataContract.comments
  );

  const franchises = useSelector(
    (state: IAppState) => state.franchises.data?.data
  );

  useEffect(() => {
    if (franchises) {
      for (let i = 0; i < franchises.length; i++) {
        if (franchises[i].level === -1) {
          setFranchise1({
            id: franchises[i]._id,
            level: -1,
            money: franchises[i].money || 0,
          });
        }
        if (franchises[i].level === 0) {
          setFranchise2({
            id: franchises[i]._id,
            level: 0,
            money: franchises[i].money || 0,
          });
        }
        if (franchises[i].level === 1) {
          setFranchise3({
            id: franchises[i]._id,
            level: 1,
            money: franchises[i].money || 0,
          });
        }
      }
    }
  }, [franchises]);

  useEffect(() => {
    if (resultCalculate?.pricingByFranchises) {
      setSelected(undefined);
      if (franchise1) {
        const amountFrachise1 = resultCalculate.pricingByFranchises.find(
          (franchise) => franchise?.franchise?.id === franchise1.id
        );
        setAmount1(amountFrachise1?.NETCompanyAvecCom);
      }

      if (franchise2) {
        const amountFrachise2 = resultCalculate.pricingByFranchises.find(
          (franchise) => franchise?.franchise?.id === franchise2.id
        );
        setAmount2(amountFrachise2?.NETCompanyAvecCom);
      }

      if (franchise3) {
        const amountFrachise3 = resultCalculate.pricingByFranchises.find(
          (franchise) => franchise?.franchise?.id === franchise3.id
        );
        setAmount3(amountFrachise3?.NETCompanyAvecCom);
      }
    }
  }, [resultCalculate, franchise1, franchise2, franchise3]);

  const handleSelect = (franchise?: IFranchise, amount?: number) => {
    franchise && setSelected(franchise);
    if (franchise && amount) {
      onSelected({
        ...franchise,
        amount,
      });
      const franchiseSelected: IPricingByFranchise | undefined =
        resultCalculate?.pricingByFranchises.find(
          (item) => item?.franchise?.id === franchise?.id
        );

      if (!franchiseSelected) return;

      onFranchiseSelected(franchiseSelected);

      let commentsResultCalculate = "";

      if (Array.isArray(franchiseSelected?.comments)) {
        commentsResultCalculate = franchiseSelected?.comments?.join(". ");
      } else if (franchiseSelected?.comments) {
        commentsResultCalculate = franchiseSelected?.comments;
      }

      if (commentsResultCalculate) setComments(commentsResultCalculate);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearResultCalculate());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaperShadowCustom
      sx={{ pt: 2.5, position: "relative", overflow: "hidden" }}
    >
      {loadingCalculate && (
        <Box
          component="div"
          sx={{ position: "absolute", top: 0, right: 0, width: "100%" }}
        >
          <LinearProgress color="success" />
        </Box>
      )}
      <Box
        component="ul"
        className={classes.headerTable}
        sx={{ pr: 3.5, pl: 2 }}
      >
        <Box
          component="li"
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "center",
          }}
          className={classes.itemHeader}
        >
          <Typography className={textStyles.paragraph_small_semi_bold}>
            Compagnie
          </Typography>
        </Box>
        <Box
          component="li"
          style={{ width: "37.5%" }}
          className={classes.itemHeader}
          sx={{ pl: 8 }}
        >
          <Typography className={textStyles.paragraph_small_semi_bold}>
            NIVEAU DE FRANCHISE
          </Typography>
        </Box>
        <Box
          component="li"
          style={{ width: "37.5%" }}
          className={classes.itemHeader}
        >
          <Typography className={textStyles.paragraph_small_semi_bold}>
            MONTANT PRIME{" "}
          </Typography>
        </Box>
      </Box>
      <Box component="div" display="flex" sx={{ py: 2.5, pr: 3.5, pl: 2 }}>
        <Box
          component="div"
          style={{
            width: "25%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.8em",
          }}
        >
          <Box component="p" className={classes.nameCompany}>
            <img
              style={{ maxWidth: 87 }}
              width="100%"
              src={LogoOptim}
              alt="logo"
            />
            <Typography
              className={textStyles.h6}
              style={{ textAlign: "center", marginTop: 8 }}
            >
              Optim’ Assurance
            </Typography>
          </Box>

          {packages && (
            <Box component="p" className={classes.nameCompany}>
              <Typography
                className={textStyles.h6}
                style={{ textAlign: "center", marginTop: 8 }}
                sx={{
                  backgroundColor: "#FFCC4D",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  width: "100%",
                  padding: "2px",
                  color: "#fff",
                  fontWeight: "700 !important",
                  minHeight: 25,
                }}
              >
                {packages?.title}
              </Typography>

              <Box className={classes.boxPrice}>
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  Garantie :
                </Typography>
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  {packages?.garantie &&
                    formatCustomInput(packages.garantie, "money")}
                  €
                </Typography>
              </Box>

              <Box
                className={classes.boxPrice}
                sx={{ backgroundColor: "#fff" }}
              >
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  Marché :
                </Typography>
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  {packages?.marche &&
                    formatCustomInput(packages.marche, "money")}
                  €
                </Typography>
              </Box>

              <Box className={classes.boxPrice}>
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  Max CA :
                </Typography>
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  style={{ textAlign: "center", marginTop: 4 }}
                >
                  {packages?.maxCA &&
                    formatCustomInput(packages.maxCA, "money")}{" "}
                  €
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box component="div" style={{ width: "75%" }}>
          <Box
            component="ul"
            className={
              selected?.id === franchise1?.id
                ? `${classes.listContent} active`
                : classes.listContent
            }
            style={{ borderBottom: "1px solid #E9ECEF" }}
            onClick={() =>
              handleSelect(franchise1 || undefined, amount1 || undefined)
            }
          >
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
              sx={{ pl: 8 }}
            >
              {franchise1 && (
                <Typography className={textStyles.h6}>
                  {formatMoney(franchise1.money)}
                </Typography>
              )}
            </Box>
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
            >
              <Box component="p" style={{ margin: 0, width: "120px" }}>
                <NumberInputCustom
                  disabled
                  value={
                    amount1 ? formatMoney(amount1, 3, ",", " ", 2) + "€" : ""
                  }
                  placeholder={!amount1 ? "-" : ""}
                  background={
                    selected?.id === franchise1?.id ? "#FFDB5C" : "#fff"
                  }
                  border={
                    selected?.id === franchise1?.id
                      ? "unset"
                      : "1px solid #D8D6DE"
                  }
                  color={selected?.id === franchise1?.id ? "#fff" : "#5E5873"}
                  fontWeight={700}
                />
              </Box>
            </Box>
          </Box>
          <Box
            component="ul"
            className={
              selected?.id === franchise2?.id
                ? `${classes.listContent} active`
                : classes.listContent
            }
            style={{ borderBottom: "1px solid #E9ECEF" }}
            onClick={() =>
              handleSelect(franchise2 || undefined, amount2 || undefined)
            }
          >
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
              sx={{ pl: 8 }}
            >
              {franchise2 && (
                <Typography className={textStyles.h6}>
                  {formatMoney(franchise2.money)}
                </Typography>
              )}
            </Box>
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
            >
              <Box component="p" style={{ margin: 0, width: "120px" }}>
                <NumberInputCustom
                  disabled
                  value={
                    amount2 ? formatMoney(amount2, 3, ",", " ", 2) + "€" : ""
                  }
                  placeholder={!amount2 ? "-" : ""}
                  fontWeight={700}
                  background={
                    selected?.id === franchise2?.id ? "#FFDB5C" : "#fff"
                  }
                  border={
                    selected?.id === franchise2?.id
                      ? "unset"
                      : "1px solid #D8D6DE"
                  }
                  color={selected?.id === franchise2?.id ? "#fff" : "#5E5873"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            component="ul"
            className={
              selected?.id === franchise3?.id
                ? `${classes.listContent} active`
                : classes.listContent
            }
            onClick={() =>
              handleSelect(franchise3 || undefined, amount3 || undefined)
            }
          >
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
              sx={{ pl: 8 }}
            >
              {franchise3 && (
                <Typography className={textStyles.h6}>
                  {formatMoney(franchise3.money)}
                </Typography>
              )}
            </Box>
            <Box
              component="li"
              style={{ width: "50%" }}
              className={classes.itemContent}
            >
              <Box component="p" style={{ margin: 0, width: "120px" }}>
                <NumberInputCustom
                  disabled
                  value={
                    amount3 ? formatMoney(amount3, 3, ",", " ", 2) + "€" : ""
                  }
                  placeholder={!amount3 ? "-" : ""}
                  fontWeight={700}
                  background={
                    selected?.id === franchise3?.id ? "#FFDB5C" : "#fff"
                  }
                  border={
                    selected?.id === franchise3?.id
                      ? "unset"
                      : "1px solid #D8D6DE"
                  }
                  color={selected?.id === franchise3?.id ? "#fff" : "#5E5873"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            component="div"
            className={classes.boxNotice}
            sx={{ py: 1.5, px: 2.5 }}
          >
            <Typography className={textStyles.h6}>
              Commentaire : {comments || commentsRedux}
            </Typography>
          </Box>
        </Box>
      </Box>
    </PaperShadowCustom>
  );
};

const useStyles = makeStyles({
  headerTable: {
    listStyle: "none",
    display: "flex",
    width: "100%",
    background: "#F3F2F7",
    padding: "0.8em 0",
    margin: 0,
  },
  itemHeader: {
    textTransform: "uppercase",
  },
  contentTable: {
    width: "100%",
  },
  listContent: {
    listStyle: "none",
    display: "flex",
    width: "100%",
    padding: "1em 0",
    margin: 0,
    cursor: "pointer",
    "&.active": {
      background: "#FFF9E8",
    },
  },
  itemContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  nameCompany: {
    margin: 0,
    background: "#FFCC4D21",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    padding: "0 10px",
    width: "100%",
  },
  boxNotice: {
    width: "100%",
    borderRadius: 5,
    background: "#F8F7FA",
    minHeight: 70,
  },
  boxPrice: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
    padding: "0 2px",
  },
});

export default BoxResult;
