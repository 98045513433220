import React, { useEffect, useMemo, useState } from "react";
import { PaperShadowCustom } from "@components/PaperCustom";
import {
  Box,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TextStyles } from "@themes/textStyles";
import { Link } from "react-router-dom";
import Spacing from "@components/Spacing";
import SelectCustom from "@components/SelectCustom";
import InputCustom from "@components/InputCustom";
import SearchIcon from "@assets/SvgComponents/SearchIcon";
import { Colors } from "@themes/colors";
import AddUserIcon from "@assets/SvgComponents/AddUserIcon";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import PaginationCustom from "@components/PaginationCustom";
import FilterTableHead from "@assets/SvgComponents/FilterTableHead";
import CheckboxCustom from "@components/CheckboxCustom";
import ActionsUser from "./ActionsUser";
import BoxUsername from "./BoxUsername";
import EyeIcon from "@assets/SvgComponents/EyeIcon";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "@stores/state";
import { IFilterUsersDto } from "@stores/users/users.dto";
import { getUsers } from "@stores/users/users.creator";
import * as _ from "lodash";
import { notistack } from "@utils/notistack";
import { formatCustomInput } from "@utils/formatCustomInput";
import { convertFormatPhone } from "@utils/convertFormatPhone";
import AdminIcon from "@assets/SvgComponents/AdminIcon";

const ListUsers = () => {
  const textStyles = TextStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [sortName, setSortName] = useState<boolean | undefined>(undefined);

  const { loadingGet, dataUsers, error, message, loadingDelete } = useSelector(
    (state: IAppState) => state?.users
  );

  const dataPage = useMemo(
    () => [
      { value: 5, label: "05" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
    []
  );

  const dataSelect = useMemo(
    () => [
      { value: "Fake Value 1", label: "Fake Value 1" },
      { value: "Fake Value 2", label: "Fake Value 2" },
      { value: "Fake Value 3", label: "Fake Value 3" },
      { value: "Fake Value 4", label: "Fake Value 4" },
    ],
    []
  );

  const { control, handleSubmit, getValues } = useForm();

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const onSubmit = (value: any) => {
    const filterUserDto: IFilterUsersDto = {
      page,
      limit: rowsPerPage,
      name: value?.name,
    };
    if (sortName !== undefined) {
      filterUserDto.sortBy = { name: sortName ? 1 : -1 };
    }
    dispatch(getUsers(filterUserDto));
  };

  useEffect(() => {
    const filterUserDto: IFilterUsersDto = {
      page,
      limit: rowsPerPage,
      name: getValues("name"),
    };
    if (sortName !== undefined) {
      filterUserDto.sortBy = { name: sortName ? 1 : -1 };
    }
    dispatch(getUsers(filterUserDto));
    error && notistack.error(error);
    message && notistack.success(message);
  }, [page, rowsPerPage, sortName, dispatch, getValues, error, message]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography sx={{ pt: 2, pb: 4 }} className={textStyles.h3}>
          Gérer les utilisateurs
        </Typography>
        <PaperShadowCustom>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              sx={{
                px: 3,
                py: 3.5,
                borderBottom: "1px solid #EBE9F1",
              }}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={textStyles.h4}>
                  Chercher et filtrer
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex">
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{ mr: 2 }}>
                  <Box component="div">
                    <Typography
                      className={textStyles.paragraph_small_500}
                      sx={{ mb: 0.5 }}
                    >
                      Nom
                    </Typography>
                    <Controller
                      control={control}
                      name="nom_select"
                      render={({ field: { onChange, value } }) => (
                        <SelectCustom
                          data={dataSelect}
                          placeholder="Nom"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Box component="div">
                    <Typography
                      className={textStyles.paragraph_small_500}
                      sx={{ mb: 0.5 }}
                    >
                      Role
                    </Typography>
                    <Controller
                      control={control}
                      name="role_select"
                      render={({ field: { onChange, value } }) => (
                        <SelectCustom
                          data={dataSelect}
                          placeholder="Active"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Box
              component="div"
              display="flex"
              sx={{ px: 3, pt: 2.5, pb: 2.5, position: "relative" }}
              justifyContent="space-between"
              alignItems="center"
            >
              {loadingGet && (
                <Box
                  position="absolute"
                  sx={{ width: "100%", left: 0, top: 0 }}
                >
                  <LinearProgress color="success" />
                </Box>
              )}
              <Box
                component="div"
                display="flex"
                alignItems="center"
                width="70%"
              >
                <Typography className={textStyles.paragraph}>
                  Afficher
                </Typography>
                <Spacing width={5} />
                <Box component="div" width={60}>
                  <SelectCustom
                    onChange={(value: number) => {
                      if (typeof value === "number") {
                        handleChangeRowsPerPage(value);
                      }
                    }}
                    data={dataPage}
                    defaultValue={5}
                    border={false}
                  />
                </Box>
                <Spacing width={30} />
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <InputCustom
                      placeholder="Rechercher"
                      endAdornmentIcon={<SearchIcon color="#BABFC7" />}
                      value={value}
                      onChange={onChange}
                      onIconClick={handleSubmit(onSubmit)}
                    />
                  )}
                />
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                width="fit-content"
                justifyContent="flex-end"
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                <Link
                  to="/management-users/information-detail"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <AddUserIcon color={Colors.primary} />
                  <Typography
                    className={textStyles.paragraph_bold}
                    sx={{
                      color: Colors.primary,
                      textDecoration: "underline",
                      ml: 1,
                    }}
                  >
                    Créer un utilisateur
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHead}>
                  <TableCell sx={{ width: "3%" }}>
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <CheckboxCustom onChange={() => console.log("test")} />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "22%", pl: "10px !important" }}>
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSortName(!sortName)}
                    >
                      <Typography
                        className={textStyles.paragraph_small_semi_bold}
                      >
                        NOM
                      </Typography>
                      <FilterTableHead />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "15%" }}>
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      TELEPHONE
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "15%" }}>
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      justifyContent="space-between"
                    >
                      <Typography
                        className={textStyles.paragraph_small_semi_bold}
                      >
                        ROLE
                      </Typography>
                      <FilterTableHead />
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      pl: "0 !important",
                      width: "45%",
                      pr: "50px !important",
                    }}
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      ACTIONS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.isArray(dataUsers?.data) &&
                  dataUsers?.data.map((user, index: number) => (
                    <TableRow className={classes.rowBody} key={index}>
                      <TableCell sx={{ width: "3%", pr: 0 }}>
                        <Box
                          component="div"
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <CheckboxCustom
                            onChange={() => console.log("test")}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: "22%", pl: "10px !important" }}>
                        <BoxUsername
                          name={user?.email || ""}
                          email={user?.email}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography className={textStyles.paragraph}>
                          {user?.phoneNumber &&
                            formatCustomInput(
                              convertFormatPhone(user?.phoneNumber),
                              "phone"
                            )}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Box component="div" display="flex" alignItems="center">
                          {user?.roles[0]?.name === "Admin" ? (
                            <AdminIcon color="#EA5455" />
                          ) : user?.roles[0]?.name === "User" ? (
                            <EyeIcon color="#28C76F" />
                          ) : (
                            ""
                          )}
                          <Typography
                            className={textStyles.paragraph_semi_bold}
                            sx={{ ml: 1.5 }}
                          >
                            {user?.roles[0]?.name === "Admin"
                              ? "Administrateur"
                              : user?.roles[0]?.name === "User"
                              ? "Utilisateur"
                              : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          pl: "0 !important",
                          pr: "62px !important",
                          width: "45%",
                        }}
                      >
                        <Box
                          component="div"
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <ActionsUser id={user._id} loading={loadingDelete} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box
              component="div"
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              sx={{ mx: 3, py: 2.5 }}
            >
              <Typography
                className={textStyles.paragraph}
                sx={{ color: "#B9B9C3" }}
              >
                {dataUsers?.data?.length} éléments affichés sur{" "}
                {dataUsers?.total}
              </Typography>
              <PaginationCustom
                page={dataUsers?.page ? dataUsers.page : 1}
                onChange={(_, page: number) => setPage(page)}
                count={dataUsers?.last_page}
              />
            </Box>
          </form>
        </PaperShadowCustom>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  tableHead: {
    background: "#F3F2F7",
    borderTop: "1px solid #EFEDF4",
    "& .MuiTableCell-head": {
      padding: "8px 0 8px 25px",
      "& .MuiTypography-root": {
        color: "#5E5873",
      },
      borderBottom: "1px solid #EFEDF4",
    },
  },
  rowBody: {
    "& .MuiTableCell-root": {
      paddingLeft: "25px",
      "& .MuiTypography-root": {
        color: "#5E5873",
      },
      borderBottom: "1px solid #EFEDF4",
    },
  },
});

export default ListUsers;
