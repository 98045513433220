import FilterTableHead from "@assets/SvgComponents/FilterTableHead";
import NextIcon from "@assets/SvgComponents/NextIcon";
import ButtonCustom from "@components/ButtonCustom";
import CheckboxCustom from "@components/CheckboxCustom";
import NumberInputCustom from "@components/NumberInputCustom";
import PaginationCustom from "@components/PaginationCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import Spacing from "@components/Spacing";
import TooltipCustom from "@components/TooltipCustom";
import { CircularProgress, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { getActivitiesContract } from "@stores/activitiesOfContract/activitiesOfContract.creator";
import {
  IActivityOfContract,
  IdProfessionType,
} from "@stores/activitiesOfContract/activitiesOfContract.dto";
import {
  saveDraftContract,
  saveInfoContractIntoRedux,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import {
  addActivity,
  removeActivity,
  updateActivity,
} from "@stores/savedActivities/savedActivities.creator";
import { IActivityRedux } from "@stores/savedActivities/savedActivities.dto";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import isNumberic from "@utils/checkNumberic";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import parse from "html-react-parser";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormQuery from "./FormQuery";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "number",
    label: "N°",
  },
  {
    id: "activite",
    label: "ACTIVITE",
  },
  {
    id: "pourcentage",
    label: "POURCENTAGE",
  },
  {
    id: "definition",
    label: "definition",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, onRequestSort } = props;
  const createSortHandler = (event: React.MouseEvent<unknown>) => {
    onRequestSort(event);
  };

  const classes = useStyles();
  const textStyles = TextStyles();

  return (
    <TableHead>
      <TableRow sx={{ background: "#F3F2F7" }}>
        {headCells.map((headCell, index: number) => (
          <TableCell
            key={headCell.id}
            align={index !== 0 ? "left" : "center"}
            className={classes.headerTable}
            sx={{
              borderBottom: "unset",
              py: 0,
              // width: index === 0 ? "35%" : index === 1 ? "15%" : "50%",
              width:
                index === 0
                  ? "10%"
                  : index === 1
                  ? "30%"
                  : index === 2
                  ? "15%"
                  : "45%",
            }}
          >
            {index === 1 ? (
              <Box component="div" display="flex">
                <Box
                  component="div"
                  sx={{ borderBottom: "unset", pl: 3, pr: 8, py: 1 }}
                  display="flex"
                  alignItems="center"
                >
                  <Box component="div" display="none">
                    <CheckboxCustom onChange={onSelectAllClick} />
                  </Box>
                </Box>
                <TableSortLabel onClick={createSortHandler} hideSortIcon={true}>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ my: 1 }}
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                      sx={{ color: "#5E5873", textTransform: "uppercase" }}
                    >
                      {headCell.label}
                    </Typography>
                    {index === 1 && <FilterTableHead />}
                  </Box>
                </TableSortLabel>
              </Box>
            ) : (
              <TableSortLabel hideSortIcon={true} sx={{ px: 0 }}>
                <Box component="div" display="flex" alignItems="center">
                  <Typography
                    className={textStyles.paragraph_small_semi_bold}
                    sx={{ color: "#5E5873", textTransform: "uppercase" }}
                  >
                    {headCell.label}
                  </Typography>
                </Box>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Activities = () => {
  const textStyles = TextStyles();
  const classes = useStyles();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPercent, setTotalPercent] = useState<number>(0);
  const [errorTotalPercent, setErrorTotalPercent] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [sort, setSort] = useState<boolean>(true);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);

  const dispatch = useDispatch();

  const activitiesRedux = useSelector(
    (state: IAppState) => state.activitiesRedux.activities
  );

  const { activities, error } = useSelector(
    (state: IAppState) => state.dataActivities
  );

  const loadingGetActivities = useSelector(
    (state: IAppState) => state.dataActivities.loading
  );

  const { detail_company } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );

  const responseDraftContract = useSelector(
    (state: IAppState) => state.dataContract.response
  );

  const { info_contract, loading } = useSelector(
    (state: IAppState) => state.dataContract
  );

  const idContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.idContractUpdate
  );

  const dataContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.data_contract_update
  );

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // console.log("acti", detail_company?.type, dataContractUpdate?.type);

  // Variables
  const currentProductType =
    (detail_company?.type ?? dataContractUpdate?.type) ===
    ContractType.Artisans_BTP
      ? ContractType.Artisans_BTP
      : ContractType.PIB;

  const lengthActivitiesCanSelect =
    currentProductType === ContractType.PIB ? 4 : 12;

  const revenueCompany = detail_company?.companyDetails?.revenue ?? 0;

  const isMaxSelected = selected.length === lengthActivitiesCanSelect;

  // Handlers
  const handleRequestSort = () => {
    setSort(!sort);
  };

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {};

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const checkBETActivity = (activity: string) => {
    if (activity.indexOf("BET") === 0) return true;
    return false;
  };

  const handleSelect = (activity: IActivityOfContract, index: number) => {
    let checkNonBET = 0;
    for (let i = 0; i < activitiesRedux.length; i++) {
      if (!checkBETActivity(activitiesRedux[i].name)) checkNonBET++;
    }
    if (!isSelected(activity._id)) {
      if (
        checkNonBET === 2 &&
        !checkBETActivity(activity.name) &&
        currentProductType === ContractType.PIB
      )
        return;

      // add activity into activities redux
      if (activitiesRedux.length < lengthActivitiesCanSelect) {
        setSelected([...selected, activity._id]);
        const newActivity: IActivityRedux = {
          id: activity._id,
          percent: 0,
          name: activity.name,
        };
        dispatch(addActivity(newActivity));
      }
    } else {
      // remove activity from activities redux
      const newSelecteds = selected.filter((n) => n !== activity._id);
      setSelected(newSelecteds);
      dispatch(removeActivity(activity._id));

      // reset value of activity
      setValue(
        `activity_${(page - 1) * activities?.data?.length + (index + 1)}`,
        ""
      );
    }
  };

  const findActivity = (
    activities: Array<IActivityRedux>,
    idActivity: string
  ) => {
    return activities.findIndex(
      (activity: IActivityRedux) => activity.id === idActivity
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdate = useCallback(
    debounce((nextValue) => dispatch(updateActivity(nextValue)), 500),
    []
  );

  const handleChangeInput = (value: any, idActivity: string, name: string) => {
    if (isNumberic(value)) {
      const updateItemActivity: IActivityRedux = {
        id: idActivity,
        percent: parseInt(value),
        name,
      };
      debounceUpdate(updateItemActivity);
    }
  };

  const onSubmit = (value: any) => {
    if (totalPercent === 100) {
      // navigate("/contract/insurance-history");
      const newDraftContract = {
        ...info_contract,
        companyId: detail_company?._id,
        activities: [...activitiesRedux],
      };
      if (isSaveDraft) {
        if (idContractUpdate) {
          dispatch(updateDraftContract(newDraftContract, idContractUpdate));
        } else {
          dispatch(saveDraftContract(newDraftContract));
        }
      } else {
        dispatch(saveInfoContractIntoRedux(newDraftContract));
        navigate("/contract/insurance-history");
      }
    } else {
      setErrorTotalPercent(true);
    }
  };

  // Side effects
  // update total selected percentage
  useEffect(() => {
    let total: number = 0;
    let arraySelected: string[] = [];
    activitiesRedux.forEach((activity) => {
      if (activity?.percent) {
        total += activity.percent;
      }
      arraySelected.push(activity.id);
    });
    setTotalPercent(total);
    setErrorTotalPercent(false);
    setSelected(arraySelected);
  }, [activitiesRedux]);

  // get activities
  useEffect(() => {
    const idProfession =
      (currentProductType === ContractType.Artisans_BTP
        ? detail_company?.professionIds?.[1]
        : detail_company?.professionIds?.[0]) ?? IdProfessionType.PIB;

    dispatch(
      getActivitiesContract({
        idProfession,
        page,
        limit: rowsPerPage,
        name: searchInput,
        sortBy: { name: sort ? 1 : -1 },
      })
    );
  }, [
    page,
    dispatch,
    rowsPerPage,
    searchInput,
    sort,
    currentProductType,
    detail_company?.professionIds,
  ]);

  // catch and show error
  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  // check and set value for selected activity.
  useEffect(() => {
    let lengthActivities: number = activities?.data?.length;
    for (let i = 0; i < lengthActivities; i++) {
      const selected =
        findActivity(activitiesRedux, activities?.data?.[i]._id) !== -1;
      const activitySelected =
        activitiesRedux[
          findActivity(activitiesRedux, activities?.data?.[i]._id)
        ];
      if (selected) {
        setValue(
          `activity_${(page - 1) * lengthActivities + (i + 1)}`,
          activitySelected?.percent
        );
      } else {
        setValue(`activity_${(page - 1) * lengthActivities + (i + 1)}`, "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activities, setValue]);

  // notify and navigate
  useEffect(() => {
    if (responseDraftContract) {
      !idContractUpdate && notistack.success("Création de brouillon réussie.");
      idContractUpdate && notistack.success("Projet de mise à jour réussi.");
      navigate("/search-company");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDraftContract, idContractUpdate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ mb: 3 }} className={textStyles.h3}>
        Les activités de la société{" "}
      </Typography>
      <PaperShadowCustom sx={{ pb: 3, position: "relative" }}>
        {loadingGetActivities && (
          <Box
            component="div"
            position="absolute"
            style={{ top: 0, left: 0, width: "100%" }}
          >
            <LinearProgress color="success" />
          </Box>
        )}
        <FormQuery
          handleChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
          handleSearchInput={(value: string) => setSearchInput(value)}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {activities?.data?.map(
                (activity: IActivityOfContract, index: number) => {
                  // test
                  const minTurnOver = 1500000;

                  // const isDisabledCheckbox = minTurnOver > revenueCompany;
                  const isDisabledCheckbox = false;

                  const activitySelected =
                    findActivity(activitiesRedux, activity._id) !== -1;

                  const isDisabledClickTooltipChildren =
                    !activitySelected && (isDisabledCheckbox || isMaxSelected);

                  const tooltipTitle = isMaxSelected
                    ? `Vous avez sélectionné ${lengthActivitiesCanSelect}/${lengthActivitiesCanSelect} activités`
                    : `Un niveau de revenus supérieur à ${formatCustomInput(
                        minTurnOver,
                        "money"
                      )} € est requis pour sélectionner cette activité`;

                  if (activitySelected && isDisabledCheckbox) {
                    dispatch(removeActivity(activity._id));
                  }

                  return (
                    <>
                      {!loadingGetActivities ? (
                        <TableRow key={index} role="checkbox" tabIndex={-1}>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{
                              borderBottom: "1px solid #E9ECEF",
                              px: 0,
                              py: 0,
                            }}
                          >
                            <Box
                              component="div"
                              sx={{ borderBottom: "unset" }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography className={textStyles.paragraph}>
                                {1.1}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{
                              borderBottom: "1px solid #E9ECEF",
                              px: 0,
                              py: 0,
                            }}
                          >
                            <Box
                              component="div"
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                component="div"
                                sx={{ borderBottom: "unset", pl: 3, pr: 5 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TooltipCustom
                                  title={tooltipTitle}
                                  maxWidth="450px"
                                  disabledClickChildren={
                                    isDisabledClickTooltipChildren
                                  }
                                  disabledHover={
                                    !isDisabledClickTooltipChildren
                                  }
                                >
                                  <CheckboxCustom
                                    onChange={() =>
                                      handleSelect(activity, index)
                                    }
                                    checked={
                                      activitySelected &&
                                      !isDisabledClickTooltipChildren
                                    }
                                    disabled={
                                      !activitySelected &&
                                      isDisabledClickTooltipChildren
                                    }
                                  />
                                </TooltipCustom>
                              </Box>
                              <Typography className={textStyles.paragraph}>
                                {activity.name}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              borderBottom: "1px solid #E9ECEF",
                              px: 0,
                              py: 2,
                            }}
                          >
                            <Box
                              component="div"
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                component="div"
                                display="flex"
                                alignItems="center"
                                sx={{ width: "60%" }}
                              >
                                <Controller
                                  name={`activity_${
                                    (page - 1) * rowsPerPage + (index + 1)
                                  }`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    const isDisabledInput =
                                      isDisabledCheckbox || !activitySelected;

                                    return (
                                      <NumberInputCustom
                                        value={
                                          value
                                            ? formatCustomInput(value, "number")
                                            : value
                                        }
                                        disabled={isDisabledInput}
                                        placeholder={isDisabledInput ? "-" : ""}
                                        onChange={(e) => {
                                          onChange(e);
                                          if (e.target.value) {
                                            handleChangeInput(
                                              e.target.value,
                                              activity._id,
                                              activity.name
                                            );
                                          } else {
                                            debounceUpdate({
                                              id: activity._id,
                                              value: 0,
                                            });
                                          }
                                        }}
                                        error={
                                          errors?.[
                                            `activity_${
                                              (page - 1) * rowsPerPage +
                                              (index + 1)
                                            }`
                                          ] &&
                                          errors?.[
                                            `activity_${
                                              (page - 1) * rowsPerPage +
                                              (index + 1)
                                            }`
                                          ]?.message
                                        }
                                      />
                                    );
                                  }}
                                  rules={{
                                    min: {
                                      value: 1,
                                      message: "Valeur invalide.",
                                    },
                                    max: {
                                      value: 100,
                                      message: "Valeur invalide.",
                                    },
                                    pattern: {
                                      value: /^[1-9]/,
                                      message: "Mauvais format",
                                    },
                                    required: {
                                      value: activitySelected,
                                      message: "Ce champ est requis.",
                                    },
                                  }}
                                />
                              </Box>
                              <Spacing width={15} />
                              <Typography
                                className={textStyles.paragraph_small_semi_bold}
                                sx={{ color: "#5E5873" }}
                              >
                                %
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              borderBottom: "1px solid #E9ECEF",
                              px: 0,
                              py: 2,
                            }}
                          >
                            <TooltipCustom
                              title={
                                activity.definition &&
                                parse(activity.definition)
                              }
                              maxWidth="538px"
                            >
                              <Box
                                className={classes.boxDescription}
                                component="div"
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2,
                                  pr: 2,
                                }}
                              >
                                <Typography className={textStyles.paragraph}>
                                  {activity.definition &&
                                    parse(activity.definition)}
                                </Typography>
                              </Box>
                            </TooltipCustom>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                }
              )}
              <TableRow>
                <TableCell
                  padding="checkbox"
                  sx={{ pl: 3, borderBottom: "unset" }}
                >
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%", height: "100%", pr: 1.5 }}
                  >
                    <Typography
                      className={textStyles.paragraph}
                      sx={{ color: "#B9B9C3" }}
                    >
                      {`${rowsPerPage} éléments sur ${activities.total}`}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell sx={{ borderBottom: "unset" }}>
                  <Typography
                    className={textStyles.paragraph_semi_bold}
                    sx={{
                      color: "#5E5873",
                      textAlign: "right",
                    }}
                  >
                    Total
                  </Typography>
                </TableCell>

                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{ px: 0, py: 2, borderBottom: "unset" }}
                >
                  <Box component="div" display="flex" alignItems="center">
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      sx={{ width: "60%" }}
                    >
                      <NumberInputCustom
                        disabled
                        value={totalPercent.toString()}
                        background="rgba(255, 204, 77, 0.2)"
                        error={
                          errorTotalPercent
                            ? "Le total doit être égal à 100"
                            : undefined
                        }
                        border={
                          errorTotalPercent ? "1px solid #D8D6DE" : "unset"
                        }
                      />
                    </Box>
                    <Spacing width={15} />
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                      sx={{ color: "#5E5873" }}
                    >
                      %
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{ px: 0, py: 3, borderBottom: "unset" }}
                >
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box component="div" display="flex" alignItems="center">
                      <Typography className={textStyles.paragraph_semi_bold}>
                        Activités sélectionnées:
                      </Typography>
                      <Typography
                        className={textStyles.paragraph_semi_bold}
                        sx={{ color: Colors.primary, ml: 0.5 }}
                      >
                        {`${activitiesRedux.length}/${lengthActivitiesCanSelect}`}
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      sx={{ pr: 3 }}
                    >
                      <PaginationCustom
                        onChange={(_, page) => handleChangePage(page)}
                        count={activities?.last_page || 1}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PaperShadowCustom>
      <Box
        component="div"
        style={{ width: 400 }}
        sx={{ mr: "auto", ml: "auto", mt: 6 }}
      >
        <Box display="flex">
          <ButtonCustom
            title="Précédent"
            color={Colors.primary}
            startIcon={
              <span
                style={{
                  margin: 0,
                  display: "flex",
                  transform: "rotateY(180deg)",
                  marginRight: 6,
                }}
              >
                <NextIcon color={Colors.primary} />
              </span>
            }
            backgroundColor="#B9B9C3"
            variant="outlined"
            onClick={() => navigate("/contract")}
          />
          <Spacing width={55} />
          <ButtonCustom
            type="submit"
            title="Suivant"
            endIcon={<NextIcon color="#fff" />}
            onClick={() => setIsSaveDraft(false)}
          />
        </Box>
        <Spacing height={35} />
        <Box width={225} sx={{ mr: "auto", ml: "auto" }}>
          <ButtonCustom
            type="submit"
            backgroundColor="#B9B9C3"
            variant="outlined"
            title="Enregistrer en brouillon"
            color={Colors.primary}
            onClick={() => setIsSaveDraft(true)}
            endIcon={
              loading && (
                <CircularProgress size={18} style={{ color: Colors.primary }} />
              )
            }
          />
        </Box>
      </Box>
      <Spacing height={100} />
    </form>
  );
};

const useStyles = makeStyles({
  headerTable: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "& > .MuiBox-root": {
      "& > .MuiButtonBase-root": {
        "& > .MuiSvgIcon-root": {
          display: "none",
        },
      },
    },
  },
  boxDescription: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export default Activities;
