import {
  IFilterActivitiesDto,
  IResponseActivitiesOfContract,
} from "./activitiesOfContract.dto";

export enum ActivitiesOfContractTypes {
  GET_ACTIVITIES_REQUESTED = "activitiesOfContract/GET_REQUESTED",
  GET_ACTIVITIES_SUCCEEDED = "activitiesOfContract/GET_SUCCEEDED",
  GET_ACTIVITIES_FAIL = "activitiesOfContract/GET_FAIL",
  CLEAR_MESSAGE_ERROR = "activitiesOfContract/CLEAR",
}

export interface IGetActivitiesRequestedAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_REQUESTED;
  filterDto?: IFilterActivitiesDto;
}

export interface IGetActivitiesSucceededAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_SUCCEEDED;
  activities: IResponseActivitiesOfContract;
  message?: string;
}

export interface IGetActivitiesFailedAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_FAIL;
  error?: any;
}

export interface IClearMessageErrorAction {
  type: typeof ActivitiesOfContractTypes.CLEAR_MESSAGE_ERROR;
}

export type ActivitiesContractActions =
  | IGetActivitiesRequestedAction
  | IGetActivitiesSucceededAction
  | IGetActivitiesFailedAction
  | IClearMessageErrorAction;
