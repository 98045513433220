import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import axios from "axios";
import { IFilterCompanyMongoDto } from "./searchCompany.dto";

// search and get detail information of the company in the Pappers (https://www.pappers.fr/api)
export function searchCompanyService(q: string) {
  const apiUrl = Endpoint.get_companies;

  return new Promise((resolve, rejects) => {
    axios({
      method: "GET",
      url: apiUrl,
      params: {
        cibles: "nom_entreprise,siren,siret",
        q,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => rejects(err));
  });
}

export function getDetailCompanyService(siren: string) {
  const apiUrl = Endpoint.detail_company;

  return new Promise((resolve, rejects) => {
    axios({
      method: "GET",
      url: apiUrl,
      params: {
        api_token: "eae23118a569cfef84aad78e843e723f287001e84bec9ebb",
        siren,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => rejects(err?.response?.data));
  });
}

// search and get detail information of the company in the our server (MongoDB)
export function searchCompanyMongoService(
  filterCompanyMongoDto: IFilterCompanyMongoDto
) {
  const apiUrl = BaseUrl.dev + Endpoint.company;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
      params: filterCompanyMongoDto,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function getDetailCompanyMongoService(id: string) {
  const apiUrl = BaseUrl.dev + Endpoint.company;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl + `/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}
