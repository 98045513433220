import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackgroundForgotPass from "@assets/SvgComponents/BackgroundForgotPass";
import { TextStyles } from "themes/textStyles";
import Spacing from "@components/Spacing";
import { useForm } from "react-hook-form";
import InputCustom from "@components/InputCustom";
import ButtonCustom from "@components/ButtonCustom";
import IconBack from "@assets/SvgComponents/IconBack";
import { Colors } from "themes/colors";
import Logo from "@assets/Images/logo_dashboard.jpg";

interface ForgotPassProps {
  onBack?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ForgotPass: FC<ForgotPassProps> = ({ onBack = () => {} }) => {
  const classes = useStyles();
  const textStyles = TextStyles();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {};

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        style={{ background: "#F8F8F8" }}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>
          <BackgroundForgotPass />
        </p>
        <p className={classes.logo}>
          <img width="100%" height="100%" src={Logo} alt="" />
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        sx={{ pl: 10, pr: 10 }}
      >
        <Typography className={textStyles.h2}>
          Mot de passe oublié ? 🔑
        </Typography>
        <Typography className={textStyles.paragraph}>
          Entrez vous email et nous vous enverrons un lien pour créer votre
          nouveau mot de passe
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <InputCustom
            label="Email"
            placeholder="johndoe@gmail.com"
            {...register("email", {
              pattern: {
                value:
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "Email invalide.",
              },
              required: "L'e-mail est requis.",
            })}
            error={errors?.email && errors?.email?.message}
            sizeLabel="sm"
          />
          <Spacing height={16} />
          <Box component="div">
            <ButtonCustom
              title="Envoyer le lien de réinitialisation"
              type="submit"
            />
          </Box>
        </form>
        <Spacing height={20} />
        <div className={classes.boxBack} onClick={onBack}>
          <IconBack />
          <Spacing width={8} />
          <Typography
            className={textStyles.paragraph}
            style={{ color: Colors.primary }}
          >
            Retour à l’authentification
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  logo: {
    position: "absolute",
    zIndex: 9,
    left: 50,
    top: 30,
    margin: 0,
    width: "170px",
  },
  form: {
    width: "100%",
    marginTop: "1.5rem",
  },
  boxBack: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
});

export default ForgotPass;
