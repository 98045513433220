import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import LogoCompany from "../../../assets/Images/logo.png";
import Logo2 from "../../../assets/Images/logo_2.png";
import ContentPage1 from "./ContentPage1";
import ContentPage10 from "./ContentPage10";
import ContentPage11 from "./ContentPage11";
import ContentPage12 from "./ContentPage12";
import ContentPage13 from "./ContentPage13";
import ContentPage14 from "./ContentPage14";
import ContentPage15 from "./ContentPage15";
import ContentPage2 from "./ContentPage2";
import ContentPage3 from "./ContentPage3";
import ContentPage4 from "./ContentPage4";
import ContentPage5 from "./ContentPage5";
import ContentPage5Artisans from "./ContentPage5Artisans";
import ContentPage6 from "./ContentPage6";
import ContentPage6Artisans from "./ContentPage6Artisans";
import ContentPage7 from "./ContentPage7";
import ContentPage7Artisans from "./ContentPage7Artisans";
import ContentPage8 from "./ContentPage8";
import ContentPage8Artisans from "./ContentPage8Artisans";
import ContentPage9 from "./ContentPage9";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IGeneratingResultPDFProps {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const GeneratingResultPDF: FC<IGeneratingResultPDFProps> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <Document>
      <Page size={[595, 956]} style={styles.rootPage}>
        <View style={styles.header} fixed>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: "172px",
                height: "42px",
              }}
              src={LogoCompany}
            />
            <Image
              style={{
                width: "87px",
                height: "68px",
              }}
              src={Logo2}
            />
          </View>
        </View>

        <ContentPage1 infoInsurance={infoInsurance} isPIB={isPIB} />

        <ContentPage2 infoInsurance={infoInsurance} isPIB={isPIB} />

        <ContentPage3 infoInsurance={infoInsurance} />

        <ContentPage4 isPIB={isPIB} />

        {isPIB ? (
          <ContentPage5
            franchiseDuContrat={infoInsurance?.franchise_du_contrat}
          />
        ) : (
          <ContentPage5Artisans
            franchiseDuContrat={infoInsurance?.franchise_du_contrat}
          />
        )}

        {isPIB ? (
          <ContentPage6 infoInsurance={infoInsurance} />
        ) : (
          <ContentPage6Artisans infoInsurance={infoInsurance} />
        )}

        {isPIB ? <ContentPage7 /> : <ContentPage7Artisans />}

        {isPIB ? (
          <ContentPage8
            fractionnement={infoInsurance?.fractionnement}
            NETCompanyAvecCom={infoInsurance?.NETCompanyAvecCom}
            pricingByPeriod={infoInsurance?.pricingByPeriod}
            cotisations={infoInsurance?.cotisations}
            taux_de_revision_du_contrat={
              infoInsurance?.taux_de_revision_du_contrat
            }
            infoInsurance={infoInsurance}
            frais_de_souscription={infoInsurance?.frais_de_souscription}
          />
        ) : (
          <ContentPage8Artisans
            fractionnement={infoInsurance?.fractionnement}
            NETCompanyAvecCom={infoInsurance?.NETCompanyAvecCom}
            pricingByPeriod={infoInsurance?.pricingByPeriod}
            cotisations={infoInsurance?.cotisations}
            taux_de_revision_du_contrat={
              infoInsurance?.taux_de_revision_du_contrat
            }
            infoInsurance={infoInsurance}
            frais_de_souscription={infoInsurance?.frais_de_souscription}
          />
        )}

        <ContentPage9 isPIB={isPIB} />

        <ContentPage10 isPIB={isPIB} />

        <ContentPage11 isPIB={isPIB} />

        <ContentPage12
          dataActivities={infoInsurance?.dataActivities}
          isPIB={isPIB}
        />

        <ContentPage13 infoInsurance={infoInsurance} />

        <ContentPage14 isPIB={isPIB} />

        <ContentPage15 isPIB={isPIB} />

        <View style={styles.footer} fixed>
          <View style={styles.containerPageNumber}>
            <Text style={styles.textFooter}>
              Numéro de devis : {infoInsurance?.contract_id}
            </Text>
            <Text
              render={({ pageNumber, totalPages }) => (
                <Text style={styles.textFooter}>
                  Page <Text style={styles.textPage}>{pageNumber}</Text> sur{" "}
                  <Text style={styles.textPage}>{totalPages}</Text>
                </Text>
              )}
            ></Text>
          </View>
          <Text style={styles.textFooter}>
            La présente offre ne constitue en aucun cas une garantie ferme mais
            une offre tarifaire basée sur les déclarations faites par le
            Souscripteur. Le présent document n’a aucune valeur contractuelle et
            le candidat à l’assurance ne pourra se prévaloir d’une quelconque
            garantie à ce titre.
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 5,
            }}
          >
            <Image
              style={{
                width: "72px",
                height: "18px",
              }}
              src={LogoCompany}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  rootPage: {
    paddingBottom: 80,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },

  header: {
    display: "flex",
    marginBottom: 10,
  },

  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    padding: "0 40px",
  },

  containerPageNumber: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },

  textFooter: {
    fontFamily: "Montserrat",
    fontSize: 6,
    color: "#6E6B7B",
    fontWeight: 300,
  },

  textPage: {
    fontFamily: "Montserrat",
    fontSize: 6,
    color: "#6E6B7B",
    fontWeight: 500,
  },
});

export default GeneratingResultPDF;
