import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { Colors } from "@themes/colors";
import { convertFormatPhone } from "@utils/convertFormatPhone";
import { formatCustomInput } from "@utils/formatCustomInput";
import formatMoney from "@utils/formatMoney";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage2: FC<IContentPage1Props> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <View break>
      <View>
        <Text style={styles.mainTitle}>Conditions de garantie</Text>
        <View style={styles.underlineMainTitle} />
      </View>

      {isPIB ? (
        <View>
          <View style={{ margin: "15px 0" }}>
            <Text style={generatingResultPDFStyles.mainContent}>
              La police et les garanties proposées sont conditionnées au fait
              que le marché du client ne dépasse pas{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                5.000.000 € (HT)
              </Text>{" "}
              et son effectif ne dépasse pas 49 employés. La garantie proposée
              est également limitée aux marchés dont le coût total de
              construction est inférieur à{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                15.000.000 € (HT)
              </Text>{" "}
              et pour lesquels les honoraires du Souscripteur ne dépassent pas{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                500.000 € (HT)
              </Text>
              . Ces conditions cumulatives sont substantielles et déterminantes
              de l’engagement de l’assureur et de la mobilisation des garanties.
            </Text>
          </View>

          <View>
            <Text style={generatingResultPDFStyles.mainContent}>
              En cas de sous-traitance (limitée à 30% de l’activité sauf accord
              exprès de l’Assureur), la garantie proposée est conditionnée à la
              production par le Souscripteur des attestations RC professionnelle
              et RC décennale du sous-traitant couvrant les activités réellement
              sous-traitées pendant la période de réalisation du chantier.
            </Text>
            <Text style={generatingResultPDFStyles.mainContent}>
              Ces conditions cumulatives sont substantielles et déterminantes de
              l’engagement de l’assureur et de la mobilisation des garanties.
            </Text>
          </View>
        </View>
      ) : null}

      {!isPIB ? (
        <View>
          <View style={{ margin: "15px 0" }}>
            <Text style={generatingResultPDFStyles.mainContent}>
              La police et les garanties proposées sont conditionnées au fait
              que le Chiffre d’affaires du Souscripteur ne dépasse pas{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                5.000.000 € (HT).
              </Text>
              La police proposée a pour objet de garantir les interventions du
              souscripteur sur les chantiers de construction à condition que le
              coût global des travaux tous corps d'état ne soit pas supérieur à
              15 000 000 Euros (HT) (sauf si un CCRD a été conclu). Par
              ailleurs, le chiffre d’affaires du souscripteur doit être
              inférieur à{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                5.000.000 € (HT)
              </Text>{" "}
              et l’effectif est limité à{" "}
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  lineHeight: 1.7,
                  fontWeight: 500,
                }}
              >
                49
              </Text>{" "}
              employés.
            </Text>
          </View>

          <View style={{ marginBottom: 15 }}>
            <Text style={generatingResultPDFStyles.mainContent}>
              Ces conditions cumulatives sont substantielles et déterminantes de
              l’engagement de l’Assureur et de la mobilisation des garanties.
            </Text>
          </View>

          <View>
            <Text style={generatingResultPDFStyles.mainContent}>
              En cas de sous-traitance (limitée à 30% de l’activité sauf accord
              exprès de l’Assureur), la garantie proposée est conditionnée à la
              production par l’assuré des attestations RC professionnelle et RC
              décennale du sous-traitant couvrant les activités réellement
              sous-traitées pendant la période de réalisation du chantier. Ces
              conditions cumulatives sont substantielles et déterminantes de
              l’engagement de l’assureur et de la mobilisation des garanties.
            </Text>
          </View>
        </View>
      ) : null}

      <View style={{ height: 25 }} />

      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <Text
            style={[generatingResultPDFStyles.titleHeaderTable, { padding: 4 }]}
          >
            INFORMATIONS DU SOUSCRIPTEUR
          </Text>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Souscripteur :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.souscripteur}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Adresse :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.address},{" "}
              {infoInsurance?.informations_du_souscripteur_1?.codePostal &&
                formatCustomInput(
                  infoInsurance?.informations_du_souscripteur_1?.codePostal
                )}
              , {infoInsurance?.informations_du_souscripteur_1?.city}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Forme juridique :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.forme_juridique}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Téléphone :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.phone &&
                formatCustomInput(
                  convertFormatPhone(
                    infoInsurance?.informations_du_souscripteur_1?.phone
                  ),
                  "phone"
                )}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Email :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.email}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              N° d’immatriculation :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1
                ?.dimmatriculation &&
                formatMoney(
                  infoInsurance?.informations_du_souscripteur_1
                    ?.dimmatriculation
                )}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Date de création :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.date_creation}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Effectif :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.effectif}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Chiffre d’affaires HT:{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.chiffre_HT &&
                formatCustomInput(
                  infoInsurance?.informations_du_souscripteur_1?.chiffre_HT
                )}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Dirigeant :{" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.dirigeant}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ height: 30 }} />

      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <Text
            style={[generatingResultPDFStyles.titleHeaderTable, { padding: 4 }]}
          >
            ACTIVITES PROFESSIONNELLES EXERCEES
          </Text>
        </View>

        {infoInsurance?.activities &&
          infoInsurance?.activities?.map((activity: any, index: number) => (
            <View
              style={
                index % 2 === 0
                  ? generatingResultPDFStyles.itemTableOdd
                  : generatingResultPDFStyles.itemTableEven
              }
              key={index}
            >
              <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {activity?.name}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: 0.5,
                  backgroundColor: "#FFCC4D",
                }}
              />
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text
                  style={generatingResultPDFStyles.contentTablePage2}
                >{`${activity?.percent} %`}</Text>
              </View>
            </View>
          ))}
        {/* <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Assitant maître d’oeuvre
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>XX %</Text>
          </View>
        </View> */}
      </View>

      <View style={{ height: 25 }} />
      <View>
        <Text style={generatingResultPDFStyles.mainContent}>
          Attention : dès lors que figure dans la définition d’une activité la
          mention de « travaux accessoires et/ou complémentaires», il est
          rappelé que lesdits travaux répertoriés comme « accessoires et/ou
          complémentaires », ne peuvent en aucun cas faire l’objet d’un marché
          de travaux à part entière. Si tel était le cas, ces travaux seraient
          alors réputés non garantis.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 12,
    color: "#5E5873",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  underlineMainTitle: {
    width: "69px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 5,
  },
});

export default ContentPage2;
