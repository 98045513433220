import { IStatisticDto } from "./statistic.dto";

export enum StatisticTypes {
  GET_STATISTIC_REQUESTED = "stastic/GET_REQUESTED",
  GET_STATISTIC_SUCCEEDED = "stastic/GET_SUCCEEDED",
  GET_STATISTIC_FAILED = "stastic/GET_FAILED",
}

export interface IGetStatisticRequestedAction {
  type: typeof StatisticTypes.GET_STATISTIC_REQUESTED;
}

export interface IGetStatisticSucceededAction {
  type: typeof StatisticTypes.GET_STATISTIC_SUCCEEDED;
  listStatistic: Array<IStatisticDto>;
}

export interface IGetStatisticFailedAction {
  type: typeof StatisticTypes.GET_STATISTIC_FAILED;
  error?: any;
}

export type StatisticActions =
  | IGetStatisticRequestedAction
  | IGetStatisticSucceededAction
  | IGetStatisticFailedAction;
