export interface IFilterUsersDto {
  sortBy?: object;
  nolimit?: boolean;
  page?: number;
  limit?: number;
  name?: string;
}
export enum RoleName {
  admin = "Admin",
  user = "User",
}
export type RoleTypes = "Admin" | "User";
export enum PermissionCode {
  CREATE_USER = "create-user",
  READ_USER = "read-user",
  UPDATE_USER = "update-user",
  DELETE_USER = "delete-user",

  ARCHIVE_CONTRACT = "archive-contract",
}
export interface ICreateUserDto {
  email?: string;
  name?: string;
  firstName?: string;
  phoneNumber?: string;
  role?: RoleTypes;
}
export interface IUser {
  _id: string;
  email?: string;
  phoneNumber?: number;
  roleIds?: Array<string>;
  name?: string;
  firstName?: string;
  roles: Array<{ name?: string; _id?: string }>;
}
export interface IResponseUsers {
  total?: number;
  limit?: number;
  page?: number;
  last_page?: number;
  data?: Array<IUser>;
}
export interface IUsersState {
  dataUsers?: IResponseUsers;
  loadingGet?: boolean;
  loadingDelete?: boolean;
  loadingCreate?: boolean;
  message?: string;
  error?: any;
  detailUser?: IUser;
  idUserUpdate?: string;
}
