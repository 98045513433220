import { Font, Text, View } from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import formatMoney from "@utils/formatMoney";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage6Props {
  infoInsurance?: IInfoContractPDF;
}

const ContentPage6Artisans: FC<IContentPage6Props> = ({ infoInsurance }) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <View style={{ flex: 3 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Nature des garanties
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 2, padding: 4 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Montant des garanties par
            </Text>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              année d’assurance
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 1.4, marginRight: -8 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Franchises
            </Text>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>(2)</Text>
          </View>
        </View>
        <Text
          style={{
            color: "#5E5873",
            fontSize: "9px",
            fontFamily: "Montserrat",
            padding: 8,
            backgroundColor: "#FFF9E8",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          RESPONSABILITE CIVILE DECENNALE
        </Text>
        {/* Odd and even in here is not correct and it's just for have correct styles */}
        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              A. Tous dommages confondus dont :
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            {/* <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text> */}
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              B. Dommages corporels et immatériels consécutifs
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_3 &&
                formatMoney(infoInsurance.franchise_du_contrat)}{" "}
              €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>Néant</Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <Text
                style={[
                  generatingResultPDFStyles.mainContent,
                  { paddingLeft: 20 },
                ]}
              >
                .Limité en cas de faute inexcusable à
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              350 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>Néant</Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              C. Dommages matériels et immatériels consécutifs
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              550 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €(1)
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              D. Dommages subis par les biens confiés
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>EXCLU</Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            {/* <Text style={generatingResultPDFStyles.textFranchise}>{infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "} €(1)</Text> */}
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              E. Dommages immatériels non consécutifs
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              50 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €(1)
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              F. Dommages résultant d'erreur d'implantation de constructions
              (tous dommages confondus)
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>EXCLU</Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            {/* <Text style={generatingResultPDFStyles.textFranchise}>{infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "} €(1)</Text> */}
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              G. Dommages par atteintes à l'environnement
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              50 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <Text
                style={[
                  generatingResultPDFStyles.mainContent,
                  { paddingLeft: 20 },
                ]}
              >
                .dont frais d'urgence
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              10 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>Néant</Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              H. Pertes pécuniaires environnementales
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              50 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <Text
                style={[
                  generatingResultPDFStyles.mainContent,
                  { paddingLeft: 20 },
                ]}
              >
                .dont responsabilité environnementale
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              10 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <Text
                style={[
                  generatingResultPDFStyles.mainContent,
                  { paddingLeft: 20 },
                ]}
              >
                .dont frais de dépollution des sols et des eaux
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              10 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <Text
                style={[
                  generatingResultPDFStyles.mainContent,
                  { paddingLeft: 20 },
                ]}
              >
                .dont frais de dépollution des biens immobiliers et mobiliers
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              10 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>
      </View>

      <View style={{ height: 5 }} />
      <View style={generatingResultPDFStyles.li}>
        <View style={generatingResultPDFStyles.dotLi} />
        <Text
          style={[
            generatingResultPDFStyles.mainContent,
            generatingResultPDFStyles.textItalic,
          ]}
        >
          (1) Les niveaux de franchises sont multipliés par DEUX en cas de
          travaux par points chauds si non respect de la procédure du permis de
          feu, et en cas d'explosion si non respect de la procédure DICT
          (Déclaration d'intention de commencement de travaux), dès lors que ces
          procédures sont applicables.
        </Text>
      </View>

      <View style={generatingResultPDFStyles.li}>
        <View style={generatingResultPDFStyles.dotLi} />
        <Text
          style={[
            generatingResultPDFStyles.mainContent,
            generatingResultPDFStyles.textItalic,
          ]}
        >
          (2) Pour un même sinistre, il sera procédé à un cumul des franchises
          afférentes à chaque garantie.
        </Text>
      </View>

      <View style={{ height: 5 }} />
      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <View style={{ flex: 3 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Nature des garanties
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 2, padding: 4 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Montant des garanties par sinistre
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 1.4, marginRight: -8 }}>
            <Text style={[generatingResultPDFStyles.titleHeaderTable]}>
              Franchises (1)
            </Text>
          </View>
        </View>
        <Text
          style={{
            color: "#5E5873",
            fontSize: "9px",
            fontFamily: "Montserrat",
            padding: 8,
            backgroundColor: "#FFF9E8",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          DOMMAGES SUBIS PAR LES TRAVAUX et EQUIPEMENT AVANT RECEPTION
        </Text>
        {/* Odd and even in here is not correct and it's just for have correct styles */}
        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              A. Dommages matériels (y compris les frais de déblaiement)
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              500 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              B. Dommages immatériels consécutifs à un dommage matériel garanti
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              50 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              C. Assurance des dommages causés par les catastrophes naturelles
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              250 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.4 }}>
            <Text
              style={[
                generatingResultPDFStyles.mainContent,
                { textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              Selon les
            </Text>
            <Text
              style={[
                generatingResultPDFStyles.mainContent,
                { textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              dispositions légales
            </Text>
            <Text
              style={[
                generatingResultPDFStyles.mainContent,
                { textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              (figurant dans les
            </Text>
            <Text
              style={[
                generatingResultPDFStyles.mainContent,
                { textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              Conventions
            </Text>
            <Text
              style={[
                generatingResultPDFStyles.mainContent,
                { textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              Spéciales).
            </Text>
          </View>
        </View>
      </View>

      <View style={{ height: 5 }} />
      <View style={generatingResultPDFStyles.li}>
        <View style={generatingResultPDFStyles.dotLi} />
        <Text
          style={[
            generatingResultPDFStyles.mainContent,
            generatingResultPDFStyles.textItalic,
          ]}
        >
          (1) Pour un même sinistre (hors catastrophes naturelles), il sera
          procédé à un cumul des franchises afférentes à chaque garantie.
        </Text>
      </View>
    </View>
  );
};

export default ContentPage6Artisans;
