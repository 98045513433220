import {
  IListActivitiesOfContract,
  IResponseActivitiesOfContract,
} from "./activitiesOfContract.dto";
import {
  ActivitiesContractActions,
  ActivitiesOfContractTypes,
} from "./activitiesOfContract.type";

const initialActivitiesOfContract: IListActivitiesOfContract = {
  activities: {} as IResponseActivitiesOfContract,
  loading: false,
};

export function getActivitiesReducer(
  state = initialActivitiesOfContract,
  action: ActivitiesContractActions
): IListActivitiesOfContract {
  switch (action.type) {
    case ActivitiesOfContractTypes.GET_ACTIVITIES_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActivitiesOfContractTypes.GET_ACTIVITIES_SUCCEEDED: {
      return {
        ...state,
        activities: action.activities,
        loading: false,
      };
    }

    case ActivitiesOfContractTypes.GET_ACTIVITIES_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case ActivitiesOfContractTypes.CLEAR_MESSAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: undefined,
        message: undefined,
      };
    }

    default:
      return state;
  }
}
