import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextStyles } from "themes/textStyles";
import Spacing from "@components/Spacing";
import InputCustom from "@components/InputCustom";
import ButtonCustom from "@components/ButtonCustom";
import { Controller, useForm } from "react-hook-form";
import Logo from "@assets/Images/logo_dashboard.jpg";
import BackgroundChangePass from "@assets/SvgComponents/BackgroundChangePass";
import IconBack from "@assets/SvgComponents/IconBack";
import { Colors } from "themes/colors";
import { useNavigate } from "react-router-dom";

export interface IChangePasswordProps {}

export const ChangePassword: FC<IChangePasswordProps> = () => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (value: any) => {
    console.log(value);
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        style={{ background: "#F8F8F8" }}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>
          <BackgroundChangePass />
        </p>
        <p className={classes.logo}>
          <img width="100%" height="100%" src={Logo} alt="" />
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        sx={{ pl: 10, pr: 10 }}
      >
        <Typography className={textStyles.h2}>
          Changer de mot de passe 🔐
        </Typography>
        <Typography className={textStyles.paragraph}>
          Mettez à jour le mot de passe de votre compte.
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputCustom
                onChange={onChange}
                value={value}
                label="Nouveau mot de passe"
                type="password"
                placeholder="Password"
                error={errors?.password && errors?.password?.message}
                autoComplete="current-password"
                sizeLabel="sm"
              />
            )}
            rules={{
              required: "Mot de passe requis.",
            }}
          />
          <Spacing height={16} />
          <Controller
            name="password"
            render={({ field: { onChange, value } }) => (
              <InputCustom
                label="Confirmer le mot de passe"
                type="password"
                error={errors?.password && errors?.password?.message}
                value={value}
                placeholder="Password"
                onChange={onChange}
                autoComplete="new-password"
                sizeLabel="sm"
              />
            )}
            control={control}
            rules={{
              required: "Mot de passe requis.",
            }}
          />
          <Spacing height={16} />
          <Box component="div">
            <ButtonCustom type="submit" title="Set New Password" />
          </Box>
          <Spacing height={20} />
          <div className={classes.boxBack} onClick={() => navigate("/login")}>
            <IconBack />
            <Spacing width={8} />
            <Typography
              className={textStyles.paragraph}
              style={{ color: Colors.primary }}
            >
              Back to login
            </Typography>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  logo: {
    position: "absolute",
    zIndex: 9,
    left: 50,
    top: 30,
    margin: 0,
    width: "170px",
  },
  form: {
    width: "100%",
    marginTop: "1.5rem",
  },
  titleForgot: {
    fontWeight: "500 !important",
    fontSize: "0.75rem !important",
    cursor: "pointer",
  },
  boxBack: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
});
