import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import { IFilterActivitiesDto } from "./activitiesOfContract.dto";

interface IParamsQueryActivities {
  _id?: string;
  name?: string;
  limit?: number;
  page?: number;
  sortBy?: any;
}

export function getActivitiesContractService(filterDto?: IFilterActivitiesDto) {
  const apiUrl =
    BaseUrl.dev +
    Endpoint.profession +
    `/${filterDto?.idProfession}/activities`;

  const paramsQueryActivities: IParamsQueryActivities = {};

  if (filterDto?._id) {
    paramsQueryActivities._id = filterDto._id;
  }
  if (filterDto?.name) {
    paramsQueryActivities.name = filterDto.name;
  }
  if (filterDto?.limit) {
    paramsQueryActivities.limit = filterDto.limit;
  }
  if (filterDto?.page) {
    paramsQueryActivities.page = filterDto.page;
  }
  if (filterDto?.sortBy) {
    paramsQueryActivities.sortBy = filterDto.sortBy;
  }

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
      params: paramsQueryActivities,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (!err?.response) {
          rejects("Erreur réseau.");
        } else {
          rejects(err?.response?.data?.message);
        }
      });
  });
}
