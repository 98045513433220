import React from "react";
import { Box, Grid } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import { PaperShadowCustom } from "@components/PaperCustom";
import ButtonCustom from "@components/ButtonCustom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ManagementUsers = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperShadowCustom sx={{ py: 1.5, px: 4 }}>
          <Box component="div" width={181} sx={{ mr: 0, ml: "auto" }}>
            <Link to="/search-company" style={{ textDecoration: "none" }}>
              <ButtonCustom
                title="Nouveau devis"
                startIcon={
                  <AddCircleOutlineIcon
                    style={{ marginRight: 5, fontSize: 14 }}
                  />
                }
              />
            </Link>
          </Box>
        </PaperShadowCustom>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default ManagementUsers;
