export enum Colors {
  primary = "#FFDB5C",
  secondary = "#82868B",
  success = "#28C76F",
  error = "#EA5455",
  warning = "#FF9F43",
  info = "#00CFE8",
  dark = "#4B4B4B",
  light = "#BABFC7",

  purple = "#8C81FF",
}
