import React, { FC } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import LogoCompany from "../../../assets/Images/logo.png";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { ICreateContractDto } from "@stores/contract/contract.dto";
import {
  ICreateCompanyDto,
  LeaderExperience,
} from "@stores/createNewCompany/createNewCompany.dto";
import moment from "moment";
import * as _ from "lodash";
import { IActivityOfContract } from "@stores/activitiesOfContract/activitiesOfContract.dto";
// import parse from "html-react-parser";
import { IActivitiesSelected } from "../CalculateResult";
import { formatCustomInput } from "@utils/formatCustomInput";
import { convertFormatPhone } from "@utils/convertFormatPhone";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IDocumentPDFProps {
  contract?: ICreateContractDto;
  company?: ICreateCompanyDto;
  activities?: Array<IActivityOfContract>;
  reason?: string;
}

const GeneratingQuestionPDF: FC<IDocumentPDFProps> = ({
  contract,
  company,
  activities,
  reason,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.rootPage}>
        <View style={styles.header}>
          <View style={{ marginBottom: 40 }}>
            <Image
              style={{
                width: "172px",
                height: "42px",
              }}
              src={LogoCompany}
            />
          </View>
          <Text style={styles.titleHeader}>QUESTIONNAIRE D’ETUDE</Text>
        </View>
        <View style={styles.body}>
          <View style={styles.subContent}>
            <Text style={styles.titleSubContent}>
              Récapitulatif des données de l’entreprise
            </Text>

            <View style={styles.boxSubContent}>
              <View style={styles.boxTitleSubContent}>
                <Text style={styles.titleBoxSubContent}>
                  Information de la société
                </Text>
                <View style={styles.underlineTitleBoxContent} />
              </View>
              <View style={styles.boxInfoDetail}>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Nom de la société :</Text>
                  <Text style={styles.contentItemInfo}>{company?.name}</Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>SIREN : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.siren && formatCustomInput(company?.siren)}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Code NAF : </Text>
                  <Text style={styles.contentItemInfo}>{company?.codeNAF}</Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Adresse du siège : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.headquarterAddress}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Ville : </Text>
                  <Text style={styles.contentItemInfo}>{company?.city}</Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Code postal : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.codePostal}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Capital : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.capitalSocial
                      ? formatCustomInput(company?.capitalSocial)
                      : 0}{" "}
                    €
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Date de création : </Text>
                  <Text style={styles.contentItemInfo}>
                    {moment(company?.birthday).format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.boxSubContent}>
              <View style={styles.boxTitleSubContent}>
                <Text style={styles.titleBoxSubContent}>
                  Information du représentant légal
                </Text>
                <View style={styles.underlineTitleBoxContent} />
              </View>
              <View style={styles.boxInfoDetail}>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>
                    NOM et prénom du représentant légal :
                  </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.legalRepresentative?.name}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Date de naissance : </Text>
                  {company?.legalRepresentative?.birthday && (
                    <Text style={styles.contentItemInfo}>
                      {moment(company.legalRepresentative.birthday).format(
                        "DD/MM/YYYY"
                      )}
                    </Text>
                  )}
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Email :</Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.legalRepresentative?.email}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Téléphone :</Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.legalRepresentative?.phoneNumber &&
                      formatCustomInput(
                        convertFormatPhone(
                          company?.legalRepresentative?.phoneNumber
                        ),
                        "phone"
                      )}
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Diplôme : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.companyDetails?.diploma}
                  </Text>
                </View>
                {company?.companyDetails?.leaderExperience && (
                  <View style={styles.itemInfoDetail}>
                    <Text style={styles.labelItemInfo}>
                      Nombre d’années d’expérience du dirigeant :{" "}
                    </Text>
                    <Text style={styles.contentItemInfo}>
                      {company?.companyDetails?.leaderExperience ===
                      LeaderExperience.LESS_THAN_ONE_YEAR
                        ? "Moins de 1 an"
                        : company?.companyDetails?.leaderExperience ===
                          LeaderExperience.FROM_1_TO_3_YEARS
                        ? "1 à 3 ans"
                        : company?.companyDetails?.leaderExperience ===
                          LeaderExperience.FROM_3_TO_5_YEARS
                        ? "3 à 5 ans"
                        : company?.companyDetails?.leaderExperience ===
                          LeaderExperience.FROM_5_TO_10_YEARS
                        ? "5 à 10 ans"
                        : "> 10 ans"}
                    </Text>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.boxSubContent}>
              <View style={styles.boxTitleSubContent}>
                <Text style={styles.titleBoxSubContent}>
                  Détails de l’entreprise
                </Text>
                <View style={styles.underlineTitleBoxContent} />
              </View>
              <View style={styles.boxInfoDetail}>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>
                    Chiffre d’affaire en cours ou prévisionnel pour création :
                  </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.companyDetails?.revenue &&
                      formatCustomInput(company?.companyDetails?.revenue)}
                    €
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>
                    % du chiffre d’affaire de sous-traitant :{" "}
                  </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.companyDetails?.percentMadeBySubContractors}%
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>
                    % du CA que vous confiez à des sous-traitants :{" "}
                  </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.companyDetails?.percentEntrustToSubContractors}%
                  </Text>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>
                    L’entreprise est-t-elle en redressement judiciaire ?
                  </Text>
                  <Text style={styles.contentItemInfo}>
                    {contract?.isInReceivership ? "Oui" : "Non"}
                  </Text>
                </View>
                <View style={styles.boxInfoSlider}>
                  <View style={styles.itemSlider}>
                    <Text style={styles.titleItemSlider}>
                      Typologie de clients
                    </Text>
                    <View style={styles.itemInfoDetail}>
                      <Text style={styles.labelItemInfo}>Particulier : </Text>
                      <Text style={styles.contentItemInfo}>
                        {company?.companyDetails?.percentIndividuals}%
                      </Text>
                    </View>
                    <View style={styles.itemInfoDetail}>
                      <Text style={styles.labelItemInfo}>Entreprises : </Text>
                      <Text style={styles.contentItemInfo}>
                        {company?.companyDetails?.percentEnterprises}%
                      </Text>
                    </View>
                  </View>
                  <View style={styles.itemSliderRight}>
                    <Text style={styles.titleItemSlider}>Type de travaux</Text>
                    <View style={styles.itemInfoDetail}>
                      <Text style={styles.labelItemInfo}>Ancien : </Text>
                      <Text style={styles.contentItemInfo}>
                        {company?.companyDetails?.percentRenovation}%
                      </Text>
                    </View>
                    <View style={styles.itemInfoDetail}>
                      <Text style={styles.labelItemInfo}>Neuf : </Text>
                      <Text style={styles.contentItemInfo}>
                        {company?.companyDetails?.percentNew}%
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.itemInfoDetail}>
                  <Text style={styles.labelItemInfo}>Certification : </Text>
                  <Text style={styles.contentItemInfo}>
                    {company?.companyDetails?.certification}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ height: 30 }} />
          </View>

          <View style={styles.subContent}>
            <Text style={styles.titleSubContent}>
              Récapitulatif des activités de l’entreprise
            </Text>
            <View style={styles.boxSubContent}>
              <View style={styles.boxTitleSubContent}>
                <Text style={styles.titleBoxSubContent}>
                  Activités de la société
                </Text>
                <View style={styles.underlineTitleBoxContent} />
              </View>
              <View style={{ height: 20 }} />
              <View style={styles.table}>
                <View style={styles.headerTable}>
                  <View style={{ width: "50%" }}>
                    <Text style={styles.titleHeaderTable}>ACTIVITE</Text>
                  </View>
                  <View style={{ width: "50%" }}>
                    <Text style={styles.titleHeaderTable}>POURCENTAGE</Text>
                  </View>
                </View>
                {_.isArray(activities) &&
                  activities.map(
                    (activity: IActivitiesSelected, index: number) => (
                      <View style={styles.bodyTable} key={index}>
                        <View
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",

                            paddingLeft: 4,
                          }}
                        >
                          <Text style={styles.contentItemInfo}>
                            {activity.name}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={styles.inputTable1}>
                            {activity?.percent}
                          </Text>
                          <Text
                            style={{
                              fontSize: 11,
                              color: "#5E5873",
                              fontWeight: 500,
                              fontFamily: "Montserrat",
                            }}
                          >
                            %
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                <View style={styles.footerTable1}>
                  <Text
                    style={{
                      color: "#6E6B7B",
                      fontWeight: 500,
                      fontSize: 11,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Total
                  </Text>
                  <Text style={styles.inputTableTotal1}>
                    {contract?.activities?.reduce(
                      (acc, current) => acc + current.percent,
                      0
                    )}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                      color: "#5E5873",
                    }}
                  >
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.subContent}>
            <Text style={styles.titleSubContent}>
              Récapitulatif des antécédants d’assurance
            </Text>
            <View style={styles.boxSubContent}>
              <View style={styles.boxTitleSubContent}>
                <Text style={styles.titleBoxSubContent}>
                  Antécédants d’assurance
                </Text>
                <View style={styles.underlineTitleBoxContent} />
              </View>
              <View style={{ height: 15 }} />
              {contract?.insuranceHistory?.hasBeenRCD ? (
                <>
                  <Text
                    style={{
                      color: "#6E6B7B",
                      marginBottom: 6,
                      fontSize: 9,
                      fontFamily: "Montserrat",
                    }}
                  >
                    L’entreprise{" "}
                    <Text
                      style={{
                        color: "#6E6B7B",
                        marginBottom: 6,
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      a été assurée
                    </Text>{" "}
                    en RC et RCD pour les activités depuis :{" "}
                    <Text
                      style={{
                        color: "#6E6B7B",
                        marginBottom: 6,
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {company?.birthday &&
                        moment(
                          contract?.insuranceHistory?.dateWithoutInteruption
                        )
                          .toDate()
                          .getFullYear() -
                          new Date(company?.birthday).getFullYear()}
                    </Text>{" "}
                    ans.
                  </Text>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Montserrat",
                      color: "#6E6B7B",
                      marginBottom: 6,
                    }}
                  >
                    Le dernier assureur étant{" "}
                    <Text
                      style={{
                        color: "#6E6B7B",
                        marginBottom: 6,
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {contract?.insuranceHistory?.lastInsurer}
                    </Text>
                    .
                  </Text>
                </>
              ) : (
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Montserrat",
                    color: "#6E6B7B",
                    marginBottom: contract?.insuranceHistory?.hasBeenRCD
                      ? 20
                      : 10,
                  }}
                >
                  L’entreprise n’a jamais assurée en RC et RCD pour les
                  activités à garantir
                </Text>
              )}
              {contract?.insuranceHistory?.hasBeenRCD && (
                <>
                  {contract?.insuranceHistory?.isInProgress ? (
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        color: "#6E6B7B",
                        marginBottom: 20,
                      }}
                    >
                      Le contrat{" "}
                      <Text
                        style={{
                          color: "#6E6B7B",
                          marginBottom: 6,
                          fontSize: 9,
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        est toujours
                      </Text>{" "}
                      en cours depuis le{" "}
                      <Text
                        style={{
                          color: "#6E6B7B",
                          marginBottom: 6,
                          fontSize: 9,
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        {moment(
                          contract?.insuranceHistory?.progressDate
                        ).format("DD/MM/YYYY")}
                      </Text>
                      .
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        color: "#6E6B7B",
                        marginBottom: 20,
                      }}
                    >
                      Le contrat{" "}
                      <Text
                        style={{
                          color: "#6E6B7B",
                          marginBottom: 6,
                          fontSize: 9,
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        n’est plus
                      </Text>{" "}
                      en cours depuis le{" "}
                      <Text
                        style={{
                          color: "#6E6B7B",
                          marginBottom: 6,
                          fontSize: 9,
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        {moment(
                          contract?.insuranceHistory?.progressCancellationDate
                        ).format("DD/MM/YYYY")}
                      </Text>{" "}
                      pour le motif suivant :{" "}
                      <Text
                        style={{
                          color: "#6E6B7B",
                          marginBottom: 6,
                          fontSize: 9,
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        {reason}
                      </Text>
                    </Text>
                  )}
                </>
              )}
              {contract?.insuranceHistory?.hasBeenRCD && (
                <>
                  {_.isArray(contract?.insuranceHistory?.claimDetails) &&
                  contract?.insuranceHistory?.claimDetails &&
                  contract.insuranceHistory.claimDetails.length > 0 ? (
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        color: "#6E6B7B",
                        marginBottom: 0,
                        fontWeight: 500,
                      }}
                    >
                      L’entreprise a fait l’objet de sinitres au cours des 5
                      dernières années.
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Montserrat",
                        color: "#6E6B7B",
                        marginBottom: 10,
                        fontWeight: 500,
                      }}
                    >
                      L’entreprise n’a pas fait l’objet de sinitres au cours des
                      5 dernières années.
                    </Text>
                  )}
                  {contract?.insuranceHistory?.hasBeenRCD &&
                    _.isArray(contract?.insuranceHistory?.claimDetails) &&
                    contract?.insuranceHistory?.claimDetails &&
                    contract.insuranceHistory.claimDetails.length > 0 && (
                      <View style={styles.table}>
                        <View style={styles.headerTable}>
                          <View style={{ width: "20%" }}></View>
                          <View style={{ width: "40%" }}>
                            <Text style={styles.titleHeaderTable}>
                              NOMBRE DE SINITRES
                            </Text>
                          </View>
                          <View style={{ width: "40%" }}>
                            <Text style={styles.titleHeaderTable}>
                              MONTANT DES SINITRES
                            </Text>
                          </View>
                        </View>
                        {[0, 1, 2, 3, 4].map((item: number) => {
                          const claim =
                            contract?.insuranceHistory?.claimDetails?.find(
                              (claim) => claim.relativeBackwardYear === item
                            );
                          return (
                            <View style={styles.bodyTable} key={item}>
                              <View
                                style={{
                                  width: "20%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <View style={{ marginLeft: 10 }}>
                                  <Text style={styles.contentItemInfo}>
                                    {item === 0 ? "ANNEE N" : `ANNEE N-${item}`}
                                  </Text>
                                </View>
                              </View>
                              <View
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  justifyContent: "center",

                                  paddingLeft: 4,
                                }}
                              >
                                <Text style={styles.inputTable2}>
                                  {claim ? claim?.numberOfClaims : "-"}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  paddingLeft: 4,
                                }}
                              >
                                <Text style={styles.inputTable2}>
                                  {claim
                                    ? formatCustomInput(claim?.amount)
                                    : "-"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 8,
                                    color: "#5E5873",
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                  }}
                                >
                                  €
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "15px 0px 15px 15px",
                          }}
                        >
                          <View
                            style={{
                              width: "20%",
                              display: "flex",

                              justifyContent: "center",
                              paddingLeft: 4,
                            }}
                          >
                            <Text
                              style={{
                                color: "#6E6B7B",
                                fontSize: 11,
                                fontWeight: 500,
                                fontFamily: "Montserrat",
                              }}
                            >
                              Total
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Text style={styles.inputTableFooter2}>
                              {contract?.insuranceHistory?.claimDetails?.reduce(
                                (acc, current) => acc + current.numberOfClaims,
                                0
                              )}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Text style={styles.inputTableFooter2}>
                              {formatCustomInput(
                                contract?.insuranceHistory?.claimDetails?.reduce(
                                  (acc, current) => acc + current.amount,
                                  0
                                )
                              )}
                            </Text>
                            <Text
                              style={{
                                fontSize: 8,
                                fontFamily: "Montserrat",
                                color: "#5E5873",
                                fontWeight: 500,
                              }}
                            >
                              €
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                </>
              )}
              {contract?.insuranceHistory?.isInsuredThePast ? (
                <Text
                  style={{
                    fontSize: 9,
                    color: "#6E6B7B",
                    fontFamily: "Montserrat",
                  }}
                >
                  L’entreprise souhaite démarrer son contrat{" "}
                  <Text
                    style={{
                      color: "#6E6B7B",
                      marginBottom: 6,
                      fontSize: 9,
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                    }}
                  >
                    le{" "}
                    {moment(
                      contract?.insuranceHistory?.contractStartDate
                    ).format("DD/MM/YYYY")}{" "}
                  </Text>
                  avec une reprise du passé au{" "}
                  <Text
                    style={{
                      color: "#6E6B7B",
                      marginBottom: 6,
                      fontSize: 9,
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                    }}
                  >
                    {moment(
                      contract?.insuranceHistory?.resumptionDatePast
                    ).format("DD/MM/YYYY")}{" "}
                  </Text>
                  .
                </Text>
              ) : (
                <Text
                  style={{
                    fontSize: 9,
                    color: "#6E6B7B",
                    fontFamily: "Montserrat",
                  }}
                >
                  L’entreprise souhaite démarrer son contrat{" "}
                  <Text
                    style={{
                      color: "#6E6B7B",
                      marginBottom: 6,
                      fontSize: 9,
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                    }}
                  >
                    le{" "}
                    {moment(
                      contract?.insuranceHistory?.contractStartDate
                    ).format("DD/MM/YYYY")}{" "}
                    sans la reprise du passé
                  </Text>
                  .
                </Text>
              )}
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={{ flex: 1, paddingRight: 20, paddingTop: 5 }}>
            <Text style={styles.textFooter}>
              MADECENNALE – 1047 route des Dollines, Business Pôle Bat A, 06560{" "}
              Valbonne - N° Siren : 811 021 260 - N° Orias : 15003712 - Tél :{" "}
              01.84.80.47.73 -{" "}
              <Link src="info@madecennale.com">info@madecennale.com</Link> -{" "}
              <Link src="www.madecennale.com">www.madecennale.com</Link>
            </Text>
          </View>
          <Image
            style={{
              width: "72px",
              height: "18px",
            }}
            src={LogoCompany}
          />
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  rootPage: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  header: {
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  titleHeader: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.primary,
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  body: {
    display: "flex",
    width: "100%",
    paddingTop: 30,
    flexDirection: "column",
  },
  subContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  titleSubContent: {
    backgroundColor: "#F3F2F7",
    paddingTop: 8,
    paddingBottom: 8,
    color: "#5E5873",
    paddingLeft: 40,
    fontSize: 11,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  textFooter: {
    fontFamily: "Montserrat",
    fontSize: 6,
    color: "#6E6B7B",
    fontWeight: 300,
  },
  boxSubContent: {
    paddingLeft: 40,
    display: "flex",
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: "column",
    paddingRight: 40,
  },
  boxTitleSubContent: {
    display: "flex",
    flexDirection: "column",
  },
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 11,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  underlineTitleBoxContent: {
    width: "52px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 4,
  },
  boxInfoDetail: {
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
  },
  itemInfoDetail: {
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
  },
  labelItemInfo: {
    fontSize: "9px",
    color: "#6E6B7B",
    marginRight: 4,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  contentItemInfo: {
    fontSize: "9px",
    color: "#6E6B7B",
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
  boxInfoSlider: {
    display: "flex",
    marginTop: 20,
    flexDirection: "row",
  },
  itemSlider: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  itemSliderRight: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    borderLeft: "1px solid #B9B9C3",
    paddingLeft: 100,
    paddingBottom: 30,
  },
  titleItemSlider: {
    color: Colors.primary,
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  headerTable: {
    display: "flex",
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#F3F2F7",
    paddingLeft: 15,
    flexDirection: "row",
  },
  titleHeaderTable: {
    fontSize: 9,
    fontWeight: 500,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  bodyTable: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 0px 15px 10px",
    borderBottom: "1px solid #E9ECEF",
  },
  inputTable1: {
    color: "#5E5873",
    backgroundColor: "#F8F8F8",
    width: "55px",
    height: "19px",
    borderRadius: 3,
    marginRight: 5,
    fontSize: 9,
    textAlign: "center",
    paddingTop: 4,
    fontFamily: "Montserrat",
  },
  inputTableTotal1: {
    color: "#5E5873",
    backgroundColor: "#FFCC4D33",
    width: "55px",
    height: "19px",
    borderRadius: 3,
    marginRight: 5,
    fontSize: 11,
    marginLeft: 10,
    textAlign: "center",
    paddingTop: 3,
    fontFamily: "Montserrat",
  },
  footerTable1: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "15px 5px",
    flexDirection: "row",
  },
  inputTable2: {
    color: "#6E6B7B",
    width: "123px",
    height: "21px",
    borderRadius: 4,
    marginRight: 5,
    fontSize: 9,
    border: "1px solid #B9B9C3",
    textAlign: "center",
    paddingTop: 5,
    fontFamily: "Montserrat",
  },
  inputTableFooter2: {
    color: "#6E6B7B",
    width: "123px",
    height: "21px",
    borderRadius: 4,
    marginRight: 5,
    fontSize: 9,
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 5,
    fontFamily: "Montserrat",
    backgroundColor: "#FFF5DB",
  },
  footer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default GeneratingQuestionPDF;
