import React, { FC, useEffect, useState } from "react";
import { Box, Grow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextStyles } from "@themes/textStyles";
import IconBack from "@assets/SvgComponents/IconBack";

interface TypeData {
  value: any;
  label: string;
  disabled?: boolean;
}

interface SelectProps {
  onChange?: (value: any) => void;
  data: Array<TypeData>;
  defaultValue?: any;
  placeholder?: string;
  border?: boolean;
  error?: string;
}

const SelectCustom: FC<SelectProps> = ({
  onChange = () => {},
  data,
  defaultValue,
  placeholder,
  border = true,
  error,
}) => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const [value, setValue] = useState<TypeData | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].value === defaultValue) {
          setValue(data[i]);
        }
      }
    }
  }, [defaultValue, data]);

  return (
    <>
      <Box
        position="relative"
        component="div"
        sx={{
          width: "100%",
          minWidth: 65,
        }}
      >
        <Box
          className={classes.rootSelect}
          component="div"
          sx={{
            width: "100%",
            height: 35,
            px: 1.5,
            cursor: "pointer",
            border: border ? "1px solid #DAD9E0" : "unset",
            borderColor: error ? "#EA5455" : "#DAD9E0",
          }}
          display="flex"
          alignItems="center"
          position="relative"
          justifyContent="space-between"
          onClick={() => setOpen(!open)}
        >
          <Typography
            className={textStyles.paragraph}
            style={{ color: value ? "rgb(35 35 35)" : "#B9B9C3" }}
          >
            {value ? value?.label : placeholder}
          </Typography>
          <Box component="p" style={{ margin: 0, transform: "rotate(-90deg)" }}>
            <IconBack color="#B9B9C3" />
          </Box>
        </Box>
        {error && (
          <Typography
            className={textStyles.paragraph_small_error}
            sx={{ mt: 0.3 }}
          >
            {error}
          </Typography>
        )}
        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 300 } : {})}
        >
          <Box component="ul" className={classes.boxDropdown}>
            {data?.map((item: TypeData, index: number) => (
              <Box
                style={{ pointerEvents: item?.disabled ? "none" : "auto" }}
                key={index}
                component="li"
                className={classes.itemDropdown}
                onClick={() => {
                  setValue(item);
                  setOpen(false);
                  onChange(item.value);
                }}
              >
                <Typography
                  className={textStyles.paragraph}
                  style={{ color: item?.disabled ? "#d1d1d1" : "#6E6B7B" }}
                >
                  {item?.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grow>
      </Box>
      {open && (
        <Box
          component="span"
          sx={{
            m: 0,
            width: "100vw",
            height: "100vh",
            left: 0,
            top: 0,
            zIndex: 9,
          }}
          display="flex"
          position="fixed"
          onClick={() => setOpen(false)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  rootSelect: {
    borderRadius: 6,
  },
  boxDropdown: {
    zIndex: 9999,
    background: "#fff",
    boxShadow: "0px 4.43679px 26.6208px rgba(0, 0, 0, 0.06)",
    listStyle: "none",
    margin: 0,
    borderRadius: "6.65519px",
    padding: 0,
    position: "absolute",
    top: "101%",
    left: 0,
    width: "100%",
    maxHeight: 300,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
      scrollbarWidth: "none",
    },
  },
  itemDropdown: {
    cursor: "pointer",
    "&:hover": {
      background: "#F8F8F9",
    },
    padding: "0.6em 1em",
  },
});

export default SelectCustom;
