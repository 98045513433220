import React, { useCallback, useEffect, useState } from "react";
import IconBack from "@assets/SvgComponents/IconBack";
import Spacing from "@components/Spacing";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import InputCustom from "@components/InputCustom";
import CreateSquare from "@assets/SvgComponents/CreateSquare";
import { makeStyles } from "@mui/styles";
import HumanSitting from "@assets/SvgComponents/HumanSitting";
import { PaperShadowCustom } from "@components/PaperCustom";
import { Link, useNavigate } from "react-router-dom";
import {
  clearDataSearchCompany,
  getDetailCompany,
  getDetailCompanyMongo,
  searchCompany,
} from "@stores/searchCompany/searchCompany.creator";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "@stores/state";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { notistack } from "@utils/notistack";

interface ICompany {
  _id?: string;
  value: string;
  label: string;
  postalCode?: string;
}

const SearchCompany = () => {
  const classes = useStyles();
  const textStyles = TextStyles();

  const { control } = useForm();

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );
  const [showList, setShowList] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue) => dispatch(searchCompany(nextValue)), 500),
    []
  );

  const handleSearch = (value: any) => {
    debounceSearch(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(clearDataSearchCompany());
    };
  }, [dispatch]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        display="flex"
        alignItems="center"
      >
        <Box component="div" display="flex" alignItems="center">
          <span style={{ display: "flex", transform: "scale(0.8)" }}>
            <IconBack color={Colors.secondary} />
          </span>
          <Spacing width={10} />
          <Typography
            className={textStyles.underline}
            style={{ color: "#5E5873" }}
          >
            Retour
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
        <PaperShadowCustom
          sx={{ pt: 7, px: 6, pb: 21 }}
          style={{ position: "relative" }}
        >
          <Typography className={textStyles.h3}>
            Pour quelle société ce devis s’adresse-t-il ?
          </Typography>
          <Grid
            display="flex"
            justifyContent="center"
            sx={{ mt: 2 }}
            style={{ minHeight: "30vh" }}
            container
            className={classes.containerActions}
          >
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              xl={8}
              lg={8}
              className={classes.boxAutocomplete}
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Box component="div" position="relative">
                <Controller
                  name="new-password"
                  render={({ field: { onChange, value } }) => (
                    <InputCustom
                      placeholder="Nom de la société ou SIRET"
                      label="Société déjà existante ?"
                      name="new-password"
                      onChange={(e) => {
                        onChange(e);
                        handleSearch(e.target.value);
                        setValueInput(e.target.value);
                      }}
                      value={value}
                      onClick={() => setShowList(true)}
                      endAdornmentIcon={
                        loading && (
                          <Box
                            component="p"
                            style={{
                              margin: 0,
                              display: "flex",
                              transform: "scale(0.5)",
                            }}
                          >
                            <CircularProgress style={{ color: "#5E5873" }} />
                          </Box>
                        )
                      }
                    />
                  )}
                  control={control}
                />
                {showList && data?.companies?.length > 0 && (
                  <Box
                    component="div"
                    sx={{
                      position: "absolute",
                      top: "105%",
                      left: 0,
                      width: "100%",
                      zIndex: 9,
                    }}
                  >
                    <PaperShadowCustom>
                      <List
                        sx={{
                          p: 0,
                          boxShadow:
                            "0px 4.43679px 26.6208px rgba(0, 0, 0, 0.06)",
                          maxHeight: 310,
                          overflowY: "scroll",
                        }}
                        className={classes.listCompany}
                      >
                        {data?.companies?.map(
                          (item: ICompany, index: number) => (
                            <ListItem
                              key={index}
                              className={classes.itemCompany}
                              sx={{ borderBottom: "1px solid #EBE9F1" }}
                              onClick={() => {
                                if (item?._id) {
                                  dispatch(getDetailCompanyMongo(item._id));
                                } else {
                                  dispatch(getDetailCompany(item.value));
                                }
                                dispatch(clearDataSearchCompany());
                                navigate("/contract");
                              }}
                            >
                              <ListItemText>
                                <Typography className={textStyles.paragraph}>
                                  {item.label}{" "}
                                  {item?.postalCode && `(${item?.postalCode})`}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          )
                        )}
                      </List>
                    </PaperShadowCustom>
                  </Box>
                )}
              </Box>
              <Spacing height={6} />
              {valueInput && data?.companies?.length === 0 && (
                <Typography
                  className={textStyles.paragraph}
                  style={{ color: "#FF9F43" }}
                >
                  La société renseignée d’existe pas. Créez la société pour
                  continuer 👉
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} xl={1} lg={1}>
              <DividerStyled
                orientation="vertical"
                flexItem
                style={{ height: "100%" }}
              >
                <Typography
                  className={textStyles.h4}
                  style={{ color: "#979797" }}
                >
                  OU
                </Typography>
              </DividerStyled>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              xl={3}
              lg={3}
              display="flex"
              alignItems="center"
            >
              {/* <Link to="/select-type" className={classes.boxCreateUser}> */}
              <Link to="/contract" className={classes.boxCreateUser}>
                <CreateSquare color="#6E6B7B" />
                <Spacing height={15} />
                <Typography className={textStyles.h6} textAlign="center">
                  Societé en création
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <p className={classes.boxHuman}>
            <HumanSitting />
          </p>
        </PaperShadowCustom>
      </Grid>
    </Grid>
  );
};

const DividerStyled = styled(Divider)({
  "&::before": {
    borderLeft: "1px solid #FFDB5C",
  },
  "&::after": {
    borderLeft: "1px solid #FFDB5C",
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  boxCreateUser: {
    border: "1px solid #B9B9C3",
    borderRadius: 6,
    width: 180,
    height: 180,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
    textDecoration: "none",
  },
  boxHuman: {
    position: "absolute",
    margin: 0,
    left: 30,
    bottom: -8,
  },
  containerActions: {
    paddingLeft: 144,
    paddingRight: 144,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  boxAutocomplete: {
    position: "relative",
    flex: 1,
    "& .MuiOutlinedInput-root": {
      padding: "0 !important",
    },
  },
  listCompany: {
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
      scrollbarWidth: "none",
    },
  },
  itemCompany: {
    cursor: "pointer",
    padding: "6px 20px !important",
    "&:hover": {
      backgroundColor: "#F8F8F9",
    },
  },
}));

export default SearchCompany;
