import { Box, Tooltip, TooltipProps, Typography } from "@mui/material";
import { TextStyles } from "@themes/textStyles";
import { FC, ReactNode } from "react";

interface TooltipCustomProps {
  title?: any;
  placement?: TooltipProps["placement"];
  minWidth?: string;
  maxWidth?: string;
  disabledClickChildren?: boolean;
  disabledHover?: boolean;
  defaultCursor?: string;
  children: ReactNode;
}

const TooltipCustom: FC<TooltipCustomProps> = ({
  title = "Invalid",
  placement = "top-start",
  minWidth = "300px",
  maxWidth = "unset",
  defaultCursor = "auto",
  disabledClickChildren = false,
  disabledHover = false,
  children,
}) => {
  const textStyles = TextStyles();

  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography
            className={textStyles.paragraph}
            sx={{ color: "#5E5873" }}
          >
            {title}
          </Typography>
        }
        disableHoverListener={disabledHover}
        placement={placement}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#FFF5DB",
              maxWidth: { maxWidth },
              minWidth: { minWidth },
              borderRadius: "6px",
              padding: "15px 20px",
              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.07)",
            },
          },
          arrow: {
            sx: {
              color: "#FFF5DB",
              transform: "translate(40px, 0px) !important",
              width: "20px",
              height: "14px",
              bottom: "-6px !important",
            },
          },
        }}
      >
        {/* By default disabled elements like <button> do not trigger user interactions so a Tooltip will not activate on normal events like hover. To accommodate disabled elements, add a simple wrapper element, such as a span */}
        <Box
          sx={{ cursor: disabledClickChildren ? "not-allowed" : defaultCursor }}
        >
          {children}
        </Box>
      </Tooltip>
    </>
  );
};

export default TooltipCustom;
