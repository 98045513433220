import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { lighten, darken } from "@mui/system";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { TextStyles } from "@themes/textStyles";
import { Colors } from "@themes/colors";
import { navigationButtons } from "./navigationButtons";
import LogoDashboard from "@assets/Images/logo_dashboard.jpg";
import LogoutIcon from "@assets/SvgComponents/LogoutIcon";
import ConfirmDialog from "@components/ConfirmDialog";
import { useDispatch } from "react-redux";
import { logoutRequested } from "@stores/logout/logout.creator";

const drawerWidth = 280;

export interface ILayoutProps {}

export const Layout: FC<ILayoutProps> = () => {
  const textStyles = TextStyles();
  const classNames = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logout, setLogout] = useState<boolean>(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          sx: {
            px: 4,
            pt: 2,
            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.05)",
            borderRight: "unset",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar className={classNames.toolbar}>
          <Box
            component="p"
            sx={{
              m: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LogoDashboard} alt="" />
          </Box>
        </Toolbar>
        <Stack direction="row" spacing={2} sx={{ px: 1, mt: 4 }}>
          <Avatar
            sx={{
              width: 30,
              height: 30,
              backgroundColor: lighten(Colors.info, 0.7),
            }}
          >
            <Typography
              sx={{ color: darken(Colors.info, 0.2) }}
              className={textStyles.paragraph_bold}
            >
              NO
            </Typography>
          </Avatar>
          <Stack>
            <Typography className={textStyles.paragraph}>
              Nicolas Oudard
            </Typography>
            <Typography className={textStyles.paragraph_small}>
              Admin
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ mt: 2 }} />
        <List>
          {navigationButtons.map((navigationBtn, idx) => (
            <Link style={{ all: "unset" }} to={navigationBtn.path} key={idx}>
              <ListItem button sx={{ px: 1 }} className={classNames.listItem}>
                <ListItemIcon sx={{ minWidth: "unset", mr: 1.5 }}>
                  <navigationBtn.Icon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={textStyles.h5}
                  primary={navigationBtn.text}
                />
              </ListItem>
            </Link>
          ))}
        </List>
        <Box sx={{ mt: "auto", mb: 2 }}>
          <ListItem
            button
            sx={{ px: 1, mb: 2 }}
            className={classNames.listItem}
            onClick={() => setLogout(true)}
          >
            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
              <span
                style={{
                  display: "flex",
                  transform: "scale(0.9)",
                  opacity: 0.7,
                  marginBottom: 2,
                }}
              >
                <LogoutIcon color="#5E5873" />
              </span>
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={textStyles.h5}
              primary={"Se déconnecter"}
            />
          </ListItem>
          <Typography
            gutterBottom
            className={textStyles.paragraph}
            sx={{ mt: 1, ml: 1 }}
          >
            COPYRIGHT © 2022
          </Typography>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: lighten(Colors.light, 0.9),
          p: 3,
          minHeight: "100vh",
        }}
      >
        <Outlet />
      </Box>
      <ConfirmDialog
        open={logout}
        onClose={() => setLogout(false)}
        onConfirm={() => {
          dispatch(logoutRequested());
          navigate("/login");
        }}
        titleCancel="Annuler"
        titleConfirm="Se déconnecter"
        titleDialog="Êtes-vous sûr de vouloir vous déconnecter?"
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "8px !important",
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#F8F8F8 !important",
    },
  },
}));
