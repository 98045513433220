import React, { FocusEvent } from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, InputAdornment, Typography } from "@mui/material";
import { TextStyles } from "themes/textStyles";
import { Colors } from "themes/colors";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

type VariantInput = "outlined" | "filled" | "standard";

type TypeInput = "text" | "password" | "email";

type SizeLabel = "sm" | "md";

interface InputCustomProps {
  variant?: VariantInput;
  placeholder?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  value?: string;
  type?: TypeInput;
  autoComplete?: string;
  sizeLabel?: SizeLabel;
  endAdornmentIcon?: React.ReactNode;
  startAdornmentIcon?: React.ReactNode;
  onIconClick?: (e: React.MouseEvent<HTMLElement>) => void;
  endGroupIcon?: React.ReactNode;
  isAutocomplete?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  name?: string;
}

const InputCustom = React.forwardRef<any, InputCustomProps>(
  (
    {
      variant = "outlined",
      placeholder,
      label,
      onChange = () => {},
      error,
      value,
      type = "text",
      autoComplete,
      sizeLabel = "md",
      endAdornmentIcon,
      startAdornmentIcon,
      onIconClick = () => {},
      endGroupIcon,
      isAutocomplete = false,
      onBlur = () => {},
      onClick = () => {},
      name,
      ...rest
    },
    ref
  ) => {
    const textStyles = TextStyles();
    const classes = useStyles();
    return (
      <>
        {!isAutocomplete && (
          <Box flex={1}>
            {label && (
              <Typography
                className={
                  sizeLabel === "sm"
                    ? textStyles.paragraph_small_500
                    : textStyles.h6
                }
                sx={{ mb: 0.5 }}
              >
                {label}
              </Typography>
            )}
            <InputStyled
              {...rest}
              type={type}
              value={value || ""}
              onChange={onChange}
              variant={variant}
              label={placeholder}
              className={error && "TextField-error"}
              autoComplete={autoComplete}
              ref={ref}
              onBlur={onBlur}
              onClick={onClick}
              name={name}
              InputProps={{
                endAdornment: endAdornmentIcon ? (
                  <InputAdornment position="end">
                    <Box
                      onClick={onIconClick}
                      className={classes.endIcon}
                      component="p"
                      sx={{ m: 0, mr: 1.5 }}
                      display="flex"
                      alignItems="center"
                    >
                      {endAdornmentIcon}
                    </Box>
                  </InputAdornment>
                ) : endGroupIcon ? (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <InputAdornment position="end">
                      <Box
                        className={classes.endGroupIcon}
                        component="p"
                        sx={{ my: 0, ml: 0.6 }}
                        display="flex"
                        alignItems="center"
                      >
                        {endGroupIcon}
                      </Box>
                    </InputAdornment>
                  </>
                ) : null,
                startAdornment: startAdornmentIcon && (
                  <InputAdornment position="start">
                    <Box
                      onClick={onIconClick}
                      className={classes.endIcon}
                      component="p"
                      sx={{ m: 0, ml: 1.5 }}
                      display="flex"
                      alignItems="center"
                    >
                      {startAdornmentIcon}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Typography
                className={textStyles.paragraph_small_error}
                sx={{ mt: 0.3 }}
              >
                {error}
              </Typography>
            )}
          </Box>
        )}
        {isAutocomplete && (
          <Box flex={1}>
            {label && (
              <Typography
                className={
                  sizeLabel === "sm"
                    ? textStyles.paragraph_small
                    : textStyles.h6
                }
                sx={{ mb: 0.5 }}
              >
                {label}
              </Typography>
            )}
            <InputStyled
              {...rest}
              type={type}
              value={value || ""}
              onChange={onChange}
              variant={variant}
              label={placeholder}
              className={error && "TextField-error"}
              autoComplete={autoComplete}
              onBlur={onBlur}
              onClick={onClick}
              ref={ref}
              name={name}
            />
            {error && (
              <Typography
                className={textStyles.paragraph_small_error}
                sx={{ mt: 0.3 }}
              >
                {error}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  }
);

const InputStyled = styled(TextField)({
  display: "flex",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 5,
    borderColor: "#DFDDE4",
    borderWidth: 1,
    top: 0,
    "&:hover": {
      borderColor: "#DFDDE4",
    },
    "& legend": {
      display: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 15px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "0.875rem",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: Colors.primary,
      },
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: 12,
    color: "#B9B9C3",
    fontWeight: 400,
    lineHeight: 1.2,
    top: -4,
    fontFamily: "Montserrat !important",
    "&.Mui-focused": {
      display: "none",
    },
    "&.MuiFormLabel-filled": {
      display: "none",
    },
  },
  "&.TextField-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.error,
      "&:hover": {
        borderColor: Colors.error,
      },
    },
  },
});

const useStyles = makeStyles({
  endIcon: {
    cursor: "pointer",
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },
  endGroupIcon: {},
});

export default InputCustom;
