import { Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import { ICreateUserDto, IFilterUsersDto } from "./users.dto";

export function getUsersService(fileterUsersDto: IFilterUsersDto) {
  const apiUrl = Endpoint.users;

  const params: IFilterUsersDto = {};
  if (fileterUsersDto.limit) params.limit = fileterUsersDto.limit;
  if (fileterUsersDto.name) params.name = fileterUsersDto.name;
  if (fileterUsersDto.nolimit) params.nolimit = fileterUsersDto.nolimit;
  if (fileterUsersDto.page) params.page = fileterUsersDto.page;
  if (fileterUsersDto.sortBy) params.sortBy = fileterUsersDto.sortBy;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function getDetailUserService(id: string) {
  const apiUrl = Endpoint.users + `/${id}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function deleteUserService(id: string) {
  const apiUrl = Endpoint.users + `/${id}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "DELETE",
      url: apiUrl,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function createUserService(user: ICreateUserDto) {
  const apiUrl = Endpoint.users;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data: user,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function updateUserService(user: ICreateUserDto, id: string) {
  const apiUrl = Endpoint.users + `/${id}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "PUT",
      url: apiUrl,
      data: user,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}
