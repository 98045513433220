import { ILogin } from "./login.dto";
import {
  ClearDataLogin,
  ClearMessageErrorLogin,
  LoginFailedAction,
  LoginRequestedAction,
  LoginSucceededAction,
  LoginType,
} from "./login.type";

export function loginRequested(
  email: string,
  password: string
): LoginRequestedAction {
  return {
    type: LoginType.LOGIN_REQUESTED,
    email,
    password,
  };
}

export function loginSucceeded(data: ILogin): LoginSucceededAction {
  return {
    type: LoginType.LOGIN_SUCCEEDED,
    data,
  };
}

export function loginFailed(error: any): LoginFailedAction {
  return {
    type: LoginType.LOGIN_FAILED,
    error,
  };
}

export function clearMessageErrorLogin(): ClearMessageErrorLogin {
  return {
    type: LoginType.CLEAR_MESSAGE_ERROR,
  };
}

export function clearDataLogin(): ClearDataLogin {
  return {
    type: LoginType.CLEAR_DATA_LOGIN,
  };
}
