import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TextStyles } from "@themes/textStyles";
import EditIcon from "@assets/SvgComponents/EditIcon";
import DuplicateIcon from "@assets/SvgComponents/DuplicateIcon";
import ArchiveIcon from "@assets/SvgComponents/ArchiveIcon";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailContract,
  infoDraftContractUpdate,
  archiveDraftContract,
} from "@stores/contract/contract.creator";
import { useNavigate } from "react-router-dom";
import { createDuplicateContract } from "@stores/listQuotes/listQuotes.creator";
import { IAppState } from "@stores/state";
import SendIcon from "@assets/SvgComponents/SendIcon";
import MaintainIcon from "@assets/SvgComponents/MaintainIcon";
import WarningBox from "./WarningBox";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { IdProfessionType } from "@stores/activitiesOfContract/activitiesOfContract.dto";

interface IActionsContract {
  id: string;
  loading?: boolean;
  contract_id?: string;
  isEdit?: boolean;
  quoteType: ContractType;
  setIdProfession: Dispatch<SetStateAction<IdProfessionType>>;
  onRefresh?: () => void;
  isPassGuarantee?: boolean;
  isArchive?: boolean;
}

const ActionsContract: FC<IActionsContract> = ({
  id,
  loading = false,
  contract_id,
  isEdit = true,
  quoteType,
  setIdProfession,
  onRefresh = () => {},
  isPassGuarantee = true,
  isArchive = true,
}) => {
  const textStyles = TextStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadingUpdate = useSelector(
    (state: IAppState) => state.dataContract.loading
  );

  const { response } = useSelector((state: IAppState) => state.dataContract);

  const [open, setOpen] = useState<boolean>(false);
  const [showWarning, setShowWaring] = useState<boolean>(false);

  const handleEditContract = () => {
    dispatch(getDetailContract(id));
    dispatch(infoDraftContractUpdate(id));
    navigate("/contract");
  };

  const handleDuplicateContract = () => {
    dispatch(createDuplicateContract(id));
  };

  const handleArchiverContract = () => {
    dispatch(archiveDraftContract(id));
  };

  useEffect(() => {
    setOpen(false);
  }, [response]);

  return (
    <>
      <Box component="div" position="relative" sx={{ width: "fit-content" }}>
        <Box
          component="p"
          sx={{ m: 0, display: "flex", cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          <MoreVertIcon style={{ color: "#5E5873" }} />
        </Box>

        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 300 } : {})}
        >
          <Box
            component="div"
            className={classes.rootList}
            sx={{ zIndex: 999 }}
          >
            <List className={classes.listActions}>
              <ListItem button onClick={handleEditContract} disabled={isEdit}>
                <ListItemIcon>
                  <EditIcon color="#6E6B7B" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={textStyles.paragraph}>
                      Modifier
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem button onClick={handleDuplicateContract}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress size={14} style={{ color: "#6E6B7B" }} />
                  ) : (
                    <DuplicateIcon color="#6E6B7B" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={textStyles.paragraph}>
                      Duppliquer
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={handleArchiverContract}
                disabled={!isArchive}
              >
                <ListItemIcon>
                  {loadingUpdate ? (
                    <CircularProgress size={14} style={{ color: "#6E6B7B" }} />
                  ) : (
                    <ArchiveIcon color="#6E6B7B" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={textStyles.paragraph}>
                      Archiver
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <SendIcon color="#6E6B7B" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      style={{ width: "max-content" }}
                      className={textStyles.paragraph}
                    >
                      Envoie devis
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                disabled={isPassGuarantee}
                button
                onClick={() => {
                  setIdProfession(
                    quoteType === ContractType.PIB
                      ? IdProfessionType.PIB
                      : IdProfessionType.Artisans_BTP
                  );
                  setShowWaring(true);
                }}
              >
                <ListItemIcon>
                  <MaintainIcon color="#6E6B7B" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      style={{ width: "max-content" }}
                      className={textStyles.paragraph}
                    >
                      Passer en garantie
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grow>
      </Box>

      {open && (
        <Box
          component="span"
          sx={{
            m: 0,
            width: "100vw",
            height: "100vh",
            left: 0,
            top: 0,
            zIndex: 9,
          }}
          display="flex"
          position="fixed"
          onClick={() => setOpen(false)}
        />
      )}

      {showWarning && (
        <WarningBox
          idContract={id}
          open={showWarning}
          onClose={() => setShowWaring(false)}
          contract_id={contract_id}
          onRefresh={() => {
            setShowWaring(false);
            onRefresh();
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  rootList: {
    position: "absolute",
    zIndex: 9,
    right: 0,
  },
  listActions: {
    borderRadius: 6,
    background: "#fff",
    boxShadow: "0px 4.43679px 26.6208px rgb(0 0 0 / 6%)",
    "& .MuiListItem-root": {
      padding: "3px 30px 3px 16px",
      "&:hover": {
        backgroundColor: "#F8F8F8",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "unset",
        marginRight: "8px",
      },
    },
  },
});

export default ActionsContract;
