import PaperIcon from "@assets/SvgComponents/PaperIcon";
import { Box, CircularProgress } from "@mui/material";
import { IActivitiesSelected } from "@pages/Contract/CalculateResult";
import GeneratingQuestionPDF from "@pages/Contract/GeneratingQuestionPDF";
import GeneratingResultPDF from "@pages/Contract/GeneratingResultPDF";
import { dataLegalStatus } from "@pages/Contract/InformationCompany";
import { dataReason } from "@pages/Contract/InsuranceHistory";
import { pdf } from "@react-pdf/renderer";
import { IdProfessionType } from "@stores/activitiesOfContract/activitiesOfContract.dto";
import {
  clearAllDataContract,
  getDetailContract,
  passInGuarantee,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import {
  ContractStatus,
  ContractType,
} from "@stores/listQuotes/listQuotes.dto";
import { clearAllActivities } from "@stores/savedActivities/savedActivities.creator";
import {
  clearDataSearchCompany,
  clearDetailCompany,
} from "@stores/searchCompany/searchCompany.creator";
import { IAppState } from "@stores/state";
import { notistack } from "@utils/notistack";
import { saveAs } from "file-saver";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PDFAttestation from "./PDFAttestation";
import PDFCondition from "./PDFConditions";
interface IDownloadDocumentProps {
  contract_id?: string;
  type: number;
  quoteType: ContractType;
  idProfession: IdProfessionType;
  setIdProfession: Dispatch<SetStateAction<IdProfessionType>>;
  onRefresh?: () => void;
  isDisabled?: boolean;
}

const DownloadDocument: FC<IDownloadDocumentProps> = ({
  contract_id,
  type = 1,
  quoteType,
  idProfession,
  setIdProfession,
  onRefresh = () => {},
  isDisabled = false,
}) => {
  const dispatch = useDispatch();
  const { activities } = useSelector(
    (state: IAppState) => state.dataActivities
  );
  const activitiesRedux = useSelector(
    (state: IAppState) => state.activitiesRedux.activities
  );
  const { data_contract_update, loadingGet, responsePassGarantee, error } =
    useSelector((state: IAppState) => state.dataContract);

  const [dataPDF, setPDF] = useState<any>(null);
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataActivities, setDataActivities] = useState<IActivitiesSelected[]>(
    []
  );
  const [dataPDFQuestion, setPDFQuestion] = useState<any>(null);
  const [qrCode, setQrCode] = useState<any>();

  // const isPIB = data_contract_update?.type === ContractType.PIB;
  const isPIB =
    !data_contract_update?.company?.name?.includes?.("test artisans");

  const handleDownloadPDF_4 = async (infoInsurance: IInfoContractPDF) => {
    const asPDF = pdf();

    const companyName = data_contract_update?.company?.name?.replaceAll(
      " ",
      "_"
    );
    const currentDate = moment(new Date()).format("DD-MM-YYYY");

    asPDF.updateContainer(
      <PDFCondition infoInsurance={infoInsurance} isPIB={isPIB} />
    );
    const blob = await asPDF.toBlob();
    saveAs(
      blob,
      `Conditions_${companyName}_${currentDate}_${data_contract_update?.contract_id}.pdf`
    );

    onRefresh();
    setTimeout(() => {
      setPDF(null);
      dispatch(clearAllDataContract());
      dispatch(clearAllActivities());
      dispatch(clearDetailCompany());
      dispatch(clearDataSearchCompany());
      setIsDownload(false);
      setLoading(false);
    }, 2000);
  };

  const handleDownloadPDF_3 = async (infoInsurance: IInfoContractPDF) => {
    const asPDF = pdf();
    const companyName = data_contract_update?.company?.name?.replaceAll(
      " ",
      "_"
    );
    const currentDate = moment(new Date()).format("DD-MM-YYYY");
    asPDF.updateContainer(
      <PDFAttestation
        qrCode={qrCode}
        infoInsurance={infoInsurance}
        isPIB={isPIB}
      />
    );
    const blob = await asPDF.toBlob();
    saveAs(
      blob,
      `Attestation_${companyName}_${currentDate}_${data_contract_update?.contract_id}.pdf`
    );
  };

  const handleDownloadPDF_2 = async (infoInsurance: IInfoContractPDF) => {
    try {
      const asPDF = pdf();

      const companyName = data_contract_update?.company?.name?.replaceAll(
        " ",
        "_"
      );
      const currentDate = moment(new Date()).format("DD-MM-YYYY");

      asPDF.updateContainer(
        <GeneratingResultPDF infoInsurance={infoInsurance} isPIB={isPIB} />
      );
      const blob = await asPDF.toBlob();
      saveAs(
        blob,
        `${companyName}_${currentDate}_${data_contract_update?.contract_id}.pdf`
      );
      onRefresh();
      contract_id &&
        dispatch(
          updateDraftContract(
            { status: ContractStatus.IN_PROGRESS },
            contract_id
          )
        );
      setTimeout(() => {
        setPDF(null);
        dispatch(clearAllDataContract());
        dispatch(clearAllActivities());
        dispatch(clearDetailCompany());
        dispatch(clearDataSearchCompany());
        setIsDownload(false);
        setLoading(false);
      }, 2000);
    } catch (err) {
      onRefresh();
      console.log("error");
      dispatch(clearAllDataContract());
      dispatch(clearAllActivities());
      dispatch(clearDetailCompany());
      dispatch(clearDataSearchCompany());
      setIsDownload(false);
      setLoading(false);
      setPDF(null);
    }
  };

  const handleDownloadPDF_1 = async (info_contract: any, reason?: string) => {
    try {
      const asPDF = pdf();
      asPDF.updateContainer(
        <GeneratingQuestionPDF
          activities={dataActivities}
          company={data_contract_update?.company}
          contract={info_contract}
          reason={reason}
        />
      );
      const blob = await asPDF.toBlob();
      saveAs(blob, "QUESTIONNAIRE D’ETUDE.pdf");
      onRefresh();
      setTimeout(() => {
        dispatch(clearAllDataContract());
        setIsDownload(false);
        setLoading(false);
        setPDFQuestion(null);
      }, 2000);
    } catch {
      onRefresh();
      dispatch(clearAllDataContract());
      setIsDownload(false);
      setLoading(false);
      setPDFQuestion(null);
    }
  };

  useEffect(() => {
    if (data_contract_update && activities.data) {
      const legalStatus = dataLegalStatus.find(
        (item) => item.value === data_contract_update?.company?.legalStatus
      );

      let newActivities: Array<IActivitiesSelected> = [];
      for (let i = 0; i < activities.data?.length; i++) {
        for (let j = 0; j < data_contract_update.activities.length; j++) {
          if (
            activities.data[i]._id ===
            data_contract_update.activities[j].activityId
          ) {
            const indefOfHaveExclusion =
              activities.data[i].definition?.indexOf("Exclusion");

            if (indefOfHaveExclusion && indefOfHaveExclusion !== -1) {
              // length of Exclusion to the fist HTML tag of this is 69

              const exclusionActivity = activities.data[i].definition?.slice(
                indefOfHaveExclusion - 69
              );

              const definitionActivity = activities.data[i].definition?.slice(
                0,
                indefOfHaveExclusion - 69
              );

              newActivities.push({
                ...activities.data[i],
                definition: definitionActivity,
                exclusion: exclusionActivity,
                percent: data_contract_update.activities[j].percent,
              });
            } else {
              newActivities.push({
                ...activities.data[i],
                percent: data_contract_update.activities[j].percent,
              });
            }
          }
        }
      }
      setDataActivities(newActivities);
      const reason = dataReason.find(
        (item) =>
          item.value ===
          data_contract_update?.insuranceHistory?.progressTerminationReason
      );
      let taux_de_revision_du_contrat: number = 0;
      if (
        data_contract_update?.pricingByFranchise?.NETCompanyAvecCom &&
        data_contract_update?.company?.companyDetails?.revenue
      ) {
        taux_de_revision_du_contrat =
          data_contract_update?.pricingByFranchise.NETCompanyAvecCom /
          data_contract_update?.company.companyDetails.revenue;
      }

      if (type === 2 || type === 3) {
        const dataInsurance: IInfoContractPDF = {
          contract_id: data_contract_update?.contract_id,
          contract_code: data_contract_update?.contract_code
            ? `BMK-${data_contract_update.contract_code}-A`
            : undefined,
          NETCompanyAvecCom: parseFloat(
            data_contract_update?.pricingByFranchise?.NETCompanyAvecCom?.toFixed(
              2
            ) || "0"
          ),
          fractionnement: data_contract_update?.contractOption?.period,
          frais_de_souscription:
            data_contract_update?.contractOption?.subscriptionFee,
          franchise_du_contrat:
            data_contract_update?.contractOption?.franchise?.money?.toFixed(
              0
            ) || 0,
          taux_de_revision_du_contrat: parseFloat(
            (taux_de_revision_du_contrat * 100).toFixed(2)
          ),
          assureur: data_contract_update?.company?.name,
          assureur_DPRSA_PJ: "Groupama protection juridique",
          courtier: "Madecennale",
          informations_du_souscripteur_1: {
            souscripteur: data_contract_update?.company?.name,
            forme_juridique: legalStatus?.label,
            phone:
              data_contract_update?.company?.legalRepresentative?.phoneNumber,
            email: data_contract_update?.company?.legalRepresentative?.email,
            dimmatriculation: data_contract_update?.company?.siren,
            date_creation: moment(
              data_contract_update?.company?.birthday
            ).format("DD/MM/YYYY"),
            effectif: data_contract_update?.company?.companyDetails?.workforce,
            chiffre_HT:
              data_contract_update?.company?.companyDetails?.revenue?.toString(),
            dirigeant: data_contract_update?.company?.legalRepresentative?.name,
            address: data_contract_update?.company?.headquarterAddress,
            siren: data_contract_update?.company?.siren,
            city: data_contract_update?.company?.city,
            codePostal: data_contract_update?.company?.codePostal,
          },
          activities: newActivities,
          informations_du_souscripteur_2: {
            hasRCD: data_contract_update?.insuranceHistory?.hasBeenRCD
              ? "Oui"
              : "Non",
            contrat_en_cours: data_contract_update?.insuranceHistory
              ?.isInProgress
              ? "Oui"
              : "Non",
            date_de_resiliation:
              !data_contract_update?.insuranceHistory?.isInProgress &&
              data_contract_update?.insuranceHistory?.progressCancellationDate
                ? moment(
                    data_contract_update?.insuranceHistory
                      ?.progressCancellationDate
                  ).format("DD/MM/YYYY")
                : "",
            assure_lors_des_24_derniers_mois:
              data_contract_update?.insuranceHistory?.claimDetails?.reduce(
                (acc: any, current: any) => acc + current.numberOfClaims,
                0
              ),
            pour_un_montant_de:
              data_contract_update?.insuranceHistory?.claimDetails?.reduce(
                (acc: any, current: any) => acc + current.amount,
                0
              ),
            esilie_non_paiement:
              reason?.label === "Non paiement de prime" ? "Oui" : "Non",
            resilie_pour_fausse_declaration:
              reason?.label === "Fausse déclaration" ? "Oui" : "Non",
            de_sous_traitance:
              data_contract_update?.company?.companyDetails
                ?.percentEntrustToSubContractors &&
              data_contract_update?.company?.companyDetails
                ?.percentEntrustToSubContractors > 30
                ? "Oui"
                : "Non",
            en_redressement_judiciaire: data_contract_update?.isInReceivership
              ? "Oui"
              : "Non",
            //
            reprise_du_passe: data_contract_update?.insuranceHistory
              ?.isInsuredThePast
              ? "Oui"
              : "Non",
            //
          },
          page6: {
            amount_franchise_2:
              data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
            amount_franchise_3:
              data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
          },
          pricingByPeriod:
            data_contract_update?.pricingByFranchise?.pricingByPeriod,
          cotisations: {
            JURY_LAW: data_contract_update?.pricingByFranchise?.JURY_LAW || 0,
            PJ_GROUPAMA:
              data_contract_update?.pricingByFranchise?.PJ_GROUPAMA || 0,
            fractionement:
              data_contract_update?.pricingByFranchise?.fractionement,
            fraisInclus5LPP:
              data_contract_update?.pricingByFranchise?.fraisInclus5LPP || 0,
            primeCODE: data_contract_update?.pricingByFranchise?.primeCode || 0,
            primeNetHT: data_contract_update?.pricingByFranchise?.primeNetHT,
            totalMAJO_MINO:
              data_contract_update?.pricingByFranchise?.totalMAJO_MINO,
          },
          contractStartDate: data_contract_update?.insuranceHistory
            ?.contractStartDate
            ? data_contract_update.insuranceHistory.contractStartDate
            : "",
          //
          resumptionDatePast: data_contract_update?.insuranceHistory
            ?.resumptionDatePast
            ? data_contract_update.insuranceHistory.resumptionDatePast
            : "",
          //
          dataActivities: newActivities,
          date_decheance: data_contract_update?.insuranceHistory
            ?.contractStartDate
            ? moment(data_contract_update?.insuranceHistory?.contractStartDate)
                .format("LL")
                .slice(0, -5)
            : "",
          //
          workforce: data_contract_update?.company?.companyDetails?.workforce,
          //
        };
        setPDF(dataInsurance);
      }
      if (type === 1) {
        const data = {
          reason: reason?.label,
          detail_company: data_contract_update?.company,
          info_contract: {
            activities: activitiesRedux,
            insuranceHistory: data_contract_update?.insuranceHistory,
            isInReceivership: data_contract_update?.isInReceivership,
          },
        };
        setPDFQuestion(data);
      }
    }
  }, [data_contract_update, activities, type, activitiesRedux]);

  useEffect(() => {
    if (dataPDF && isDownload && !loadingGet) {
      if (type === 2) {
        handleDownloadPDF_2(dataPDF);
      }
      if (type === 3) {
        if (dataPDF?.contract_code) {
          handleDownloadPDF_3(dataPDF);
          handleDownloadPDF_4(dataPDF);
        } else {
          setPDF(null);
          console.log("pass");
          contract_id && dispatch(passInGuarantee(contract_id, false));
        }
      }
    }
    if (dataPDFQuestion && isDownload && !loadingGet) {
      if (type === 1) {
        handleDownloadPDF_1(
          dataPDFQuestion?.info_contract,
          dataPDFQuestion?.reason
        );
      }
    }
    loadingGet ? setLoading(true) : setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPDF, isDownload, loadingGet, type, dataPDFQuestion, contract_id]);

  const handleGetDetail = () => {
    if (contract_id) {
      dispatch(getDetailContract(contract_id));

      const newIdPro =
        quoteType === ContractType.PIB
          ? IdProfessionType.PIB
          : IdProfessionType.Artisans_BTP;

      if (idProfession !== newIdPro) {
        setIdProfession(newIdPro);
      }

      setIsDownload(true);
      setPDF(null);
    }
  };

  const qrCodeCanvas = document.querySelector("canvas");

  useEffect(() => {
    if (qrCodeCanvas) {
      const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
      setQrCode(qrCodeDataUri);
    }
  }, [qrCodeCanvas]);

  useEffect(() => {
    if (responsePassGarantee) {
      if (contract_id === data_contract_update?._id) {
        dispatch(clearAllDataContract());
        setTimeout(() => {
          contract_id && dispatch(getDetailContract(contract_id));
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsePassGarantee, contract_id]);

  useEffect(() => {
    if (error) {
      notistack.error(error);
      dispatch(clearAllDataContract());
      dispatch(clearAllActivities());
      dispatch(clearDetailCompany());
      dispatch(clearDataSearchCompany());
      setIsDownload(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Box
        component="p"
        sx={{
          m: 0,
          transform: "scale(1.5)",
          ml: 2,
          cursor: isDisabled ? "auto" : "pointer",
        }}
        display="flex"
        onClick={() => (isDisabled ? null : handleGetDetail())}
      >
        {loading && isDownload ? (
          <CircularProgress size={14} style={{ color: "#6E6B7B" }} />
        ) : (
          <PaperIcon color={isDisabled ? "#B9B9C3" : "#5E5873"} />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          opacity: 0,
          visibility: "hidden",
        }}
      >
        <QRCodeCanvas
          value={`https://attestations.madecennale.com/?id=${contract_id}`}
        />
      </Box>
    </>
  );
};

export default DownloadDocument;
