import { ILogin, IResponseLogin } from "./login.dto";
import { LoginActions, LoginType } from "./login.type";

const initialLoginState: IResponseLogin = {
  data: {} as ILogin,
  loading: false,
};

export default function loginReducer(
  state = initialLoginState,
  action: LoginActions
): IResponseLogin {
  switch (action.type) {
    case LoginType.LOGIN_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoginType.LOGIN_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case LoginType.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case LoginType.CLEAR_MESSAGE_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    case LoginType.CLEAR_DATA_LOGIN: {
      return {
        ...state,
        data: {} as ILogin,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
