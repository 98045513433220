import type { RouteObject } from "react-router-dom";
import { LoginPage } from "@pages/Auth/Login";
import { Dashboard } from "@pages/Dashboard";
import { ProtectedPage } from "@components/ProtectedPage";
import { ChangePassword } from "@pages/Auth/ChangePassword";
import { Layout } from "layout";
import Contract from "@pages/Contract";
import Activities from "@pages/Contract/Activities";
import InformationCompany from "@pages/Contract/InformationCompany";
import CalculateResult from "@pages/Contract/CalculateResult";
import { InsuranceHistory } from "@pages/Contract/InsuranceHistory";
import ListQuotes from "@pages/ListQuotes";
import ManagementUsers from "@pages/ManagementUsers";
import SearchCompany from "@pages/SearchCompany";
import PageNotFound from "@pages/PageNotFound";
import InformationDetail from "@pages/ManagementUsers/InformationDetail";
import ListUsers from "@pages/ManagementUsers/ListUsers";
import SelectType from "@pages/SelectType";

export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <ProtectedPage>
        <Layout />
      </ProtectedPage>
    ),
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/search-company",
        element: <SearchCompany />,
      },
      {
        path: "/select-type",
        element: <SelectType />,
      },
      {
        path: "/contract",
        element: <Contract />,
        children: [
          {
            path: "activities",
            element: <Activities />,
          },
          {
            element: <InformationCompany />,
            index: true,
          },
          {
            path: "calculate-result",
            element: <CalculateResult />,
          },
          {
            path: "insurance-history",
            element: <InsuranceHistory />,
          },
        ],
      },
      {
        path: "/liste-des-devis",
        element: <ListQuotes />,
      },
      {
        path: "/management-users",
        element: <ManagementUsers />,
        children: [
          {
            index: true,
            element: <ListUsers />,
          },
          {
            path: "information-detail",
            element: <InformationDetail />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export const authRoutes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
